import { getCompany } from "@/actions/auth";
import { getOriginsFiltered } from "@/helpers/crm";
import { getFilteredStatusTags } from "@/helpers/tagColorToPalette";
import _ from "lodash";
import { Hash, LetterText, List, ListTodo, MessageCircle, MessageCircleQuestion, Tag, Trash2 } from "lucide-react";
import { ReactNode } from "react";
import StatusTagCircle from "../../StatusTag/status-tag-circle";
import FieldTextValue from "../FieldTextValue";

const getIconByType = (type: string) => {
    const icons = {
        'select': <ListTodo size={17} />,
        'string': <LetterText size={17} />,
        'number': <Hash size={17} />,
        'multiselect': <List size={17} />
    }

    return icons[type];
}

export type FieldType = 'email' | 'phone' | 'select' | 'multiselect' | 'string' | 'number'

export type FieldObjectType = {
    label: string,
    code: string,
    icon: JSX.Element,
    type: FieldType,
    editable?: boolean,
    removable?: boolean,
    fieldType?: 'array' | 'default',
    options?: {
        value: string,
        label: string,
        render: () => ReactNode
    }[],
    emptyText?: string
    renderValue?: (value: any) => ReactNode
}

export const formFields: FieldObjectType[] = [
    {
        label: 'Origen',
        code: 'origin',
        icon: <MessageCircleQuestion size={17} />,
        type: 'select',
        editable: false,
        removable: false,
        options: getOriginsFiltered().map((origin) => {
            return {
                value: origin.code,
                label: origin.name
            }
        }),
        emptyText: 'Agrega un origen....'
    },
    {
        label: 'Etiqueta',
        code: 'statusTag',
        icon: <Tag size={17} />,
        type: 'select',
        renderValue: ({ value, label }: { value: string, label: string, render?: () => ReactNode }) => {
            return <FieldTextValue className="flex items-center gap-2 truncate">
                <StatusTagCircle code={value} className="w-[9px] h-[9px]" />
                <span className="flex-grow max-w-[100%] overflow-hidden truncate">
                    {label}
                </span>
            </FieldTextValue>
        },
        emptyText: 'Agrega una etiqueta....',
        options: [
            ...getFilteredStatusTags().map((tag) => {
                return {
                    value: tag.code,
                    label: tag.name,
                    render: () => {
                        return <span className="flex items-center gap-2">
                            <StatusTagCircle code={tag.code} className="w-[12px] h-[12px]" />
                            {tag.name}
                        </span>
                    }
                }
            })
        ]
    },
    {
        label: 'Comentario',
        icon: <MessageCircle size={17} />,
        code: 'detail',
        type: 'string',
        emptyText: 'Escribe un comentario...'
    },
    ..._.get(getCompany(), 'additionalDataFields', [])
        .map((field) => {
            return {
                code: `additionalData.${field.code}`,
                icon: getIconByType(field.type),
                label: field.name,
                type: field.type,
                emptyText: `Agrega un valor...`,
                options: (field.options || []).map((option) => {
                    return {
                        value: option?.name || option,
                        label: option?.name || option
                    }
                })
            }
        })
];

