import { Button } from "@/components/ui/button";
import { CardDescription, CardTitle } from "@/components/ui/card";
import { RefreshCw } from "lucide-react";

export const FETCHING_CONTACT_ERRORS = {
    'PERMISSION_DENIED': 'PERMISSION_DENIED',
    'CONTACT_NOT_FOUND': 'CONTACT_NOT_FOUND',
    'INTERNAL_SERVER_ERROR': 'INTERNAL_SERVER_ERROR'
} as const;

const FETCHING_CONTACT_ERRORS_DESCRIPTIONS = {
    [FETCHING_CONTACT_ERRORS.CONTACT_NOT_FOUND]: {
        title: 'Contacto no encontrado',
        description: 'El contacto que estás buscando no existe o no está registrado en nuestra base de datos.',
        image: <img src={'/illustrations/not-found.svg'} className="w-[200px] h-[200px]" />
    },
    [FETCHING_CONTACT_ERRORS.PERMISSION_DENIED]: {
        title: 'Permiso denegado',
        description: 'No tienes los permisos necesarios para acceder a la información de este contacto.',
        image: <img src={'/illustrations/error.svg'} className="w-[180px] h-[180px]" />
    },
    [FETCHING_CONTACT_ERRORS.INTERNAL_SERVER_ERROR]: {
        title: 'Error interno del servidor',
        description: 'Ocurrió un problema al intentar obtener la información del contacto. Por favor, inténtalo nuevamente.',
        image: <img src={'/illustrations/error.svg'} className="w-[180px] h-[180px]" />
    }
};

export type FetchingContactError = typeof FETCHING_CONTACT_ERRORS[keyof typeof FETCHING_CONTACT_ERRORS];

interface FetchContactErrorProps {
    onRetryFetchContact: () => void,
    fetchingError?: FetchingContactError
}

export default function FetchContactError({
    onRetryFetchContact,
    fetchingError
}: FetchContactErrorProps) {
    const {
        description,
        title,
        image
    } = FETCHING_CONTACT_ERRORS_DESCRIPTIONS[fetchingError || FETCHING_CONTACT_ERRORS.INTERNAL_SERVER_ERROR];

    return (
        <div className="flex justify-center items-center text-center flex-grow h-full">
            <div className="flex-col  flex text-center justify-center items-center sm:max-w-[60%] max-w-[90%]">
                <div className="mb-4">
                    {image}
                </div>
                <CardTitle className="text-[18px]">
                    {title}
                </CardTitle>
                <CardDescription className="text-[14px]">
                    {description}
                </CardDescription>
                {
                    (!fetchingError || fetchingError == FETCHING_CONTACT_ERRORS['INTERNAL_SERVER_ERROR']) && (
                        <Button
                            animateClick
                            variant={"destructive"}
                            onClick={onRetryFetchContact}
                            className="bg-[#FF6B6B] flex items-center gap-2 hover:bg-[#FF6B6B] h-9 rounded-full mt-6 text-xs"
                        >
                            <RefreshCw size={17} />
                            Volver a reintentar
                        </Button>
                    )
                }
            </div>
        </div>
    )
}