import ShakingInput from "@/components/ui/shaking-input";
import { useToggle } from "@/hooks/useToggle";
import { emailValidator } from "@/lib/validators/emailValidator";
import { useEffect, useRef, useState } from "react";
import FieldTextValue from "./FieldTextValue";

interface EmailInputProps {
    value: string;
    placeholder: string;
    onChangeValue: (value: string) => void
}

export default function EmailInput({ value, placeholder, onChangeValue }: EmailInputProps) {
    const [editableMode, toggleEditableMode] = useToggle<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(value);
    const inputRef = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [error, setError] = useState<string>('');

    const handleChangeDefaultValue = () => {
        if (inputValue == value) return;
        if (!error) onChangeValue(inputValue);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                handleChangeDefaultValue();
                toggleEditableMode(false);
            }
        };

        if (editableMode) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [editableMode, toggleEditableMode, handleChangeDefaultValue]);

    useEffect(() => {
        if (editableMode) {
            setError('');
            setInputValue(value);
        }
    }, [editableMode])

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const isValidEmail = !value || emailValidator(value);

        setError(!isValidEmail
            ? 'El email ingresado no es valido.'
            : ''
        );

        setInputValue(value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleChangeDefaultValue();
            toggleEditableMode(false);
        }
    };

    useEffect(() => {
        if (inputRef.current && editableMode) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(
                inputRef.current.value.length,
                inputRef.current.value.length
            );
        }
    }, [inputValue, editableMode]);

    return (
        <div ref={wrapperRef} className="relative flex items-center">
            {editableMode && (
                <div
                    className={`absolute flex flex-col bg-white ${!error ? 'my-auto ' : 'top-[-7px]'} left-[-10px] z-10`}
                    style={{ width: 'calc(100% + 9px)' }}
                >
                    <ShakingInput
                        ref={inputRef}
                        type="text"
                        inputMode="email"
                        style={{ boxShadow: '0px 4px 15px rgba(0,0,0,0.08)' }}
                        className={`outline-none font-medium text-gray-700 placeholder:text-gray-700 bg-gray-50 text-[13px] py-2 px-3 rounded-lg border h-[34px] ${error ? 'border-red-400 text-red-500' : ''}`}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder={placeholder}
                        shake={!!error}
                    />
                </div>
            )}
            <div className="overflow-hidden relative truncate w-full">
                <FieldTextValue onClick={() => toggleEditableMode(true)} className="px-2 py-0.5">
                    {value || placeholder}
                </FieldTextValue>
            </div>
        </div>
    );
}