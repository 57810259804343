import { getAvailableMeetingIntegrations } from "@/actions/crm/calendar";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export default function useGetAvailabilityMeetingIntegrations({ agent, enabled }: { agent?: string, enabled: boolean }) {

    const [integrations, setIntegrations] = useState<{
        meet: boolean,
        zoom: boolean
    }>({
        meet: false,
        zoom: false
    })

    const { data, isLoading } = useQuery({
        queryKey: ['meeting-integrations', agent],
        queryFn: () => getAvailableMeetingIntegrations(agent),
        enabled
    });

    useEffect(() => {
        if (!data) return;
        setIntegrations(data)
    }, [data]);

    return {
        isLoading,
        integrations
    }

}