import CardError from '@/components/toaster/card-error'
import { useToast } from '@/components/ui/use-toast'
import { useSelector } from 'react-redux'

export type LockedPhone = {
    phoneNumber: string,
    disableCall: boolean,
    disableCreation: boolean,
    disableChat: boolean
}

export default function useGetIsBlockNumber({ externalPhoneStates }: { externalPhoneStates?: LockedPhone[] } = {}) {
    const { toast } = useToast()
    const phoneNumberStates = externalPhoneStates || useSelector((state: any) => state?.crm?.contact?.phoneNumberStates) || []

    const findNumberState = (number: string): LockedPhone | undefined => {
        return phoneNumberStates.find((config) => config.phoneNumber === number)
    }

    const handlePressBlockNumber = (phoneNumberState: Record<string, any> = {}) => {
        toast({
            duration: 3000,
            className: "p-3 py-4",
            customElement: (
                <CardError
                    title='Número de teléfono restringido'
                    description={
                        phoneNumberState.blockReason || `La función de llamadas ha sido restringida para el número de teléfono ${phoneNumberState.phoneNumber}.`
                    }
                />
            )
        })
    }

    const toastOnCallAlert = (isUContactClickToCall = false) => {
        return toast({
            duration: 3000,
            className: "p-3 py-4",
            customElement: (
                <CardError
                    title='Contacto en llamada.'
                    description={isUContactClickToCall
                        ? "Te recomendamos abrir tu discador de llamadas y revisar si tienes una llamada activa. Si no, vuelve a realizar la llamada."
                        : "El contacto está actualmente en una llamada. Por favor, gestiona la llamada activa antes de intentar nuevamente."
                    }
                />
            )
        })
    }

    const isLockedFeature = (field: 'disableCall' | 'disableCreation' | 'disableChat', phone: string) => {
        const findSetting = phoneNumberStates.some(op => op[field] === true)
        if (!findSetting) return false
        const findDefaultValue = findNumberState(phone)
        return findDefaultValue
    };

    return {
        findNumberState,
        isLockedFeature,
        toastOnCallAlert,
        handlePressBlockNumber
    }
}
