import { Button, buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { VariantProps } from 'class-variance-authority';
import { Plus } from 'lucide-react';
import React from 'react';

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    classNameIcon?: string
}

const PlusButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ children, classNameIcon, variant = 'transparent', ...props }) => {
    return <Button variant={variant as ButtonProps['variant']} type='button' className='text-[13px] gap-2 ' {...props}>
        <Plus size={18} className={cn(classNameIcon)} />
        {children}
    </Button>
})

export default PlusButton