import { Dispatch } from "redux"
import { createInteraction } from "../../Prospect/libs/helpers/interaction"
import { MultimediaFile } from "./sendNoteWithFiles"

interface sendMultimediaWithPersonalWhatsAppProps {
    files: (MultimediaFile & { caption: string })[]
    medium: ChannelMediusType,
    contactId: string,
    dispatch: Dispatch
    channel: {
        _id?: string,
        id: string
    }
}

const ChannelMedius = {
    UnofficialWahtsapp: 'unofficial-whatsapp'
} as const

type ChannelMediusType = typeof ChannelMedius[keyof typeof ChannelMedius]

export const sendMultimediaWithPersonalWhatsApp = ({
    files,
    medium,
    contactId,
    channel,
    dispatch
}: sendMultimediaWithPersonalWhatsAppProps) => new Promise(async (resolve, reject) => {
    const promises: Promise<any>[] = []

    for (let file of files) {
        const { type, path, caption, name } = file || {}

        const fileType = getFileType(type)

        if (medium == ChannelMedius['UnofficialWahtsapp']) {
            await createInteraction({
                dispatch,
                type: 'unofficial-whatsapp',
                data: {
                    type: fileType,
                    fileName: name,
                    mimetype: type,
                    contactId,
                    file: path,
                    caption,
                }
            })
        } else {
            await createInteraction({
                dispatch,
                type: 'channel-message',
                data: {
                    file: path,
                    caption,
                    type: fileType,
                    fileName: name,
                    contactId,
                    mimetype: type,
                    channel
                }
              })
        }
    }

    Promise.all(promises)
        .then(() => resolve(true))
        .catch((e) => reject(e))
})

export const getFileType = (type: string = '') => {
    if (type.includes('image')) return 'image';
    if (type.includes('video')) return 'video';
    if (type.includes('audio')) return 'audio';
    return 'file';
}