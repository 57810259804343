import { getCalendarAvailability } from "@/actions/user";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export interface ParamsOfCalendarAvailability {
    startTime: string;
    endTime: string;
    eventTypeSlug: '30min' | '15min' | '60min';
    agent?: string
}

export default function useGetCalendarAvailability({ enabled = true }: { enabled?: boolean }) {
    const [availability, setAvailability] = useState<Record<string, { time: string }>>({});

    const [params, setParams] = useState<ParamsOfCalendarAvailability>({
        startTime: dayjs().startOf('month').toISOString(),
        endTime: dayjs().endOf('month').toISOString(),
        eventTypeSlug: '30min'
    });

    const { data, isLoading } = useQuery({
        queryKey: ['calendar-availability', params],
        queryFn: () => getCalendarAvailability(params),
        enabled
    });

    useEffect(() => {
        if (data?.result) setAvailability(data.result);
    }, [data])

    const onChangeParams = (newParams: Partial<ParamsOfCalendarAvailability>) => {
        setParams(lastValue => ({ ...lastValue, ...newParams }));
    };

    return {
        isLoading,
        availability,
        onChangeParams
    }
}
