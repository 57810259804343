import { Contact } from '@/interfaces/crm/contact'
import React from 'react'
import FancyContactInfo from './FancyContactInfo'

interface ContactProfileInformationProps {
    contact: Contact,
    open: boolean
    fixed?: boolean,
    downChatFn?: () => void,
    onClose?: () => void
}

export default function ContactProfileInformation({
    fixed = false,
    contact,
    open,
    downChatFn,
    onClose
}: ContactProfileInformationProps) {

    return (
        <React.Fragment>
            <div className={`w-[280px] xl:w-[305px] xl:relative h-full flex ${open ? "" : "-ml-[305px] translate-x-full duration-200"
                } flex-col ${!fixed ? "xl:relative" : ""
                } fixed h-[100vh] right-0 bg-white overflow-y-auto transition-all z-30 ease-out top-0`}>
                <FancyContactInfo
                    contact={contact}
                    downChatFn={downChatFn}
                    onPressCloseButton={onClose}
                />
            </div>
        </React.Fragment>
    )
}



