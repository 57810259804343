import { TooltipTrigger } from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"
import "moment/locale/es"
import Link from "next/link"
import { Tooltip, TooltipContent } from "../ui/tooltip"

export default function NavLink({
  icon,
  label,
  notification,
  href,
  className = "text-[#1F2937] text-[14px]",
  target = "",
  onAction = undefined,
  isSelected = false,
  slug,
  isCollapsedMenu = false,
  currentSlug,
  pingAnimate = false,
  classNameNotificationItem = ''
}: any) {
  const isSelect = (slug && currentSlug && slug === currentSlug) || isSelected

  return (
    <SideBarTooltip content={label} active={isCollapsedMenu}>
      <Link
        href={onAction ? 'javascript:void(0)' : href}
        className={cn(
          `flex relative  ${isCollapsedMenu
            ? "lg:px-0  lg:justify-start hover:bg-blue-100   lg:text-start lg:h-[38px] lg:w-[42px] lg:py-[10px] "
            : "hover:bg-blue-100 h-[30px] truncate"
          } ${isSelect ? isCollapsedMenu ? 'lg:bg-primary text-white hover:bg-primary/80' : 'bg-blue-100' : ""
          } shrink-0 items-center px-2 justify-between py-[5px]  whitespace-nowrap rounded-[4px] group `,
          className
        )}
        target={target}
        style={{ textDecoration: "none" }}
        onClick={onAction}
      >
        {
          pingAnimate && (
            <span className={`w-[7px] h-[7px] bg-green-400 absolute top-1 right-1 [&>span]:bg-green-400 rounded-full shrink-0`}>
              <span className="w-[7px] h-[7px]  absolute top-0 left-0 right-0 bottom-0 animate-ping m-auto rounded-full shrink-0" />
            </span>
          )
        }
        <div className={`${isCollapsedMenu ? 'lg:justify-center' : ''} flex items-center  w-full h-full gap-2`}>
          <span className={`${isSelect && isCollapsedMenu ? 'text-white' : 'text-slate-500'} `}>
            {icon}
          </span>
          <span className={`flex ${!isCollapsedMenu ? 'flex' : 'lg:hidden'} items-center text-[13px] gap-1 font-medium`}>
            {label}
          </span>
        </div>
        {
          (notification && notification > 0)
            ? (
              <NotificationItem
                notification={notification}
                className={cn(`${isCollapsedMenu ? 'absolute top-0 right-0' : ''}`, classNameNotificationItem)}
              />
            )
            : null
        }
      </Link>
    </SideBarTooltip>
  )
}

export function SideBarTooltip({ children, content, active }) {
  return active ? (
    <Tooltip delayDuration={500}>
      <TooltipTrigger className="w-full shrink-0 h-auto p-0 pb-0 m-0">{children}</TooltipTrigger>
      <TooltipContent
        align="start"
        sideOffset={-10}
        alignOffset={20}
        className="m-0 p-0 shrink-0 hidden lg:flex whitespace-nowrap px-2 left-6 text-[12px] absolute rounded-md "
      >
        {content}
      </TooltipContent>
    </Tooltip>
  ) : (
    children
  )
}

export function NotificationItem({ notification, className = "" }) {
  return (
    <div
      className={cn(
        "bg-[#6B7280] text-white font-medium text-[10px] min-w-[17px] px-[4px] flex items-center justify-center h-[17px] rounded-[4px]  shrink-0",
        className
      )}
    >
      {notification}
    </div>
  )
}
