import { isAuth } from "@/actions/auth";
import { GetUserValue } from "@/actions/company/users";
import { ItemAgentForColumns } from "@/components/item-agent-for-columns";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandList
} from "@/components/ui/command";
import { GenericComboboxSearchInput } from "@/components/ui/generic-combobox";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { UserAvatar } from "@/components/ui/user-avatar";
import { searchRegex } from "@/helpers/regex";
import { ReceiptUser } from "@/interfaces/Interface";
import { cn } from "@/lib/utils";
import _ from "lodash";
import { Check, ChevronDown } from "lucide-react";
import { useMemo, useState } from "react";

interface UserSelectProps {
    value?: ReceiptUser
    onChangeValue: (user: ReceiptUser) => void,
    users: GetUserValue[],
    editMode?: boolean,
    disabled?: boolean
}

export default function UserSelect({
    value,
    onChangeValue,
    users = [],
    editMode = true,
}: UserSelectProps) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [open, setOpen] = useState(false);

    const userId = _.get(isAuth(), '_id', '');

    const filteredOptions = useMemo(() => {
        if (!searchValue) return users;
        const regex = searchRegex(searchValue);

        return users.filter((user) => {
            return regex.test(user.name)
                || regex.test(user.lastname)
                || regex.test(`${user.name} ${user.lastname}`)
                || regex.test(user.email)
        })
    }, [searchValue, users]);

    const GetTrigger = () => {
        if (!value?._id && !editMode) return null;

        return (
            <button
                role="combobox"
                // disabled={disabled}
                aria-expanded={open}
                className="max-w-full flex disabled:opacity-50 items-center hover:text-gray-800 relative text-start text-[13.5px] group text-gray-700"
            >
                {/* {disabled && <Lock size={16} />} */}
                {
                    (!editMode && (value?._id == userId))
                        ? 'Tú'
                        : !value?._id
                            ? 'Seleccionar...'
                            : <ItemAgentForColumns agent={value} />
                }
                {
                    editMode && (
                        <ChevronDown size={16} className="opacity-50 ml-2 text-gray-800" />
                    )
                }
            </button>
        )
    };

    if (!editMode) return GetTrigger();

    return <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
            {GetTrigger()}
        </PopoverTrigger>
        <PopoverContent className="w-[250px] p-0">
            <Command>
                <GenericComboboxSearchInput
                    placeholder="Buscar usuario..."
                    onValueChange={setSearchValue}
                    value={searchValue}
                />

                <CommandList className="h-max overflow-y-auto max-h-[220px] relative flex flex-col">
                    <CommandEmpty>No se encontro el usuario.</CommandEmpty>
                    <CommandGroup>
                        {
                            filteredOptions.map((user) => {
                                const fullName = [user.name, user.lastname].filter(Boolean).join(' ');
                                return (
                                    <CommandItem
                                        key={user._id}
                                        className="py-1"
                                        onSelect={() => {
                                            setOpen(false)
                                            onChangeValue(user)
                                        }}
                                    >
                                        <div className="flex items-center gap-2">
                                            <UserAvatar user={user} className="w-[21px] shadow-sm text-[10px] h-[21px]" />
                                            {fullName}
                                        </div>
                                        <Check
                                            size={16}
                                            className={cn(
                                                "ml-auto",
                                                (value && value._id == user._id) ? "opacity-100" : "opacity-0"
                                            )}
                                        />
                                    </CommandItem>
                                )
                            })
                        }
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
}