import { isAuth } from "@/actions/auth";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { useToggle } from "@/hooks/useToggle";
import { Contact } from "@/interfaces/crm/contact";
import _ from "lodash";
import { CircleAlert, MessagesSquare } from "lucide-react";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { DialogTakeContact } from "../ContactDrawer/DialogTakeContact";
import { PhoneActionButton } from "../ContactDrawer/phone-item";
import { DialogUnifyContact } from "../ContactInformationMenu/DialogUnifyContact";
import TransferContactDialog from "../Modal/TransferContactDialog";
import { states } from "../ContactDrawer/ContactPhoneInput";

interface PhoneErrorPopoverProps {
    error: Record<string, any>,
    contact: Contact
}

export default function PhoneError({ error, contact }: PhoneErrorPopoverProps) {

    const [createSubConversation, setCreateSubConversation] = useState<string | false>(false)
    const [openTakeContactModal, setOpenTakeContactModal] = useState<boolean>(false);
    const [openUnifyContact, toggleOpenUnifyContact] = useToggle<boolean>(false);

    const { itsMine, isObtainable, error: message, contactId, subConversation, unifyContacts, unifyContact } = error;

    const router = useRouter();
    const userId = useMemo(() => _.get(isAuth(), '_id', ''), []);

    const handleCreateSubConversation = () => setCreateSubConversation(contactId);
    const handleGoToContact = (id: string) => router.push(`contact?inbox=me&id=${id}`);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <button className="w-4 h-4 flex items-center justify-center">
                    {states['error']}
                </button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-3 min-w-[200px] max-w-[300px] rounded-lg border shadow-lg bg-white">
                <div className="grid gap-2">
                    <div>
                        <p className="text-red-500 font-medium text-[12.5px]">
                            <CircleAlert size={16} className="text-red-500 inline mb-[2px] mr-1 shrink-0" />
                            {message}
                        </p>
                    </div>
                    {
                        (isObtainable || itsMine || subConversation || (unifyContacts && unifyContact)) && (
                            <div className="flex flex-col gap-2">
                                {
                                    (isObtainable) && <PhoneActionButton
                                        onClick={() => setOpenTakeContactModal(true)}
                                    >
                                        Obtener contacto
                                    </PhoneActionButton>
                                }
                                {
                                    (itsMine) && <PhoneActionButton
                                        onClick={() => handleGoToContact(contactId)}
                                    >
                                        Ir a mi contacto
                                    </PhoneActionButton>
                                }
                                {
                                    subConversation && <PhoneActionButton
                                        variant={'outline-primary'}
                                        onClick={handleCreateSubConversation}
                                    >
                                        <MessagesSquare size={18} />
                                        Crear sub conversación
                                    </PhoneActionButton>
                                }
                                {(unifyContacts && unifyContact) && <PhoneActionButton
                                    variant='outline-primary'
                                    onClick={toggleOpenUnifyContact}
                                >
                                    Unificar contacto
                                </PhoneActionButton>
                                }
                            </div>
                        )
                    }
                    {
                        openTakeContactModal && <DialogTakeContact
                            contactId={contactId}
                            open={openTakeContactModal}
                            onClose={() => setOpenTakeContactModal(false)}
                            userId={userId}
                        />
                    }
                    {
                        !!createSubConversation && <TransferContactDialog
                            type={'clone'}
                            open={createSubConversation}
                            currentUser={true}
                            onClose={() => setCreateSubConversation(false)}
                        />
                    }
                    {
                        (openUnifyContact && contact) && (
                            <DialogUnifyContact
                                onOpenChange={toggleOpenUnifyContact}
                                hiddenChangeContactB={true}
                                contact={contact}
                                open={openUnifyContact}
                                contactB={unifyContact}
                            />
                        )
                    }
                </div>
            </PopoverContent>
        </Popover>
    )
}