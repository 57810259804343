import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

export default function FieldTextValue({ children, className, onClick }: PropsWithChildren & { className?: string, onClick?: () => void }) {
    return (
        <span
            onClick={onClick}
            className={cn('font-medium text-gray-700 text-[13px] break-words ', className)}
            style={{ wordBreak: 'break-word' }}
        >
            {children}
        </span>
    )
}

