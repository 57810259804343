import { Button } from "@/components/ui/button";
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { Contact } from "@/interfaces/crm/contact";
import {
    Trash2
} from "lucide-react";
import { ReactNode } from "react";
import EmailInput from "./EmailInput";
import MixedTypeInput from "./MixedTypeInput";
import MultiSelectField from "./MultiSelectField";
import PhonenumberField from "./PhonenumberField";
import SelectFieldItem from "./SelectFieldItem";
import { FieldObjectType } from "./helpers/formFields";


export interface FieldItemProps {
    value: any,
    onChangeValue: (value: any) => void,
    field: FieldObjectType,
    contact: Contact,
    companyPhoneCode?: string,
    fieldValue?: any
    handleDeleteField: () => void
}

function FieldItem({
    handleDeleteField,
    companyPhoneCode,
    onChangeValue,
    fieldValue,
    contact,
    value,
    field
}: FieldItemProps) {
    const { icon, editable, removable, label, type, renderValue, emptyText, options = [] } = field || {}

    const GetValueElement = (): ReactNode => {
        switch (type) {
            case 'select': return (
                <SelectFieldItem
                    onChangeValue={onChangeValue}
                    renderValue={renderValue}
                    emptyText={emptyText}
                    editable={editable}
                    options={options}
                    value={value}
                />
            )
            case 'phone': return (
                <PhonenumberField
                    placeholder={emptyText || ''}
                    defaultContactNumbers={contact.phones || []}
                    onChangeValue={onChangeValue}
                    phoneCode={companyPhoneCode}
                    allPhones={fieldValue}
                    contact={contact}
                    value={value}
                />
            )
            case 'email': {
                return (
                    <EmailInput
                        onChangeValue={onChangeValue}
                        placeholder={emptyText || ''}
                        value={value}
                    />
                )
            }
            case 'number':
            case 'string': {
                return (
                    <MixedTypeInput
                        onChangeValue={onChangeValue}
                        placeholder={emptyText}
                        value={value}
                        type={type}
                    />
                )
            }
            case 'multiselect': {
                return <MultiSelectField
                    onChangeValue={onChangeValue}
                    options={options}
                    value={value}
                />
            }
            default: return null
        }
    }

    const GetElementWithFieldName = () => {
        const GetButtonWithFieldName = () => {
            return <>
                <SimpleTooltip classNameTrigger="w-max px-2 h-7 max-w-full px-0" content={label}>
                    <Button
                        className="w-max px-2 h-[25px] max-w-full"
                        variant="ghost"
                        type="button"
                    >
                        <span className="text-[13.2px] w-full flex items-center font-medium text-gray-500 gap-2">
                            <span className="mt-[2.8px]">
                                {icon}
                            </span>
                            <span className="flex-grow truncate max-w-full ">
                                {label}
                            </span>
                        </span>
                    </Button>
                </SimpleTooltip>
            </>
        }

        if (typeof removable == 'boolean' && !removable) return GetButtonWithFieldName();

        return (
            <Popover>
                <PopoverTrigger asChild>
                    <div>
                        {GetButtonWithFieldName()}
                    </div>
                </PopoverTrigger>
                <PopoverContent className="w-28 p-0" sideOffset={2}>
                    <Command className="p-0">
                        <CommandList>
                            <CommandGroup>
                                <CommandItem
                                    className="py-1"
                                    onSelect={() => handleDeleteField()}
                                >
                                    <Trash2 className="mr-2" size={17} />
                                    <span>Eliminar</span>
                                </CommandItem>
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
        )
    }

    return (
        <div className="grid grid-cols-5 gap-2">
            <div className="col-span-2" >
                {GetElementWithFieldName()}
            </div>
            <div className="col-span-3">
                {GetValueElement()}
            </div>
        </div>
    )
}

export default FieldItem;