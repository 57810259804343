import { fetcher } from "@/helpers/fetcher";
import { formatSearchParams } from "@/helpers/formatSearchParams";

export const getDocumentUrl = ({ name, mimeType = '', disposition = '' }: {
    name: string,
    mimeType?: string,
    disposition?: string
}) => {
    const searchParams = formatSearchParams({ disposition });
    
    return fetcher<{ url: string, key: string }>({
        url: `/aws/documents?${searchParams}`,
        method: 'POST',
        defaultContentType: true,
        body: {
            name,
            mimeType,
            via: 'web'
        }
    })
}