import { Button } from "@/components/ui/button";
import { searchRegex } from "@/helpers/regex";
import { ArrowLeft } from "lucide-react";
import { useMemo } from "react";
import EmptyTemplateAlert from "./EmptyTemplateAlert";
import TemplateItem, { SelecteableTemplate } from "./TemplateItem";
import TemplateItemSkeleton from "./TemplateItemSkeleton";

interface TemplateSelectProps {
    onSelectTemplate: (template: SelecteableTemplate) => void,
    templates: SelecteableTemplate[],
    onBackClick?: () => void
    hideButtonBack?: boolean
    loading?: boolean
    title: string,
    search?: string,
}

export default function TemplateSelect({
    onSelectTemplate,
    templates = [],
    onBackClick,
    title,
    search,
    hideButtonBack = false,
    loading = false
}: TemplateSelectProps) {

    const filteredTemplates: SelecteableTemplate[] = useMemo(() => {
        if (!search) return templates
        const regex = searchRegex(search)

        return templates.filter((template) => {
            return regex.test(template.title) || regex.test(template.text || '')
        })
    }, [search, templates])

    return (
        <div className="w-full h-full">
            <div className="px-4 py-3">
                <div className="flex items-center gap-2">
                    {
                        (onBackClick && !hideButtonBack) && (
                            <Button
                                size={'icon'}
                                type="button"
                                variant={'ghost'}
                                className="w-7 h-7"
                                animateClick
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onBackClick()
                                }}
                            >
                                <ArrowLeft size={19} />
                            </Button>
                        )
                    }
                    <h5 className="font-medium text-gray-800 flex items-center text-[18px]">
                        {title}
                        {
                            !loading && (
                                <span className="text-gray-600 text-[16px] ml-1.5 font-normal">
                                    {` (${filteredTemplates.length})`}
                                </span>
                            )
                        }
                    </h5>
                </div>
            </div>
            <div className="px-3 pb-3">
                {
                    (templates.length == 0 && !loading)
                        ? <EmptyTemplateAlert />
                        : <ul className="flex flex-col gap-1.5">
                            {
                                loading
                                    ? Array.from({ length: 4 }).map((__, index) => (
                                        <li key={index}>
                                            <TemplateItemSkeleton />
                                        </li>
                                    ))
                                    : filteredTemplates.map((template, index) => (
                                        <TemplateItem
                                            onSelect={() => onSelectTemplate(template)}
                                            template={template}
                                            key={index}
                                        />
                                    ))
                            }
                        </ul>
                }
            </div>
        </div>
    );
};