import { cn } from "@/lib/utils";
import { CheckIcon } from "lucide-react";
import React from "react";

export interface CheckboxProps
    extends React.ReactHTMLElement<HTMLDivElement> {
    selected?: boolean,
    className?: string,
    onCheckedChange?: (value: boolean) => void
}

const Checkbox = React.forwardRef<HTMLDivElement, any>(({
    selected,
    className,
    onCheckedChange,
    ...props
}) => {

    return <div
        {...props}
        onClick={() => onCheckedChange && onCheckedChange(!selected)}
        className={cn(`
      mr-2 
      flex h-[15px] w-[15px] items-center justify-center rounded-sm ${selected
                ? "bg-primary text-primary-foreground border opacity-100 border-primary"
                : "opacity-50 [&_svg]:invisible group-hover:opacity-100 group-hover:border border-0 border-slate-400 hover:border-primary "
            }`, className)}
    >
        <CheckIcon className={"h-4 w-4"} />
    </div>
})

export default Checkbox