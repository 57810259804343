import { Colleague } from '@/interfaces/Interface';
import { Ban, Link as LinkIcon, Loader2, Mail, Plus } from 'lucide-react';
import Link from "next/link";
import { useMemo } from 'react';
import { UseFormReturn } from "react-hook-form";
import { MeetingClient } from '.';
import { LoadingButton } from '../buttons/loading-button';
import CreateReminders from '../settings/meetings/CreateReminder';
import { Button } from "../ui/button";
import { CustomFormField } from "../ui/custom-form-field";
import { GenericMultipleCombobox } from '../ui/generic-multiple-combobox';
import { Textarea } from "../ui/textarea";
import { renderUserLabel } from './assign-user-selector';
import { MeetingFormType } from "./constants";
import ParticipantItem from './participant-item';

interface ConfirmStepProps {
    contextForm: UseFormReturn<MeetingFormType>,
    onBackStep: () => void,
    usersMap: Record<string, Colleague>,
    currentUser: Colleague,
    colleagues: Colleague[],
    client: MeetingClient,
    isInstant?: boolean
    isGeneretingLink?: boolean
    isCreatingMeeting?: boolean
}

export default function ConfirmStep({
    onBackStep,
    contextForm,
    usersMap,
    isInstant = false,
    currentUser,
    isGeneretingLink = false,
    isCreatingMeeting = false,
    client,
    colleagues
}: ConfirmStepProps) {

    const attendees = contextForm.watch('attendees');
    const assignUser = contextForm.watch('assignUser');
    const meetingLink = contextForm.watch('meetingLink');
    const isAssignedToMe = assignUser === 'me';
    const medium = contextForm.watch('medium');

    const isValidForm = contextForm.formState.isValid

    const attendeesList = useMemo(() => {
        const list = [...attendees];
        const organizer = isAssignedToMe ? currentUser : assignUser && usersMap[assignUser];

        list.unshift({
            role: 'client',
            email: client.email,
            name: client.firstName,
            lastname: client.lastName,
            photo: client.photo
        });

        if (organizer) {
            list.unshift({
                role: 'organizer',
                email: organizer.email,
                name: organizer.name,
                lastname: organizer.lastname || '',
                photo: organizer.photo || ''
            });
        }

        return list;
    }, [attendees, assignUser, isAssignedToMe, usersMap]);


    const handleChangeAttendess = (value: string[]) => {
        const participants: MeetingFormType['attendees'] = value.map((userId) => {
            const user = usersMap[userId];
            if (!user) return null;

            return {
                role: 'guest',
                user: userId,
                email: user.email,
                name: user.name,
                lastname: user.lastname,
                photo: user.photo
            }
        }).filter(Boolean) as MeetingFormType['attendees'];
        contextForm.setValue('attendees', participants);
    };

    const colleaguesOptions = useMemo(() => {
        const users = [{
            name: currentUser.name,
            lastname: currentUser.lastname,
            _id: currentUser._id,
            email: currentUser.email,
            photo: currentUser.photo
        }, ...colleagues];

        return users.filter((user) => (user._id !== (isAssignedToMe ? currentUser._id : assignUser)) && user.email);
    }, [colleagues, currentUser, assignUser, isAssignedToMe]);

    const attendeesValue = useMemo(() => {
        return attendees.map((participant) => participant.user).filter(Boolean) as string[];
    }, [attendees, isAssignedToMe, assignUser, currentUser]);

    return <div className="flex flex-col w-full px-2 py-6 h-full">
        <div className="flex-grow relative">
            <div className="md:absolute w-full flex flex-col gap-4 h-full px-2 top-0 left-0 pb-2 overflow-y-auto">
                {
                    (medium !== 'person') && <>
                        {
                            isGeneretingLink
                                ? <span className="flex items-center gap-2 text-primary text-[14px] font-medium">
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                    Generando link de la reunión...
                                </span>
                                : !meetingLink
                                    ? <span className="flex items-center gap-2 text-red-500 text-[14px] font-medium">
                                        <Ban className="h-4 w-4" />
                                        Error al generar el link...
                                    </span>
                                    : <Link
                                        href={meetingLink || ''}
                                        target="_blank"
                                        className="flex items-center gap-2 hover:underline text-primary text-[14px] font-medium"
                                    >
                                        <LinkIcon size={17} />
                                        Link de la reunión
                                    </Link>
                        }
                    </>
                }

                <div className="">
                    <span className="font-medium text-gray-800 text-[15px] flex items-center gap-2">
                        <Mail size={18} />
                        {attendeesList.length == 1 ? '1 invitado a la reunión' : `${attendeesList.length} invitados a la reunión`}
                    </span>

                    <ul className="grid gap-2 mt-2">
                        {
                            attendeesList.map((participant: any, index: number) => (
                                <ParticipantItem
                                    {...participant}
                                    key={index}
                                />
                            ))
                        }
                    </ul>

                    <GenericMultipleCombobox
                        buttonClassName="text-gray-800 w-full overflow-hidden text-[15px] px-2 h-8"
                        options={colleaguesOptions.map((colleague) => {
                            return {
                                value: colleague._id,
                                label: renderUserLabel(colleague),
                                searchableBy: [colleague.name, colleague.lastname].filter(Boolean).join(' ')
                            }
                        })}
                        placeholder="Seleccionar"
                        modal={true}
                        noResultsText="Sin resultados"
                        searchPlaceholder="Buscar..."
                        onChange={handleChangeAttendess}
                        value={attendeesValue}
                        trigger={(
                            <Button
                                className="flex hover:bg-transparent items-center hover:text-gray-700 text-[14px] mt-2 px-0 h-max gap-1"
                                variant="ghost"
                                type="button"
                            >
                                <Plus size={18} />
                                Agregar participantes
                            </Button>
                        )}
                    />
                </div>

                <CustomFormField
                    name="comment"
                    label="Comentario"
                    control={contextForm.control}
                    fnElement={({ field }) => (
                        <Textarea
                            {...field}
                            placeholder="Deja un comentario aquí..."
                            className="h-[100px]"
                            autoComplete="off"
                        />
                    )}
                />

                {
                    !isInstant && <>
                        <CreateReminders contextForm={contextForm} />

                        <CreateReminders
                            contextForm={contextForm}
                            hiddenUpdateMessageButton={true}
                            description='Programa recordatorios automáticos por correo electrónico y notificaciones push dirigidos a los participantes que son de la empresa.'
                            configBase='reminders'
                            label='Recordatorios'
                        />
                    </>
                }
            </div>
        </div>
        <div className="w-full flex items-center pt-[1px] gap-2 px-2 justify-end">
            <Button
                type="button"
                variant='ghost'
                onClick={onBackStep}
            >
                Volver
            </Button>
            <LoadingButton
                type="submit"
                form="meeting-form"
                disabled={isGeneretingLink || !isValidForm}
                loading={isCreatingMeeting}
            >
                Agendar
            </LoadingButton>
        </div>
    </div>
}

