import { Button } from "@/components/ui/button";
import { GenericCombobox } from "@/components/ui/generic-combobox";
import FieldTextValue from "./FieldTextValue";
import { FieldObjectType } from "./helpers/formFields";

interface SelectFieldItemProps {
    renderValue: FieldObjectType['renderValue'],
    onChangeValue: (value: string) => void,
    options: FieldObjectType['options'],
    editable?: boolean,
    emptyText?: string,
    value: string
}

export default function SelectFieldItem({
    onChangeValue,
    editable = true,
    emptyText,
    value,
    options = [],
    renderValue
}: SelectFieldItemProps) {
    const optionSelected = options.find((option) => option.value == value);
    const isNotEditable = typeof editable == 'boolean' && !editable;

    const getTrigger = () => {
        return (
            <div>
                <Button
                    className={`px-2 h-6 rounded-full ${isNotEditable ? 'hover:bg-white cursor-text' : ''}`}
                    variant={'outline'}
                    type="button"
                >
                    {
                        !value
                            ? <FieldTextValue>{emptyText}</FieldTextValue>
                            : !optionSelected
                                ? <FieldTextValue>{value}</FieldTextValue>
                                : renderValue
                                    ? renderValue(optionSelected)
                                    : <FieldTextValue>{optionSelected?.label}</FieldTextValue>
                    }
                </Button>
            </div>
        )
    };

    if (isNotEditable) return getTrigger();

    return (
        <GenericCombobox
            options={[...options.map((opt) => ({
                value: opt.value,
                label: opt.render ? opt.render() : opt.label,
                searchableBy: [opt.label],
            }))]}
            value={value}
            deselectValue={false}
            trigger={getTrigger()}
            noResultsText="Sin resultados..."
            onChange={(value) => onChangeValue(value == 'empty_value' ? '' : value)}
        />
    )
}


