import { Button } from "@/components/ui/button";
import { MouseEvent, MouseEventHandler, PropsWithChildren } from "react";

export default function ActionButton({ children, onClick }: (PropsWithChildren & { onClick?: (evt: MouseEvent<HTMLButtonElement, MouseEvent>) => void })) {
    return (
        <Button
            size="icon"
            animateClick
            tabIndex={0}
            type="button"
            className="bg-gray-100 w-[38px] h-[38px] text-gray-500 hover:bg-gray-200"
            onClick={(ev) => onClick && onClick(ev as any)}
        >
            {children}
        </Button>
    )
}
