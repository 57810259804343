import { cn } from "@/lib/utils";
import { setInputProperty } from "@/redux/slices/crm";
import "@/styles/scrollModify.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ChatInputProvider } from "../ChatInput/Context";
import EditMessageCard from "../ChatInput/EditMessageCard";
import ReplyMessageCard from "../ChatInput/ReplyMessageCard";
import FancyTemplateSelect from "../ChatInput/TemplateSelect/new";
import ButtonMoveInputHeight from "./ButtonMoveInputHeight";
import { ChatInputHandler } from "./ChatInputHandler";
import SelectAction from "./SelectAction";

export function BottomActions({
    setOpenUpdateContact,
    action,
    activeChannel,
    dispatch,
    downChatFc,
    handelActionPress,
    fromBackOffice,
    id,
    prospect,
    sendMessage,
    sendMultimedia,
    sendMultimediaPersonalWPP,
    isFromSharedInbox = false,
    setAction
}) {
    const isActiveTemplateMode = useSelector((state: any) => state.crm.isActiveTemplateMode);
    const { height: inputHeight } = useSelector((state: any) => state.crm.inputProperties);
    const editInteraction = useSelector((state: any) => state.crm.editInteraction);
    const isReply = useSelector((state: any) => state.crm.reply);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight < 600) {
                dispatch(setInputProperty({
                    height: 110
                }));
                return;
            } else if (window.innerHeight >= 600 && window.innerHeight < 700) {
                dispatch(setInputProperty({
                    height: 150
                }));
                return;
            }
        };

        window.addEventListener("resize", handleResize);

        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <ChatInputProvider>
            {isActiveTemplateMode && (
                <div className="bg-white m-3 rounded-lg overflow-y-auto max-h-[240px] border-2">
                    <FancyTemplateSelect
                        type="user-template"
                        channel={activeChannel}
                        sendMessage={(message) => {
                            if (message.type == 'multimedia') {
                                let sendFn: any = undefined;
                                const file = message.file;

                                if ((action == "unofficial-whatsapp" || action === 'unofficial-whatsapp-from-shared-inbox')) {
                                    sendFn = sendMultimediaPersonalWPP
                                } else if ([
                                    "whatsapp",
                                    "facebook",
                                    "instagram",
                                    "channel-chat",
                                    "channel-sms"
                                ].includes(action)) {
                                    sendFn = sendMultimedia
                                }

                                if (sendFn) {
                                    sendFn(file.url, "audio", '', file.name || 'voice-note.mp3', file.mimetype)
                                }
                            }
                        }}
                    />
                </div>
            )}
            {
                editInteraction && <EditMessageCard interaction={editInteraction} />
            }
            {
                isReply && <ReplyMessageCard interaction={isReply} classNameContainer="mx-3 mb-3" />
            }
            <div
                className=" mt-0 mx-3 mb-3 flex flex-col overflow-hidden rounded-xl shadow-md border z-10"
            >
                {
                    (!editInteraction && !isReply) && (
                        <div className={`justify-start group flex w-full h-[50px] bg-white relative overflow-x-auto`}>
                            <SelectAction
                                contactId={id}
                                isFromSharedInbox={isFromSharedInbox}
                                setOpenUpdateContact={setOpenUpdateContact}
                                onAction={handelActionPress}
                                action={action}
                            />
                            <div className="absolute opacity-0 group-hover:opacity-100 right-4 top-[11px]">
                                <ButtonMoveInputHeight />
                            </div>
                        </div>
                    )
                }
                <div
                    style={{ height: `${inputHeight}px` }}
                    className={cn(`w-full bg-crm flex  justify-center flex-grow items-center overflow-y-auto`, 'short:h-3')}>
                    <ChatInputHandler key={action} action={action} activeChannel={activeChannel} dispatch={dispatch} downChatFc={downChatFc} handelActionPress={handelActionPress} id={id} prospect={prospect} sendMessage={sendMessage} sendMultimedia={sendMultimedia} fromBackOffice={fromBackOffice} sendMultimediaPersonalWPP={sendMultimediaPersonalWPP} setAction={setAction} />
                </div>
            </div >
        </ChatInputProvider>
    )
}

