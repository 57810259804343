interface GoalStats {
    contacts: number,
    contactsValue: number,
    sale: number,
    saleValue: number,
    tracing: number,
    salePlus: number,
    tracingValue: number
}

export const validateReachedGoal = ({ contacts, contactsValue, sale, saleValue, tracing, salePlus, tracingValue }: GoalStats) => {
    if (!contacts && !sale && !tracing && !salePlus) return false;
    return contactsValue >= contacts && saleValue >= sale && tracingValue >= tracing && saleValue >= salePlus;
};