import InputWithSelect from "@/components/ui/input-with-select"
import useGetContacts from "@/hooks/crm/useGetContacts"
import { Contact } from "@/interfaces/crm/contact"
import { useMemo } from "react"
import { ContactAvatar } from "./Prospect"

interface SelectContactProps {
    value?: Contact,
    onChangeValue: (contact: Contact) => void
}

export default function SelectContact({ value, onChangeValue: handleChangeValue }: SelectContactProps) {
    const { contacts, isLoading, hasMore, onChangePage, searchParams, onChangeFilters } = useGetContacts();

    const options = useMemo(() => {
        return contacts.map((contact) => {
            return {
                label: [contact?.firstName, contact?.lastName].filter(Boolean).join(' '),
                value: contact._id,
                extras: contact
            }
        })
    }, [contacts]);

    const onChangeValue = (value: { extras: Contact }) => {
        if (!value?.extras) return null;
        handleChangeValue(value?.extras)
    };

    const GetRenderItem = ({ extras, label }: any) => {
        return (
            <div className='flex items-center gap-2'>
                <ContactAvatar
                    firstName={extras.firstName}
                    className="w-[20px] h-[20px] text-[10px]"
                    statusTag={extras.statusTag}
                    lastName={extras.lastName}
                    photo={extras.photo}
                />
                <p>{label}</p>
            </div>
        )
    };

    return (
        <InputWithSelect
            strict={true}
            placeholder="Seleccionar agente a transferir..."
            onChangeInputSearch={(value) => onChangeFilters({ search: value })}
            onChangeValue={(__, realValue) => onChangeValue(realValue as { extras: Contact })}
            isLoading={isLoading}
            closeWithSelect
            emptyIndicator={"Sin resultados para la busqueda"}
            value={(value && { label: `${value?.firstName} ${value?.lastName}`, value }) as any}
            options={options}
            classNameContentList="max-h-[240px]"
            renderItem={GetRenderItem}
            infiniteScroll={{
                next: () => onChangePage(searchParams.page + 1),
                dataLength: contacts.length,
                loading: isLoading,
                hasMore
            }}
        />
    )
}