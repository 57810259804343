import { fetcher } from "@/helpers/fetcher"
import { formatSearchParams } from "@/helpers/formatSearchParams"

export const getCalendarEvents = (filters: Record<string, any> = {}) => {
    const params = formatSearchParams(filters)
    return fetcher<{ success: boolean, events: Array<any>, calendarConfig: any }>({
        url: `/calendar/events?${params}`,
        defaultContentType: true
    })
}

export const getMeetingsList = (filters: Record<string, any> = {}) => {
    const params = formatSearchParams(filters)
    return fetcher<{ success: boolean, meetings: Array<any> }>({
        url: `/calendar/meetings?${params}`,
        defaultContentType: true
    })
}

export interface CreateCalendarEventParams {
    medium: string,
    agent?: string,
    summary: string,
    startDate: string,
    duration: { value: number, unit: string }
};

export const createMeetingLink = (body: CreateCalendarEventParams) => {
    return fetcher<{ success: boolean, link: string }>({
        url: `/calendar/meetingLink`,
        defaultContentType: true,
        method: 'POST',
        body
    })
}

export const getAvailableMeetingIntegrations = (agent?: string) => {
    return fetcher<{ meet: boolean, zoom: boolean }>({
        url: `/calendar/meetings/integrations?agent=${agent || ''}`,
        defaultContentType: true,
        method: 'GET'
    })
}