import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const contactSchemaForm = z.object({
    firstName: z
        .string({ required_error: "Este campo es obligatorio" })
        .min(2, { message: "Debe tener al menos 2 caracteres" })
        .max(100, { message: "No debe tener más de 32 caracteres" }),
    lastName: z.string().max(100, { message: "No debe tener más de 32 caracteres" }),
    phones: z.array(z.string()),
    phonesMetadata: z.array(z.object({
        extensionNumber: z.string()
    })),
    emails: z.array(z.any().optional()),
    govId: z.string().optional(),
    statusTag: z.any().optional(),
    additionalData: z.any().optional(),
    contactLocation: z.any().optional(),
    detail: z.string().optional(),
    affiliatedCompanyGroup: z.any().optional(),
    origin: z.string(),
    photo: z.string().optional()
});

export type ContactFormValueType = z.infer<typeof contactSchemaForm>;

export const defaultContactValue: ContactFormValueType = {
    firstName: "",
    lastName: "",
    phones: [""],
    emails: [],
    origin: undefined,
    photo: '',
    detail: "",
    govId: "",
    additionalData: {},
    statusTag: undefined,
    phonesMetadata: [{ extensionNumber: '' }]
} as any


export type UseContactFormReturn = UseFormReturn<ContactFormValueType>