import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import React, { InputHTMLAttributes, forwardRef, useEffect, useState } from "react";

interface ShakingInputProps extends InputHTMLAttributes<HTMLInputElement> {
  shake?: boolean;
  shakeDuration?: number;
  offset?: number;
  classNameContent?: string;
  downElement?: React.ReactNode
}

const ShakingInput = forwardRef<HTMLInputElement, ShakingInputProps>(
  ({ shake = false, downElement, classNameContent, offset = 5, shakeDuration = 500, ...props }, ref) => {
    const [isShaking, setIsShaking] = useState(false);

    useEffect(() => {
      if (shake) {
        setIsShaking(true);
        const timer = setTimeout(() => setIsShaking(false), shakeDuration);
        return () => clearTimeout(timer);
      } else {
        setIsShaking(false);
      }
    }, [shake, shakeDuration]);

    const shakeAnimation = isShaking
      ? {
        x: [0, -offset, offset, -offset, 0],
        transition: { duration: shakeDuration / 1000 },
      }
      : {};

    return (
      <motion.div animate={shakeAnimation} className={cn('relative', classNameContent)}>
        <input
          {...props}
          ref={ref}
          className={cn(`border p-2 w-full`, props.className ?? "")}
        />
        {downElement}
      </motion.div>
    );
  }
);

ShakingInput.displayName = "ShakingInput";
export default ShakingInput;
