'use client';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import ReactDOM from 'react-dom';

interface ReachedGoalAlertProps { show?: boolean }

export default function ReachedGoalAlert({show }: ReachedGoalAlertProps) {
    const [hidden, setHidden] = useState<boolean>(false)

    useEffect(() => {
        if (!show) return;

        const timer = setTimeout(() => {
            setHidden(true);
        }, 5000);

        return () => clearTimeout(timer);
    }, [show])

    if (hidden || !show) return false;

    return ReactDOM.createPortal(<GoalAlert />,
        document.getElementById('portal-alerts'))
}

export function GoalAlert() {
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [recycle, setRecycle] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        };

        const timer = setTimeout(() => {
            setRecycle(false);
        }, 2000);

        window.addEventListener('resize', handleResize);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="w-full z-[99999] h-full fixed top-0 left-0">
            <Confetti
                height={windowSize.height}
                width={windowSize.width}
                numberOfPieces={320}
                tweenDuration={2000}
                recycle={recycle}
            />
        </div>
    );
}