import { unifyContact } from "@/actions/crm/contact"
import { LoadingButton } from "@/components/buttons/loading-button"
import { toastActionError } from "@/components/comercial/comercialManagment/comissions/CommisionModal"
import { Button } from "@/components/ui/button"
import { CustomFormField } from "@/components/ui/custom-form-field"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog"
import { Form } from "@/components/ui/form"
import { Label } from "@/components/ui/label"
import { Contact } from "@/interfaces/crm/contact"
import { ModalPropsBase } from "@/interfaces/Interface"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import SelectContact from "../SelectContact"
import UnifyFeedbackTable from "./UnifyFeedbackTable"

interface DialogUnifyContactProps extends ModalPropsBase {
    contact: Contact
    contactB?: Contact,
    hiddenChangeContactB?: boolean
}

const unifyContactFormSchema = z.object({
    contactA: z.string().min(1, { message: 'El contacto A es requerido.' }),
    contactB: z.string().min(1, { message: 'El contacto B es requerido.' }),
    base: z.enum(['A', 'B'])
});

type UnifyContactFormType = z.infer<typeof unifyContactFormSchema>;

const defaultFormValue: UnifyContactFormType = {
    contactA: '',
    contactB: '',
    base: "A"
}

export function DialogUnifyContact({
    open,
    contact,
    onOpenChange,
    contactB,
    hiddenChangeContactB = false
}: DialogUnifyContactProps) {
    const [contactSelected, setContactSelected] = useState<Contact | undefined>(contactB);

    const contextForm = useForm<UnifyContactFormType>({
        resolver: zodResolver(unifyContactFormSchema),
        defaultValues: defaultFormValue
    });

    const isValidForm = contextForm.formState.isValid;
    const base = contextForm.watch('base');
    const router = useRouter();

    const { mutate, isPending } = useMutation({
        mutationFn: ({ contactA, contactB }: Omit<UnifyContactFormType, 'base'>) => unifyContact(contactA, contactB),
        onError: toastActionError,
        onSuccess: ({ contactBaseId }) => {
            return router.push({
                query: { ...router.query, id: contactBaseId },
                pathname: '/contact',
            }).then(onOpenChange);
        },
    });

    const onSubmit = (value: UnifyContactFormType) => {
        const useContactA = value.base == 'B' ? value.contactB : value.contactA;
        const useContactB = value.base == 'B' ? value.contactA : value.contactB;
        mutate({ contactA: useContactA, contactB: useContactB });
    };

    const handleChangeBase = (base: 'A' | 'B') => contextForm.setValue('base', base);

    useEffect(() => {
        if (!open) return;
        setContactSelected(undefined);
        contextForm.reset({ ...defaultFormValue, contactA: contact._id });

        if (contactB) {
            setContactSelected(contactB);
            contextForm.setValue('contactB', contactB._id);

            if (new Date(contactB.createdAt as string) < new Date(contact.createdAt as string)) {
                handleChangeBase('B');
            }
        }
    }, [open]);

    if (!open) return null;

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[900px] px-3">
                <DialogHeader className="px-2">
                    <DialogTitle>Unificar contacto</DialogTitle>
                    <DialogDescription>
                        Busca el contacto antiguo con el que deseas unificar la información. Recuerda que esta acción no se puede deshacer.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex flex-col gap-2 pb-2 px-2 max-h-[450px] overflow-y-auto">
                    <Form {...contextForm}>
                        <form
                            onSubmit={contextForm.handleSubmit(onSubmit)}
                            id="unifyContactForm"
                        >
                            {
                                !hiddenChangeContactB && (
                                    <CustomFormField
                                        control={contextForm.control}
                                        name="contactB"
                                        isRequired={true}
                                        label="Contacto con el que deseas unificar"
                                        fnElement={({ field }) => {
                                            return <SelectContact
                                                value={contactSelected}
                                                onChangeValue={(contactB) => {
                                                    const base = (new Date(contactB.createdAt) < new Date(contact.createdAt))
                                                        ? 'B'
                                                        : 'A';

                                                    handleChangeBase(base);
                                                    field.onChange(contactB._id);
                                                    setContactSelected(contactB);
                                                }}
                                            />
                                        }}
                                    />
                                )
                            }
                            <div className="flex-col gap-2 mt-2 hidden md:flex">
                                <Label className="text-[15px] text-gray-700 font-medium">
                                    Vista previa de la unificación:
                                </Label>
                                <UnifyFeedbackTable
                                    onChangeBase={handleChangeBase}
                                    contactB={contactSelected}
                                    contactA={contact}
                                    base={base}
                                />
                                <p className="text-[13px] text-gray-500 mt-1.5">
                                    NOTA: La siguiente tabla muestra algunos de los campos que serán reemplazados, pero no incluye la lista completa. Además, todas las interacciones y configuraciones asociadas con el contacto B serán transferidas al contacto A y esta acción no es reversible.
                                </p>
                            </div>
                        </form>
                    </Form>
                </div>
                <DialogFooter className="px-2">
                    <Button
                        type="button"
                        onClick={() => onOpenChange(false)}
                        variant="outline-primary"
                    >
                        Cancelar
                    </Button>
                    <LoadingButton
                        type={"submit"}
                        loading={isPending}
                        form="unifyContactForm"
                        disabled={!isValidForm}
                    >
                        Unificar
                    </LoadingButton>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
};