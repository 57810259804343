"use client"

import { Check, ChevronDown, Search } from "lucide-react"
import * as React from "react"

import { Button } from "@/components/ui/button"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandList
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { searchRegex } from "@/helpers/regex"
import { cn } from "@/lib/utils"

type Option = {
    value: string;
    label: React.ReactNode,
    searchableBy: string[],
    disabled?: boolean,
    render?: (option: Option) => JSX.Element,
    forceClosePopover?: boolean
};

type GenericComboboxProps = {
    options: Option[];
    placeholder?: string;
    searchPlaceholder?: string;
    noResultsText?: string;
    classNameCommand?: string;
    className?: string;
    buttonClassName?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    trigger?: React.ReactElement,
    modal?: boolean,
    deselectValue?: boolean
    value?: string,
    renderResult?: (value: string) => React.ReactNode,
    hideCheck?: boolean
    align?: 'start' | 'center' | 'end',
    closePopoverWhenSelectOption?: boolean
    hideSearchInput?: boolean
};

export function GenericCombobox({
    options = [],
    placeholder = "Select an option...",
    searchPlaceholder = "Buscar...",
    noResultsText = "Sin resultados....",
    className = "",
    classNameCommand,
    disabled,
    buttonClassName = "",
    onChange,
    modal = false,
    deselectValue = false,
    hideCheck = false,
    hideSearchInput = false,
    trigger,
    renderResult,
    value = '',
    align = 'start',
    closePopoverWhenSelectOption = true
}: GenericComboboxProps) {
    const [open, setOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState<string>('');

    const handleSelect = (selectedValue: string, option: Option) => {
        if (deselectValue && selectedValue === value) return;

        const newValue = selectedValue === value ? "" : selectedValue;
        (closePopoverWhenSelectOption || option.forceClosePopover) && setOpen(false);
        if (onChange) onChange(newValue);
    };

    const filteredOptions = React.useMemo(() => {
        if (!searchValue) return options;
        const regex = searchRegex(searchValue);

        return options.filter((option) => {
            return option.searchableBy.some((searchableBy) => regex.test(searchableBy));
        });
    }, [options, searchValue]);

    const GetTrigger = () => {
        if (trigger) return trigger;

        return (
            <Button
                variant="ghost"
                role="combobox"
                type="button"
                aria-expanded={open}
                className={cn(`w-max justify-between ${disabled ? "cursor-default hover:bg-transparent" : ""} ${open ? 'bg-accent' : ''}`, buttonClassName)}
            >
                {value
                    ? renderResult ? renderResult(value) : options.find((option) => option.value === value)?.label
                    : placeholder}
                {
                    !disabled && (<ChevronDown className={`ml-2 ${open ? "rotate-180" : ""} shrink-0 duration-100`} size={17} />)
                }
            </Button>
        )
    }

    if (disabled || options.length == 0) return GetTrigger();

    return (
        <Popover open={open} onOpenChange={setOpen} modal={modal}>
            <PopoverTrigger asChild>
                {GetTrigger()}
            </PopoverTrigger>
            <PopoverContent className={`w-full max-w-[300px] p-0 ${className}`} align={align}>
                <Command className={cn('max-h-[260px]', classNameCommand)}>
                    {
                        !hideSearchInput && (
                            <GenericComboboxSearchInput
                                placeholder={searchPlaceholder}
                                onValueChange={setSearchValue}
                                value={searchValue}
                            />
                        )
                    }
                    <CommandList>
                        <CommandEmpty>{noResultsText}</CommandEmpty>
                        <CommandGroup>
                            {filteredOptions.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    value={option.value}
                                    className={`overflow-hidden ${option.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={option.disabled}
                                    onSelect={() => !option.disabled && handleSelect(option.value, option)}
                                >
                                    {
                                        option.render
                                            ? option.render(option)
                                            : <span className="truncate max-w-full">
                                                {option.label}
                                            </span>
                                    }
                                    {
                                        !hideCheck && (
                                            <Check
                                                size={15}
                                                className={cn(
                                                    value === option.value ? "opacity-100 ml-2" : "opacity-0"
                                                )}
                                            />
                                        )
                                    }
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}


interface GenericComboboxSearchInputProps {
    onValueChange: (value: string) => void
    value: string,
    placeholder?: string
}

export function GenericComboboxSearchInput({
    onValueChange,
    value,
    placeholder
}: GenericComboboxSearchInputProps) {

    return (
        <div className="flex items-center border-b px-3">
            <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            <input
                onChange={(ev) => onValueChange(ev.target.value)}
                placeholder={placeholder}
                value={value}
                className="flex w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 h-9"
            />
        </div>
    )
}