import { Button } from "@/components/ui/button"
import { ReminderMeetingBase } from "@/interfaces/crm/contact"
import dayjs from "dayjs"
import 'dayjs/locale/es'
import { BellRing } from "lucide-react"
import moment from "moment"
import { setRedirectToInteraction } from "@/redux/slices/crm"
import { useDispatch, useSelector } from "react-redux"
import { meetingTypes } from "../Modal/meeting/select-meeting-type"
dayjs.locale('es')

interface MeetingReminderBubbleProps {
    interaction: ReminderMeetingBase
}

const MeetingReminderBubble = ({ interaction }: MeetingReminderBubbleProps) => {
    const { content } = interaction || {}
    const { meeting, meetingDate, meetingType, reminderDate, summary } = content || {}
    const { icon: iconMedium, label: mediumLabel } = meetingTypes[meetingType] || {};

    const contact = useSelector((state: any) => state.crm.contact);
    const dispatch = useDispatch();

    const handleViewInteraction = () => {
        dispatch(setRedirectToInteraction({
            contact: contact._id,
            interaction: meeting
        }));
    };

    return (
        <div className="w-full justify-center  flex items-center">
            <div className="bg-white shadow-bubble-phone flex rounded-md md:min-w-[380px] min-w-auto max-w-[380px] overflow-hidden border flex-col relative">
                <div className="flex items-center px-4  h-[35px] w-full" style={{ backgroundColor: '#ffe4e6' }}>
                    <h6 className={"font-medium  flex items-center gap-2 text-[13px]"} style={{ color: '#f43f5e' }}>
                        <BellRing size={17} />
                        Recordatorio de reunión
                    </h6>
                </div>
                <div className="flex p-4  flex-col gap-2 w-full">
                    <h4 className="text-[17px] truncate line-clamp-2 font-medium text-gray-800">
                        Recordatorio de reunión {calculateDifference(new Date(reminderDate), new Date(meetingDate))}
                    </h4>
                    <ul className={"flex flex-col relative overflow-hidden max-w-[100%] gap-3"}>
                        <li>
                            <div className="flex items-start gap-2">
                                <span className="font-medium text-[15px] text-gray-600">
                                    Asunto:
                                </span>
                                <span className="flex items-center text-[13px] font-medium text-gray-500 gap-1">
                                    {summary}
                                </span>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-start gap-2">
                                <span className="font-medium text-[15px] text-gray-600">
                                    Medio:
                                </span>
                                <span className="flex items-center text-[13px] font-medium text-gray-500 gap-1">
                                    <span className="[&>svg]:w-[15px] [&>svg]:h-[15px]">
                                        {iconMedium}
                                    </span>
                                    {mediumLabel}
                                </span>
                            </div>
                        </li>
                    </ul>
                    <div className="w-full flex justify-end mt-2">
                        <Button
                            variant="link"
                            className="h-5 px-2 flex items-center gap-2 text-[13px]"
                            onClick={handleViewInteraction}
                        >
                            Ir a la reunión
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}


const calculateDifference = (startDate: Date, endDate: Date): string => {
    const start = moment(startDate);
    const end = moment(endDate);

    const differenceInMinutes = end.diff(start, 'minutes');
    const differenceInHours = end.diff(start, 'hours');
    const differenceInDays = end.diff(start, 'days');
    const differenceInMonths = end.diff(start, 'months');
    const differenceInYears = end.diff(start, 'years');

    if (differenceInYears > 0) {
        return `en ${differenceInYears} año${differenceInYears === 1 ? '' : 's'}`;
    } else if (differenceInMonths > 0) {
        return `en ${differenceInMonths} mes${differenceInMonths === 1 ? '' : 'es'}`;
    } else if (differenceInDays > 0) {
        return `en ${differenceInDays} día${differenceInDays === 1 ? '' : 's'}`;
    } else if (differenceInHours > 0) {
        return `en ${differenceInHours} hora${differenceInHours === 1 ? '' : 's'}`;
    }

    return `en ${differenceInMinutes} minuto${differenceInMinutes === 1 ? '' : 's'}`;
}

export default MeetingReminderBubble