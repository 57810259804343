import ConditionalElement from "@/components/ConditionalElement";
import { GenericCombobox } from "@/components/ui/generic-combobox";
import { CHANNELS_TYPE_DATA } from "@/constants/crm/channels";
import { Contact } from "@/interfaces/crm/contact";
import { setForceSelectChannel } from "@/redux/slices/crm";
import { MessageSquareMore, PhoneCall } from "lucide-react";
import React, { useCallback, useMemo } from "react";
import { IoMdAlert } from "react-icons/io";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { createInteraction } from "../Prospect/libs/helpers/interaction";
import useGetIsBlockNumber from "../Prospect/libs/hooks/useGetBlockNumber";
import ActionButton from "./ActionButton";

interface ActionsListProps {
    downChatFn?: () => void,
    contact: Contact
}

export default function ActionsList({ contact, downChatFn }: ActionsListProps) {
    const { phones = [], emails = [], channels = [], interactions = [] } = contact;

    const dispatch = useDispatch();
    const handleClickChannel = (channel: any) => {
        dispatch(setForceSelectChannel(channel));
    }

    return (
        <div className="flex items-center justify-center flex-wrap gap-3 mt-2.5">
            <CallAction contact={contact} downChatFn={downChatFn} dispatch={dispatch} />
            {
                channels.map((channel, index) => {
                    return (
                        <ActionButton key={index} onClick={() => handleClickChannel(channel)}>
                            {CHANNELS_TYPE_DATA[channel.type]?.icon || <MessageSquareMore size={20} />}
                        </ActionButton>
                    )
                })
            }
        </div>
    )
}

const CallAction = React.memo(({ contact, downChatFn, dispatch }: Pick<ActionsListProps, 'contact' | 'downChatFn'> & {
    dispatch: Dispatch<any>
}) => {
    const { interactions = [], phones = [] } = contact
    const { handlePressBlockNumber, isLockedFeature, toastOnCallAlert } = useGetIsBlockNumber();

    const isOnCall = useMemo(() => {
        const lastInteraction = interactions[0];
        return lastInteraction && lastInteraction.type == 'phoneCall' && lastInteraction.content.state == 'calling';
    }, [interactions])

    const parsePhoneOption = useCallback((phone: string) => {
        const isCallFeatureLocked = isLockedFeature('disableCall', phone);

        return ({
            value: phone,
            label: phone,
            searchableBy: [phone],
            forceClosePopover: !isCallFeatureLocked,
            render: ({ label }) => {
                return <span className={`flex items-center gap-2 `}>
                    <span className={`${isCallFeatureLocked ? 'opacity-60' : ''}`}>
                        {label}
                    </span>
                    {isCallFeatureLocked && <IoMdAlert size={14} className="text-[#F54457]" />}
                </span>
            }
        })
    }, [phones]);

    const handleCallPhone = (phone: string) => {
        const isCallFeatureLocked = isLockedFeature('disableCall', phone);
        if (isCallFeatureLocked) return handlePressBlockNumber();
        if (isOnCall) return toastOnCallAlert();

        createInteraction({
            dispatch,
            type: 'phonecall',
            data: {
                id: contact._id,
                onSuccess: () => downChatFn && downChatFn(),
                onError: (error: string) => console.error(error),
                phone
            }
        })
    };

    return (
        <ConditionalElement show={phones.length > 0}>
            <GenericCombobox
                options={phones.map(parsePhoneOption)}
                closePopoverWhenSelectOption={false}
                classNameCommand="max-h-[180px]"
                onChange={handleCallPhone}
                disabled={isOnCall}
                hideSearchInput
                align="center"
                hideCheck
                trigger={
                    <div className="relative">
                        <ActionButton
                            onClick={(evt) => {
                                if (isOnCall) {
                                    evt.stopPropagation();
                                    toastOnCallAlert();
                                }
                            }}>
                            <PhoneCall size={20} className={`${isOnCall ? 'text-green-500' : ''} `} />
                        </ActionButton>
                    </div>
                }
            />
        </ConditionalElement>
    )
})
