import { Button } from '@/components/ui/button'
import { FormField } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import SimpleTooltip from '@/components/ui/simple-tooltip'
import { useToggle } from '@/hooks/useToggle'
import { cn } from '@/lib/utils'
import { AlarmClock, Mail, MessageSquare, PencilLine, Plus, Trash2 } from 'lucide-react'
import EditReminderModal from './EditReminderModal'
import { SchemaEditReminderType } from './utils/constants'

const defaultReminder = {
    unitAndNumber: {
        number: 30,
        unit: 'minutes'
    },
    medium: 'whatsApp',
    messageContent: {
        message: ''
    }
}

const unitOptions = [
    {
        label: 'Minutos',
        singular: 'Minuto',
        code: 'minutes'
    },
    {
        label: 'Horas',
        singular: 'Hora',
        code: 'hours'
    },
    {
        label: 'Días',
        singular: 'Día',
        code: 'days'
    }
]

export default function CreateReminders({
    contextForm,
    hiddenUpdateMessageButton = false,
    label = 'Notificaciones',
    classNameLabel,
    maxReminders = 3,
    description = '',
    configBase = 'notifications'
}: {
    contextForm: any,
    label?: string,
    description?: string,
    maxReminders?: number
    hiddenUpdateMessageButton?: boolean,
    configBase?: string
    classNameLabel?: string
}) {
    const reminders = contextForm.watch(configBase) || []

    const onHandleAddReminder = () => {
        contextForm.setValue(configBase, [...reminders, defaultReminder])
    }

    const onHandleDeleteReminder = (inx: number) => {
        const allValues = [...reminders]
        allValues.splice(inx, 1)
        contextForm.setValue(configBase, allValues)
    }

    return (
        <div className='flex flex-col gap-1'>
            <div className={`${reminders.length > 0 ? 'mb-2' : ''}`}>
                <Label className={cn(classNameLabel)}>
                    {label}
                </Label>
                <p className="text-[13px] text-gray-500">
                    {description || `Envía notificaciones personalizables al cliente antes del inicio de la reunión.`}
                </p>
            </div>

            <ul className='grid gap-2'>
                {
                    reminders.map((__, inx: number) => (
                        <li key={`reminder-item-${inx}`}>
                            <ReminderItem
                                index={inx}
                                contextForm={contextForm}
                                configBase={configBase}
                                hiddenUpdateMessageButton={hiddenUpdateMessageButton}
                                onHandleDeleteReminder={onHandleDeleteReminder}
                            />
                        </li>
                    ))
                }
            </ul>

            {
                reminders.length < maxReminders && (
                    <Button
                        className={`flex items-center gap-2 px-3 h-8 w-max text-[13px] ${reminders.length > 0 ? 'mt-2' : ''}`}
                        onClick={onHandleAddReminder}
                        type='button'
                        variant="outline-primary"
                    >
                        <Plus size={16} />
                        Agregar recordatorio
                    </Button>
                )
            }
        </div>
    )
}

export function ReminderItem({
    contextForm,
    onHandleDeleteReminder,
    index,
    configBase = 'reminders',
    hiddenUpdateMessageButton = false
}) {
    const [openEditReminder, toggleEditReminder] = useToggle<boolean>(false)
    const values = contextForm.watch(`${configBase}.${index}`)

    const onChangeMessage = (newValues: SchemaEditReminderType) => {
        contextForm.setValue(`${configBase}.${index}`, { ...values, ...newValues })
    }

    const onHandleChangeUnit = (unit: string) => {
        contextForm.setValue(`${configBase}.${index}.unitAndNumber`, { number: 1, unit })
    }

    const onHandleChangeReminderValue = (value: string) => {
        let formatValue = Number(value.replace(/[^\d+]/g, ''));
        let unit = values.unitAndNumber.unit;

        if (unit === 'minutos' && formatValue > 60) {
            formatValue = 1;
        } else if (unit === 'hours' && formatValue > 24) {
            formatValue = 1;
            unit = 'days'
        } else if (unit === 'days' && formatValue > 10) {
            formatValue = 10;
        }

        contextForm.setValue(`${configBase}.${index}.unitAndNumber.number`, formatValue);
    }

    return (
        <div className='w-full group'>
            <EditReminderModal
                onOpenChange={toggleEditReminder}
                onSaveChange={onChangeMessage}
                open={!!openEditReminder}
                defaultValue={values}
            />
            <FormField
                control={contextForm.control}
                name={`${configBase}.${index}`}
                render={({ field }) => {
                    const value = field.value

                    return (
                        <div className="flex items-center gap-3">
                            <div className="flex flex-grow items-center gap-4">
                                <span className="text-gray-700 shrink-0">
                                    {
                                        hiddenUpdateMessageButton
                                            ? <AlarmClock size={18} />
                                            : (value.medium == 'email')
                                                ? <Mail size={18} />
                                                : <MessageSquare size={18} />
                                    }
                                </span>
                                <div className="flex items-center gap-1">
                                    <DurationPicker
                                        number={value.unitAndNumber.number}
                                        onChangeNumber={(newNumber) => onHandleChangeReminderValue(newNumber)}
                                        onChangeUnit={(newUnit) => onHandleChangeUnit(newUnit)}
                                        unit={value.unitAndNumber.unit}
                                        options={unitOptions}
                                    />
                                    {
                                        !hiddenUpdateMessageButton && (
                                            <SimpleTooltip content="Editar">
                                                <Button
                                                    size={'icon'}
                                                    variant="outline"
                                                    className='relative'
                                                    onClick={toggleEditReminder}
                                                    type='button'
                                                >
                                                    {
                                                        !!(value.messageContent.message) && (
                                                            <span className={'w-2 h-2 absolute bg-primary rounded-full -top-1 -right-1'}></span>
                                                        )
                                                    }
                                                    <PencilLine size={17} />
                                                </Button>
                                            </SimpleTooltip>
                                        )
                                    }
                                </div>
                            </div>
                            <p className="text-gray-700 md:block hidden font-medium text-[13px] ml-2">
                                antes de la reunión
                            </p>
                            <SimpleTooltip content={'Eliminar recordatorio'}>
                                <Button
                                    type='button'
                                    size={'icon'}
                                    variant={'ghost'}
                                    onClick={() => onHandleDeleteReminder(index)}
                                >
                                    <Trash2 size={17} className='text-red-500' />
                                </Button>
                            </SimpleTooltip>
                        </div>
                    )
                }}
            />
        </div>
    )
}


interface DurationPickerProps {
    number: number,
    unit: string,
    onChangeUnit: (value: string) => void,
    onChangeNumber: (value: string) => void,
    className?: string
    options: {
        label: string,
        singular: string,
        code: string
    }[]
}

export function DurationPicker({
    number,
    unit,
    onChangeUnit,
    onChangeNumber,
    options,
    className
}: DurationPickerProps) {
    return (
        <div className={cn('flex items-center h-[35px]', className)}>
            <Input
                onChange={(e) => onChangeNumber(e.target.value)}
                value={String(number)}
                className={'w-[70px] h-full rounded-r-none'}
            />
            <Select
                value={unit}
                onValueChange={onChangeUnit}
            >
                <SelectTrigger className="w-[100px] h-full rounded-l-none">
                    <SelectValue defaultValue={'minutes'} />
                </SelectTrigger>
                <SelectContent>
                    {
                        options.map(({ code, label, singular }) => (
                            <SelectItem value={code} key={code}>
                                {
                                    (number == 1)
                                        ? singular
                                        : label
                                }
                            </SelectItem>
                        ))
                    }
                </SelectContent>
            </Select>
        </div>
    )
}