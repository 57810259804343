import { getFileType } from "@/components/crm/uploadMultimedia/utils/sendMultimediaWithPersonalWhatsApp";
import { z } from "zod";

export const templateSchema = z.object({
    channel: z.enum(['email', 'message']),
    title: z.string().min(1, { message: 'El titulo es requerido.' }),
    message: z.string().optional(),
    subject: z.string().optional(),
    contentType: z.enum(['message-text', 'voice-note']),
    files: z
        .array(
            z
                .object({
                    path: z.string().optional(),
                    type: z.string().optional(),
                    name: z.string().optional(),
                    size: z.number().optional(),
                    duration: z.number().optional(),
                    isUpload: z.boolean().optional(),
                })
                .optional()
        )
        .optional(),
}).refine(
    (data) => data.channel !== 'email' || (data.subject && data.subject.length > 0),
    {
        message: 'El asunto es requerido.',
        path: ['subject'],
    }
).refine(
    (data) =>
        (data.channel === 'message' && data.contentType !== 'voice-note'
            ? data.message && data.message.length > 0
            : true) &&
        (data.channel === 'email' ? data.message && data.message.length > 0 : true),
    {
        message: 'El mensaje es requerido.',
        path: ['message'],
    }
).refine(
    (data) =>
        !(data.channel === 'message' && data.contentType === 'voice-note') ||
        (data.files && data.files.length > 0),
    {
        message: 'Se requiere de una nota de audio.',
        path: ['files'],
    }
);

export type TemplateFormValues = z.infer<typeof templateSchema>

export const defaultTemplateValue: TemplateFormValues = {
    contentType: 'message-text',
    subject: undefined,
    channel: 'email',
    title: '',
    message: '',
    files: [],

}

export const formatTemplateFile = (file, format: 'normal' | 'upload-server' = 'upload-server') => {
    if (format == 'upload-server') {
        const { name, path, size, type, duration } = file
        return {
            url: path,
            mimetype: type || 'application/octet-stream',
            fileType: getFileType(type),
            fileLength: size,
            fileName: name,
            duration
        }
    }

    const { url, mimetype, fileLength, fileName, fileType, duration } = file;

    return {
        name: fileName,
        size: fileLength,
        type: mimetype,
        mediaType: fileType || getFileType(mimetype || ''),
        path: url,
        duration
    }
}
