import userNotification from "@/actions/userNotification";
import { UserBannerNotification, UserRightBottomNotification } from "@/interfaces/Interface";
import { setUnviewedNotifications } from "@/redux/slices/global";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useGetUnopenedNotifications() {
    const [onceFetchingData, setOnceFetchingData] = useState<boolean>(false);

    const unviewedNotifications = useSelector((state: any) => state.global?.unviewedNotifications || [])
    const dispatch = useDispatch();

    const { data, isLoading } = useQuery({
        queryFn: () => userNotification.getMyNotifications({ unopened: 1, limit: 50 }),
        queryKey: ['unopened-notifications-list'],
        enabled: (unviewedNotifications.length == 0 && !onceFetchingData)
    });

    useEffect(() => {
        if (!data) return;
        if (onceFetchingData) return;
        const { notifications = [] } = data;
        dispatch(setUnviewedNotifications(notifications));
        setOnceFetchingData(true);
    }, [data]);

    const handleDeleteNotificationFromLocal = (index: number) => {
        let notificationsList = [...unviewedNotifications];
        notificationsList.splice(index, 1);
        dispatch(setUnviewedNotifications(notificationsList));
    };

    const onChangeNotificationsList = (notifications: any) => {
        dispatch(setUnviewedNotifications(notifications));
    }

    return {
        isLoading,
        unviewedNotifications,
        handleDeleteNotificationFromLocal,
        onChangeNotificationsList
    }
}

const bannerNotification: UserBannerNotification = {
    "_id": "6749b2de86663289d24c4625",
    "setting": {
        "_id": "6749b29686663289d24c33c9",
        "metadata": {
            "title": "Nuevo video en la academia",
            "subTitle": "Descubre como crear un canal de whatsapp",
            "thumbnailURL": "https://i.pinimg.com/474x/44/d0/86/44d086542292a4a00938d194bfec92ad.jpg",
            "multimediaType": "video"
        },
        "action": {
            "redirectURL": "https://www.youtube.com/embed/P6vwMrA2q6U?si=fK3jF21cZw6amVCJ",
            "redirectType": "iframe"
        },
        "enabled": true,
        "type": "banner",
        "createdAt": "2024-11-29T12:24:54.963Z",
        "updatedAt": "2024-11-29T12:24:54.963Z"
    },
    "viewed": false,
    "createdAt": "2024-11-29T12:26:06.560Z"
}

const rightBottomNotification: UserRightBottomNotification = {
    "_id": "6749b2de86663289d24c4625",
    "setting": {
        "_id": "6749b29686663289d24c33c9",
        "metadata": {
            "title": "Como configurar una solicitud para llevar los procesos de tu empresa",
            "description": "Como crear una solicitud y no morir en el intento, es importante entender como funciona y cuales son sus poderes, tambien poder visualizar las ventajas, si esta bueno lo que se hace, si es importante.",
            "thumbnailURL": "https://i.pinimg.com/474x/44/d0/86/44d086542292a4a00938d194bfec92ad.jpg",
            "multimediaType": "video"
        },
        "action": {
            "redirectURL": "https://ventia-supportdesk.freshdesk.com/a/tickets/1531",
            "redirectType": "redirect"
        },
        "enabled": true,
        "type": "right-bottom-notification",
        "createdAt": "2024-11-29T12:24:54.963Z",
        "updatedAt": "2024-11-29T12:24:54.963Z"
    },
    "viewed": false,
    "createdAt": "2024-11-29T12:26:06.560Z"
}