import { Skeleton } from "@/components/ui/skeleton";

export default function TemplateItemSkeleton() {
    return (
        <div className="w-full p-3 border relative bg-white px-4 duration-100">
            <Skeleton className="w-12 h-4 absolute top-2 right-2 bg-gray-200 rounded-full " />
            <Skeleton className="w-full h-2.5 mb-1.5 bg-gray-200 rounded-md max-w-[200px]" />
            <Skeleton className="w-full h-2.5 bg-gray-200 rounded-md max-w-[170px]" />
        </div>
    )
}

