import { isAuth } from '@/actions/auth'
import { ROLES, isTheRolEnabled } from '@/helpers/featureFlagHelpers'
import { Agent, IUserByRol } from '@/interfaces/Interface'
import { ArrowRight } from 'lucide-react'
import Link from 'next/link'
import { CardTitle } from '../ui/card'
import CustomHoverCard from '../ui/custom-hover-card'
import { InteractionProfileUser } from './Bubble/interaction-profile-user'
import { cn } from '@/lib/utils'

const reactionsOfMessage = ['👏', '💪', '🙌', '⭐']

interface CardHoverReactionsProps {
    trigger: JSX.Element
    profile?: string | null
    isBot?: boolean
    disabledReaction?: boolean
    classNameText?: string
    classNameContentUser?: string
    isAgent: boolean
    agent?: Agent | null | IUserByRol
}

export default function CardHoverReactions({
    trigger,
    profile,
    isAgent,
    classNameText,
    isBot,
    disabledReaction = true,
    classNameContentUser,
    agent
}: CardHoverReactionsProps) {
    const user = isAuth()
    const { lastname, name, photo, _id: userId } = agent || {}
    const fullName = (name || lastname) ? `${name || ''} ${lastname || ''}` : `${user?.name || ''} ${user?.lastname || ''}`

    return isAgent && !isBot ? <CustomHoverCard
        className="bg-white border-none p-0 shadow-none w-auto max-w-[300px] relative right-6"
        trigger={<div>{trigger}</div>}
    >
        <div className="flex flex-col bg-white gap-1">
            <div className={cn("rounded-md border flex items-center bg-popover p-4 text-popover-foreground shadow-md outline-none gap-3 px-6", classNameContentUser)}>
                <InteractionProfileUser
                    className="shadow-none w-[43px] h-[43px] text-[17px]"
                    classNameImage="w-[43px] h-[43px] border-primary border-[2.5px]"
                    isAgent={true}
                    profile={profile}
                />
                <div className="flex flex-col overflow-hidden">
                    <CardTitle className={cn("text-[16px] truncate", classNameText)} title={fullName}>
                        {fullName}
                    </CardTitle>
                    {
                        isTheRolEnabled([ROLES.COMERCIAL, ROLES.SUPER]) && <Link href={`/?view-user=${userId}`} onClick={(e) => e.stopPropagation()} className="font-medium group text-[12px] flex items-center gap-1 text-[#606775]">
                            Ver perfil
                            <ArrowRight size={15} className="group-hover:translate-x-1 mt-[2px] duration-75" />
                        </Link>
                    }
                </div>
            </div>
            {/* {
                disabledReaction &&  <div className="rounded-b-md border bg-popover items-center flex justify-center p-3 text-popover-foreground shadow-md outline-none flex-col gap-1 px-6">
                <CardTitle className="text-[15px] flex items-center text-center text-gray-500">
                    <CornerDownLeft className="w-[17px] mr-2 mt-[3px] h-[17px]" />
                    Reaccionar al mensaje
                </CardTitle>
                <div className="flex items-center gap-3.5">
                    {
                        reactionsOfMessage.map(reaction => (
                            <motion.div
                                initial={{ scale: 0.8 }}
                                animate={{ scale: 1 }}
                                whileTap={{ scale: 0.8 }}
                                transition={{
                                    duration: 0.2,
                                    type: 'spring',
                                    bounce: 0.2
                                }}
                                whileHover={{ scale: 1.2, y: -10 }}
                            >
                                <motion.div
                                    whileTap={{ scale: 0.8 }}
                                >
                                    <button className="text-[27px]" key={reaction}>{reaction}</button>
                                </motion.div>
                            </motion.div>
                        ))
                    }
                </div>
            </div> 
            } */}
        </div>
    </CustomHoverCard> : trigger
}
