import { isAuth } from "@/actions/auth";
import { formatTemplateFile } from "@/components/settings/templates/ModalTemplate/helpers/constants";
import { searchRegex } from "@/helpers/regex";
import { replaceObjectValues } from "@/helpers/replaceObjectValues";
import { ChannelTemplate, FastCompanyTemplate, FastUserTemplate } from "@/interfaces/Interface";
import { setMatchTemplate, setUploadMultimediaType } from "@/redux/slices/crm";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultValueForEditor } from "../../uploadMultimedia/constants";
import { useChatInputContext } from "../Context";
import { SelecteableTemplate } from "./TemplateItem";
import TemplateSelect from "./TemplateSelect";

interface NewTemplateSelectProps {
  type?: 'user-template' | 'send-fast-message',
  forceChannelTemplates?: ChannelTemplate[]
  onBackChange?: () => void
  loading?: boolean,
  sendMessage?: (message: ({
    type: 'text',
    text: string,
    template?: ChannelTemplate
  } | {
    type: 'multimedia',
    file: {
      name: string,
      url: string,
      mimetype: string,
      type: string
    }
  })) => void,
  channel?: {
    id: string,
    name: string,
    fastTemplates?: {
      title: string,
      message: string,
      files: {
        url: string,
        type: string
      }[]
    }[]
  }
}

const FancyTemplateSelect = ({
  forceChannelTemplates,
  type: selectionType,
  onBackChange,
  sendMessage,
  channel,
  loading = false
}: NewTemplateSelectProps) => {
  const [searchText, setSearchText] = useState<string>("");

  const prospect = useSelector((state: any) => state.crm.contact);
  const inputSearch: string = (useSelector((state: any) => state.crm.searchTemplate) || '') as string
  const companyTemplates: FastCompanyTemplate[] = useSelector((state: any) => state.crm.companyTemplates) as FastCompanyTemplate[]

  const dispatch = useDispatch();
  const { setTextValue } = useChatInputContext()

  const filteredCompanyTemplates = useMemo(() => {
    return companyTemplates.filter((template) => {
      const templateChannels = template.channels || [];
      return template.type == 'message' && (templateChannels.length == 0 || templateChannels.includes(channel?.id || ''))
    }).map((template) => ({ ...template, entityType: 'company' }))
  }, [companyTemplates])

  const userTemplates = useMemo(() => {
    const templates: FastUserTemplate[] = _.get(isAuth(), 'templates', []);
    return templates.filter((template) => template.channel === 'message').map((template) => ({ ...template, entityType: 'personal' }))
  }, []);

  const forceChannelsTemplateList = useMemo(() => {
    return (forceChannelTemplates || []).map((template) => {
      return {
        text: template.message,
        entityType: 'channel',
        title: template.name,
        type: 'message-text',
        id: template._id,
      } as SelecteableTemplate
    })
  }, [forceChannelTemplates])

  const templates: SelecteableTemplate[] = useMemo(() => {
    if (forceChannelTemplates) return forceChannelsTemplateList;

    const list: SelecteableTemplate[] = [...userTemplates, ...filteredCompanyTemplates].map((template) => {
      return {
        type: 'contentType' in template ? (template.contentType || 'message-text') : 'message-text',
        title: template?.title || template?.name,
        entityType: template.entityType,
        text: template.message || '',
        files: template.files,
      }
    }) as SelecteableTemplate[];

    (channel?.fastTemplates || []).map((template) => {
      list.push({
        title: template.title,
        text: template.message,
        files: template.files,
      })
    });

    return list;
  }, [filteredCompanyTemplates, userTemplates, forceChannelTemplates, selectionType, searchText])

  const displayedTemplates = useMemo(() => {
    if (!searchText) return [];
    const allTemplates = [...(filteredCompanyTemplates || []), ...userTemplates];

    const filteredTemplates: any[] = allTemplates.filter((template) => {
      const regex = searchRegex(searchText);
      return regex.test(template.name || ('title' in template ? template.title : '')) || regex.test(template?.message || '')
    });

    return filteredTemplates;
  }, [forceChannelTemplates, userTemplates, searchText, inputSearch])

  const replaceTemplateMessage = (message: string) => {
    const user = isAuth();

    return replaceObjectValues(message, {
      group: user.group || {},
      contact: prospect,
      user: user
    })
  };

  const onSelectTemplate = (template: SelecteableTemplate) => {
    const { files = [], id: templateId, text, type } = template;

    if (files.length > 0) {
      if (type == 'voice-note') {
        const audio = files[0];

        if (sendMessage) {
          sendMessage({
            type: 'multimedia',
            file: {
              name: audio.fileName || 'voice-note.mp3',
              mimetype: audio.mimetype || 'audio/mp4',
              url: audio.url,
              type: 'audio'
            }
          })
        }
        
        onBackChange && onBackChange();
        return;
      }

      const filesFormated = files.map(file => ({
        ...defaultValueForEditor,
        ...formatTemplateFile(file, 'normal'),
        caption: text
      }));

      return dispatch(setUploadMultimediaType({ multimedia: filesFormated }))
    }

    if (selectionType == 'send-fast-message') {
      if (sendMessage) {
        // Para los casos como Facebook, Instagram y WhatsApp en donde su rango de conversación se acabó.
        const channelTemplate = (forceChannelTemplates || []).find((template) => template._id == templateId);

        sendMessage({
          type: 'text',
          text: text || '',
          template: channelTemplate
        })
      }

      onBackChange && onBackChange();
      return;
    }

    setTextValue(replaceTemplateMessage(text || ''))
    onBackChange && onBackChange()
  }

  useEffect(() => {
    if (inputSearch?.length > 0 && inputSearch.startsWith('/')) {
      const cleanText = inputSearch.substring(1);
      setSearchText(cleanText)

      let firstMatchedTemplate = displayedTemplates[0] ? { ...displayedTemplates[0] } : undefined;

      if (firstMatchedTemplate && firstMatchedTemplate.message) {
        firstMatchedTemplate.message = replaceTemplateMessage(firstMatchedTemplate.message);
      }

      dispatch(setMatchTemplate(firstMatchedTemplate))
    }
  }, [inputSearch, displayedTemplates])

  return (
    <TemplateSelect
      onBackClick={onBackChange && onBackChange}
      hideButtonBack={selectionType !== 'user-template'}
      onSelectTemplate={onSelectTemplate}
      title="Tus respuestas rápidas"
      templates={templates}
      search={searchText}
      loading={loading}
    />
  );
};

export default FancyTemplateSelect;