import AlertErrorDescription from "@/components/AlertErrorDescription"
import { TitleQuestion } from "@/components/QuestionTitle"
import { CardDescription, CardTitle } from "@/components/ui/card"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Form } from "@/components/ui/form"
import { optionsForRecorder } from "@/constants/modalReminder"
import useLoadingAndError from "@/hooks/useLoadingAndError"
import { ModalReminderProps } from "@/interfaces/Interface"
import { cn } from "@/lib/utils"
import { handleSetLocalStatus, handleUpdate } from "@/redux/slices/crm"
import { handleLocalInteractions } from '@/redux/slices/thunks/crm'
import { zodResolver } from "@hookform/resolvers/zod"
import contactService from 'actions/crm/newContact'
import dayjs from "dayjs"
import { AlarmClock, ArrowLeft, CalendarDays, Loader2, X } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { createMassiveReminder } from "../../../../actions/crm/contact"
import PrimaryButton from "./PrimaryButton"
import StepOne from "./StepOne"
import StepTwo, { TYPES_OF_SELECTION } from "./StepTwo"
import { TReminderData, defaultValues, formSchema } from "./constant"

dayjs.locale("es")

export default function ModalReminder({
  open,
  onCLose,
  customReminder,
  className = "",
  handleSuccess,
  dateOptions,
  defaultValues: defualtFormValue
}: ModalReminderProps) {
  const { isError, isLoading, setError, setIsLoading } = useLoadingAndError()
  const dataReminder = useSelector((state: any) => state.crm.dataReminder)
  const [stepSelected, setStepSelected] = useState<0 | 1>(() => dataReminder ? 1 : 0)

  const contextForm = useForm<TReminderData>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const socketId = useSelector((state: any) => state.crm.socketId)
  const dispatch = useDispatch()
  const actionLabel = contextForm.watch("action.label")

  useEffect(() => {
    contextForm.reset(dataReminder ?? defaultValues)
    if (!dataReminder && !customReminder) {
      setStepSelected(0)
    }

    if (customReminder) {
      setStepSelected(customReminder.step)
      const findType = optionsForRecorder.find(
        (type) => type.actionCode === customReminder.type
      )

      if (findType) {
        const { label, actionCode } = findType

        contextForm.setValue("action", {
          code: actionCode,
          label
        })
      }
    }

    if (defualtFormValue) {
      setStepSelected(defualtFormValue.step);
      contextForm.setValue('reminderDate.date', new Date(defualtFormValue.date));
      contextForm.setValue('reminderDate.type', TYPES_OF_SELECTION.MANUAL);
    };

    setError("")
  }, [open, dataReminder, customReminder, defualtFormValue])
  const localId = useSelector((state: any) => state.crm.localInteractionId)

  const handleSubmit = useCallback(
    async (values: TReminderData) => {
      const valuesToSend: any = { ...values }
      valuesToSend.action = valuesToSend?.action?.code ?? ""
      const date = valuesToSend.reminderDate.date

      if (!date)
        return contextForm.setError("reminderDate", {
          message: "Por favor, elija una hora para configurar el recordatorio",
        })

      valuesToSend.reminderDate = new Date(date).toISOString()

      const contactsList = [open].flat()
      const isSingleContact = contactsList.length == 1

      if (isSingleContact) {
        const { action, comment, reminderDate } = valuesToSend || {}

        dispatch(handleLocalInteractions({
          values: {
            reminderDate,
            actionType: action,
            comment
          },
          contact: contactsList[0],
          type: 'reminder'
        }))

        onCLose && onCLose()
      }

      setIsLoading(true)

      try {
        const promise = isSingleContact
          ? contactService.createReminder({
            id: contactsList[0] as string, data: valuesToSend,
            localId: localId + 1, socketIgnore: socketId
          })
          : createMassiveReminder({ data: valuesToSend, contacts: (contactsList as string[]) });

        const result: any = await promise

        if ('events' in result) {
          for (let event of result.events) dispatch(handleUpdate(event))
        }

        handleSuccess && handleSuccess()
        onCLose && onCLose()
      } catch {
        if (isSingleContact) dispatch(handleSetLocalStatus({ localId }))
        setError("Ocurrió un error al guardar el recordatorio")
      } finally {
        setIsLoading(false)
      }
    }, [open])

  const titles = [
    {
      icon: <AlarmClock />,
      title: "Programar recordatorio",
    },
    {
      icon: <CalendarDays />,
      title: "Agenda el recordatorio",
      actionLabel,
    }
  ]

  return (
    <Dialog open={!!open} onOpenChange={() => {
      onCLose()
    }}>
      <DialogContent
        classNameCloseBtn={`${customReminder ?
          'hidden' : ''}`}
        className={cn(
          `gap-0  h-auto w-full sm:max-w-[580px] p-0 max-h-screen overflow-hidden `,
          className
        )}
      >
        <div className="flex sm:max-w-[600px] max-w-[100vw] flex-grow flex-col">
          {customReminder && (
            <div
              className="w-full flex items-center justify-between relative shrink-0 h-[38px] space-x-2 md:px-[26px] px-3.5 "
              style={{
                background: customReminder.bgColor,
              }}
            >
              <div className="flex items-center gap-2">
                {customReminder.icon}
                <CardTitle
                  className={`text-[15px] my-0`}
                  style={{
                    color: customReminder.textColor,
                  }}
                >
                  {customReminder.label}
                </CardTitle>
              </div>
              {
                customReminder && <button className="text-red-500 absolute right-5" onClick={onCLose}>
                  <X className="h-4 w-4" />
                </button>
              }
            </div>
          )}
          <div
            className={`sm:p-6 overflow-hidden flex flex-col sm:py-6 ${customReminder ? "sm:pt-3 px-3.5  pt-4" : "py-6"
              } `}
          >
            <DialogHeader
              className={`text-start max-w-full mt-3 relative overflow-hidden h-auto ${customReminder ? "mb-[17px]" : "mb-4"} px-3.5 `}
            >
              {customReminder ? (
                customReminder.title
              ) : (
                <DialogTitle className="flex line-clamp-2 text-[17px] items-center [&>svg]:w-[18px] [&>svg]:h-[18px] gap-[7px]" style={{ overflowWrap: 'break-word' }}>
                  {titles[stepSelected].icon}
                  <span>
                    {titles[stepSelected].title}
                    <span className="font-bold">
                      {stepSelected === 1 && !!actionLabel ? `: ${actionLabel}` : ''}
                    </span>
                  </span>
                </DialogTitle>
              )}
              {!customReminder &&
                stepSelected === 1 &&
                contextForm.getValues("comment") && (
                  <CardDescription className="text-[14px] text-neutral-600 line-clamp-4 pr-8 " style={{ overflowWrap: 'break-word' }}>
                    <span className="text-neutral-900 font-medium">
                      Comentario:
                    </span>
                    {contextForm.getValues("comment")}
                  </CardDescription>
                )}
            </DialogHeader>
            <AlertErrorDescription description={isError} isActive={isError} className="px-3.5" />
            {
              customReminder && <TitleQuestion className="px-3.5">
                Selecciona un horario
              </TitleQuestion>
            }
            <Form {...contextForm}>
              <form
                className="px-3.5"
                onSubmit={contextForm.handleSubmit(handleSubmit)}
                id="formAddNewReminder"
              >
                <div className="min-h-[50vh] py-2 ">
                  {(customReminder && customReminder?.step === 0) || !customReminder && stepSelected == 0 ? (
                    <StepOne contextForm={contextForm} />
                  ) : (
                    <StepTwo
                      contextForm={contextForm}
                      defaultValues={dateOptions}
                      handleCreateReminder={(values) => handleSubmit(values)}
                      disabledQuestion={customReminder && customReminder.step > 0}
                      isLoading={isLoading}
                    />
                  )}
                </div>
              </form>
            </Form>
            <DialogFooter className="w-full px-3.5">
              <div className="flex items-center w-full justify-between">
                {stepSelected === 0 ? (
                  <>
                    <PrimaryButton
                      className="flex-1"
                      onAction={() => setStepSelected(1)}
                    >
                      <CalendarDays />
                      Agendar recordatorio
                    </PrimaryButton>
                  </>
                ) : (
                  <>
                    {stepSelected > 0 && (
                      <PrimaryButton
                        onAction={() =>
                          customReminder
                            ? onCLose()
                            : setStepSelected(0)
                        }
                      >
                        {!customReminder && <ArrowLeft />}
                        {customReminder && customReminder.step > 0
                          ? "Cancelar"
                          : "  Volver"}
                      </PrimaryButton>
                    )}
                    <PrimaryButton
                      type="submit"
                      disabled={isLoading}
                      form="formAddNewReminder"
                    >
                      {isLoading ? (
                        <Loader2 className="h-4 w-4 animate-spin" />
                      ) : (
                        "Confirmar"
                      )}
                    </PrimaryButton>
                  </>
                )}
              </div>
            </DialogFooter>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}