import {
    Command,
    CommandEmpty,
    CommandInputNotIcon,
    CommandItem,
    CommandList
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { Check } from "lucide-react";
import { ReactNode } from "react";
import { Input } from "./input";
import { Skeleton } from "./skeleton";

interface PupoverWithSelectItemProps {
    Trigger: JSX.Element | null | string | undefined
    placeholder?: string,
    emptyMessage?: string,
    PopoverContentElement?: ReactNode
    ElementTop?: JSX.Element | null
    searchActive?: boolean
    triggerClassName?: string
    classNameContent?: string,
    isLoading?: boolean
    props?: Record<string, any>,
    ElementBottom?: JSX.Element | null
    loading?: boolean
    classNameInputDiv?: string,
    search?: 'local' | 'server'
    classNameInput?: string,
    handleSearch?: (value: string) => void
    searchValue?: string,
    classNameList?: string
    align?: 'end' | 'start' | 'center',
    CommandListContent: JSX.Element
}

export default function PupoverWithSelectItem({
    Trigger,
    placeholder = '',
    search = 'local',
    emptyMessage = '',
    classNameContent = '',
    handleSearch,
    classNameList,
    classNameInput,
    searchValue,
    ElementTop,
    ElementBottom,
    loading,
    triggerClassName,
    props = {},
    PopoverContentElement,
    align,
    searchActive = true,
    classNameInputDiv,
    CommandListContent
}: PupoverWithSelectItemProps) {
    return <Popover {...props} >
        <PopoverTrigger onClick={(e) => e.stopPropagation()} className={cn(triggerClassName)}>
            {Trigger}
        </PopoverTrigger>
        <PopoverContent align={align} className={cn("p-0 z-50 max-w-[210px]", classNameContent)}>
            {
                CommandListContent
                    ? <Command>
                        {ElementTop}
                        <CommandList
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            className={cn("h-max px-2  overflow-y-auto max-h-[250px] relative modifyScroll  flex flex-col", classNameList)}>
                            {
                                !loading && searchActive && handleSearch && <>
                                    {
                                        <div className="w-full sticky bg-white z-10 top-0">
                                            {
                                                search === 'local'
                                                    ? <>
                                                        <CommandInputNotIcon
                                                            placeholder={placeholder}
                                                            onValueChange={(val) => handleSearch(val)}
                                                            value={searchValue}
                                                            className={cn("h-[35px] w-full bg-neutral-100 rounded-[3px] px-2", classNameInput)}
                                                            classNameDiv={cn("border-b-0 py-3 px-0 pb-1", classNameInputDiv)}
                                                        />
                                                    </>
                                                    : <Input className='border-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0' placeholder="Busca una dirección..." />
                                            }
                                        </div>
                                    }
                                    {
                                        loading && <CommandEmpty>{emptyMessage}</CommandEmpty>
                                    }
                                </>
                            }
                            {
                                loading
                                    ? <LoadingCommandItem />
                                    : CommandListContent && CommandListContent
                            }
                        </CommandList>
                        {ElementBottom}
                    </Command>
                    : PopoverContentElement
            }
        </PopoverContent>
    </Popover>
}

export function CustomCommandItem({
    value,
    onChange,
    label,
    itemLeft,
    className = '',
    isChecked,
    rightElement = null
}: {
    value?: string,
    onChange?: () => void,
    label?: string,
    itemLeft?: JSX.Element,
    className?: string,
    isChecked?: boolean,
    rightElement?: ReactNode
}) {
    return <CommandItem
        value={value}
        onSelect={() => onChange && onChange()}
        className={cn("group", className)}
    >
        <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-2">
                {itemLeft && itemLeft}
                <span className={`truncate flex items-center text-[13px] font-medium`}>{label}</span>
            </div>
            <div className="flex items-center gap-1">
                {
                    isChecked && <span>
                        <Check size={15} />
                    </span>
                }
                {rightElement}
            </div>
        </div>
    </CommandItem>
}


export function LoadingCommandItem({ countItem = 10 }) {
    return <div className="flex flex-col gap-2">
        {
            new Array(countItem).fill(null).map((_, inx) => <Skeleton className="w-full h-[15px]  rounded-[2px]" />)
        }
    </div>
}
