import { Badge } from "@/components/ui/badge";
import Chip, { TailwindColor } from "@/components/ui/chip";
import { motion } from 'framer-motion';
import { AudioLines, FileText } from "lucide-react";
import React from "react";

type EntityType = 'personal' | 'channel' | 'company';

export interface SelecteableTemplate {
    type?: 'voice-note' | 'message-text',
    entityType?: EntityType,
    id?: string | number,
    title: string,
    text?: string,
    files?: {
        url: string,
        mimetype?: string,
        fileType?: 'audio' | 'image' | 'video' | 'file',
        fileName?: string,
        fileLength?: number
        duration?: number
    }[]
}

interface TemplateItemProps {
    template: SelecteableTemplate
    onSelect?: () => void,
}

const getEntityNames = (type: EntityType) => {
    const names: Record<EntityType, { color: TailwindColor, label: string }> = {
        'channel': {
            color: 'pink',
            label: 'Plantilla del canal'
        },
        'company': {
            color: 'amber',
            label: 'Plantilla de la empresa'
        },
        'personal': {
            color: 'purple',
            label: 'Tú plantilla'
        }
    }

    return names[type];
}

function TemplateItem({
    template,
    onSelect,
}: TemplateItemProps) {
    const files = template?.files || [];
    const isVoiceAudio = template.type == 'voice-note';
    const { color, label } = template.entityType ? getEntityNames(template.entityType) : {}

    const GetDescription = () => {
        if (isVoiceAudio) return (
            <>
                <AudioLines size={17} className="mr-1.5 inline" />
                Nota de audio
            </>
        )

        return template.text;
    }

    return (
        <motion.div
            whileTap={{ scale: 0.996 }}
            className="w-full py-2 border hover:bg-gray-100/60 relative cursor-pointer bg-white px-4 duration-100 rounded-md"
            onClick={(evt) => {
                evt.stopPropagation();
                if (onSelect) onSelect();
            }}
        >
            {
                (label && color) && (
                    <Chip
                        className="absolute top-2 right-2 text-[10px]"
                        color={color}
                    >
                        {label}
                    </Chip>
                )
            }
            <div className="flex items-center gap-2">
                <h5 className="text-[16px] font-medium select-none text-gray-700">
                    {template.title}
                </h5>
                {
                    (files.length > 0 && !isVoiceAudio) && (
                        <Badge variant={'outline'} className="text-[12px] px-1.5" >
                            <FileText size={14} className="mr-1" />
                            {files.length}{files.length === 1 ? ' Archivo' : ' Archivos'}
                        </Badge>
                    )
                }
            </div>
            <p className="text-[14.5px] text-gray-500 select-none">
                {GetDescription()}
            </p>
        </motion.div>
    )
}

export default React.memo(TemplateItem)