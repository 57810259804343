import { getCompany, isAuth } from '@/actions/auth'
import { Separator } from '@/components/ui/separator'
import { toast } from '@/components/ui/use-toast'
import _ from 'lodash'
import { Layers } from 'lucide-react'
import React, { Suspense, useMemo, useState } from 'react'
import { IShortcut } from '../BottomActions/SelectAction'
import DetailAccordionItem from './detail-accordion-item'
import { DialogSeeShortcut } from './DialogSeeShortcut'
import { replaceObjectValues } from '@/helpers/replaceObjectValues'
const IconByName = React.lazy(() => import('../../../components/company/shortcuts/RenderIconByName'))

export default function ShortcutsItem({ prospect }) {
    const [openDialog, setOpenDialog] = useState<null | string>(null)
    const groupShortcuts = useMemo(() => _.get(getCompany(), "shortcuts", []), [])
    const user = useMemo(() => isAuth(), [])

    const handleOnClickShortcut = (shortcut: IShortcut) => {
        const url = replaceObjectValues(shortcut.url, { contact: prospect, user: user, group: user.group });

        if (!url) {
            return toast({
                title: 'Error',
                description: 'La url no es valida, por favor revisa la configuración del acceso directo.',
                duration: 2000
            })
        }

        if (shortcut.redirectionType === "internal") return setOpenDialog(url);
        window.open(url, "_blank");
    }

    return groupShortcuts && groupShortcuts.length > 0
        ? <>
            <DialogSeeShortcut open={openDialog} onClose={() => setOpenDialog(null)} />
            <DetailAccordionItem label="Accesos Directos" value="value-1">
                <div className="mt-[2px]">
                    {groupShortcuts.map((shortcut: IShortcut, index) => (
                        <>
                            <button
                                key={index}
                                className="flex items-center gap-1.5 w-full text-start justify-start p-1.5 hover:bg-slate-100 rounded"
                                onClick={() => handleOnClickShortcut(shortcut)}
                            >
                                {shortcut.avatar?.type === 'link'
                                    ? (
                                        <img alt="Logo atajo" src={shortcut.avatar?.url} className="rounded-full w-7 h-7 border" />
                                    ) : shortcut.avatar?.type === 'icon'
                                        ? (
                                            <Suspense fallback={<span className="rounded-full h-6 w-6 animate-pulse bg-gray-100" />}>
                                                <IconByName name={shortcut.avatar.url} />
                                            </Suspense>
                                        ) : (
                                            <Layers size={20} />
                                        )}
                                <div className="flex flex-col">
                                    <span className="font-medium text-[13.5px] leading-[14.5px]" >{shortcut.title}</span>
                                    <p className="text-[10px] text-gray-600">
                                        {shortcut.redirectionType === 'internal' ? 'Redirección interna' : 'Redirección externa'}
                                    </p>
                                </div>
                            </button>
                            {(index !== groupShortcuts.length - 1) && <Separator key={index} className="my-[2px]" />}
                        </>
                    ))}
                </div >
            </DetailAccordionItem>
        </>
        : null
}
