import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { GenericMultipleCombobox } from "@/components/ui/generic-multiple-combobox";
import { Plus, XIcon } from "lucide-react";
import { FieldItemProps } from "./FieldItem";

interface MultiSelectFieldProps {
    value: string[],
    onChangeValue: (value: string[]) => void,
    options: FieldItemProps['options']
}

export default function MultiSelectField({
    onChangeValue,
    options = [],
    value,
}: MultiSelectFieldProps) {
    let valueToUse = Array.isArray(value) ? value : [];

    const handleDeleteOption = (code: string) => {
        onChangeValue(valueToUse.filter((op) => op !== code))
    }

    const filteredOptions = options.filter((op) => !valueToUse.includes(op.value))

    return <div className="flex items-center flex-wrap gap-1 gap-y-1.5">
        {
            valueToUse.map((option) => {
                const optionData = options.find((opt) => opt.value == option);

                return <Badge
                    variant={'outline'}
                    className="flex items-center pr-1 text-[12px] text-gray-700 py-[1px] gap-1"
                    key={option}
                >
                    <span>{optionData?.label || option}</span>
                    <button
                        onClick={() => handleDeleteOption(option)}
                        className="w-[15px] h-[15px]"
                        type='button'
                    >
                        <XIcon size={13} />
                    </button>
                </Badge>
            })
        }
        {
            filteredOptions.length > 0 && (
                <GenericMultipleCombobox
                    value={valueToUse}
                    onChange={onChangeValue}
                    hiddenCheckbox={true}
                    trigger={
                        <Button
                            type="button"
                            variant={'outline'}
                            className="px-2 h-6 py-[1px] rounded-full flex hover:bg-gray-100 items-center gap-1 text-gray-700 text-[12px]"
                        >
                            <Plus size={13} />
                            Agregar
                        </Button>
                    }
                    options={filteredOptions.map((opt) => {
                        return {
                            value: opt.value,
                            label: opt.label,
                            searchableBy: [opt.label]
                        }
                    }) as any}
                />
            )
        }
    </div>
}
