import Chip from "@/components/ui/chip";
import DetailAccordionItem from "./detail-accordion-item";

const chipVariants = {
    'success': 'green',
    'error': 'red',
}

export default function StatsItem({ stats }: { stats: any[] }) {
    if (stats.length == 0) return null;

    const GetDataByType = (body: any) => {
        switch (body.type) {
            case 'text': {
                return <p className="text-[13px] text-gray-500">
                    {body?.value}
                </p>
            }
            case 'chip': {
                const { text, variant } = body || {};
                return <Chip
                    color={chipVariants[variant]}
                    withCircle={false}
                    className="text-[10px] py-[1px]"
                >
                    {text}
                </Chip>
            }
        }

        return null
    }

    return (
        <DetailAccordionItem label="Estadísticas" value="stats">
            <div className="py-2 mt-2">
                <ul className="flex flex-col gap-3">
                    {
                        stats.map(({ title, body }, index) => {
                            if (body.type == 'text' && !body.value) return null;

                            const item = GetDataByType(body);
                            if (!item) return null;

                            return <li key={index} className={`flex items-center gap-x-2 ${body.type == 'chip' ? 'gap-y-2' : ''} flex-wrap`}>
                                <span className="text-slate-800 text-[14px] flex items-center shrink-0 gap-1 font-medium">
                                    {title}
                                </span>
                                {item}
                            </li>
                        })
                    }
                </ul>
            </div>
        </DetailAccordionItem>
    )
}
