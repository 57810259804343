import { ContactService } from "@/actions/crm/contact-controller";
import ShakingInput from "@/components/ui/shaking-input";
import { useToggle } from "@/hooks/useToggle";
import { Contact } from "@/interfaces/crm/contact";
import validatePhoneNumber from "@/lib/validatePhoneNumber";
import { useEffect, useRef, useState } from "react";
import { states } from "../ContactDrawer/ContactPhoneInput";
import useGetIsBlockNumber from "../Prospect/libs/hooks/useGetBlockNumber";
import FieldTextValue from "./FieldTextValue";
import PhoneError from "./PhoneError";

interface PhonenumberFieldProps {
    value: string,
    placeholder?: string,
    onChangeValue: (value: string) => void
    defaultContactNumbers?: string[],
    phoneCode?: string
    contact: Contact,
    allPhones: string[]
}

export default function PhonenumberField({
    onChangeValue,
    placeholder,
    contact,
    value,
    phoneCode = 'UY',
    defaultContactNumbers = [],
    allPhones = []
}: PhonenumberFieldProps) {
    const [isValidatingPhonenumber, setIsValidatingPhonenumber] = useState<boolean>(false);
    const [editableMode, toggleEditableMode] = useToggle<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(value);
    const [phoneError, setPhoneError] = useState<any>(undefined);

    const inputRef = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const { handlePressBlockNumber } = useGetIsBlockNumber();

    const handleChangeDefaultValue = () => {
        if (inputValue == value) return;
        if (!phoneError && !isValidatingPhonenumber) onChangeValue(inputValue);
    };

    const validatePhone = (input: string) => {
        let phoneToValidate: string = input;
        let validatedPhonenumber = validatePhoneNumber(phoneToValidate.trim(), phoneCode);

        if (!validatedPhonenumber?.valid) {
            setIsValidatingPhonenumber(false)
            return setPhoneError({ error: `El teléfono no es un número valido.` })
        };

        phoneToValidate = String(validatedPhonenumber.number);

        if (phoneToValidate !== value && allPhones.includes(phoneToValidate)) {
            setIsValidatingPhonenumber(false)
            return setPhoneError({ error: `El número de teléfono se encuentra repetido.` })
        };

        setIsValidatingPhonenumber(true);

        ContactService.validateNumbers(phoneToValidate, contact._id, '')
            .then((res) => {
                if (!res.success && res?.error) {
                    const { type, blockReason, phoneNumber } = res || {};
                    setPhoneError(res);

                    if (type && type === 'blocknumber') {
                        handlePressBlockNumber({
                            externalBlockReason: blockReason,
                            externalPhoneNumber: phoneNumber
                        })
                    }
                } else {
                    setPhoneError(undefined);
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setIsValidatingPhonenumber(false))
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        validatePhone(value);
        setInputValue(value);
    };

    const getPhoneState = () => {
        const phoneValidate = validatePhoneNumber(inputValue ?? "", phoneCode);
        if (isValidatingPhonenumber) return 'loading';
        if (phoneError) return 'error';

        if (inputValue.trim() !== '' && !defaultContactNumbers.includes(String(phoneValidate?.number))) return 'success';
        return ''
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleChangeDefaultValue();
            toggleEditableMode(false);
        }
    };

    useEffect(() => {
        if (inputRef.current && editableMode) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(
                inputRef.current.value.length,
                inputRef.current.value.length
            );
        }
    }, [inputValue, editableMode]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                handleChangeDefaultValue();
                toggleEditableMode(false);
            }
        };

        if (editableMode) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [editableMode, toggleEditableMode, handleChangeDefaultValue]);

    useEffect(() => {
        if (editableMode) {
            setPhoneError(undefined);
            setInputValue(value);
        }
    }, [editableMode])

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const phoneState = getPhoneState();

    return (
        <div ref={wrapperRef} className="relative">
            {editableMode && (
                <div
                    className={`absolute flex flex-col bg-white top-0 bottom-0 my-auto left-[-10px] z-10`}
                    style={{ width: 'calc(100% + 9px)' }}
                >
                    <ShakingInput
                        classNameContent="flex items-center absolute top-0 bottom-0 my-auto z-[1]"
                        ref={inputRef}
                        type="text"
                        inputMode="email"
                        style={{ boxShadow: '0px 4px 15px rgba(0,0,0,0.08)' }}
                        className={`outline-none font-medium text-gray-700 placeholder:text-gray-700 bg-gray-50 text-[13px] py-2 px-3 rounded-lg border h-[34px] ${phoneError ? 'border-red-400 text-red-500' : ''} ${phoneState ? 'pr-7' : ''}`}
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        shakeDuration={400}
                        shake={!!phoneError}
                        onKeyDown={handleKeyDown}
                        downElement={<>
                            <div className={'absolute right-[10px] z-10'}>
                                {
                                    (phoneState == 'error')
                                        ? <PhoneError error={phoneError} contact={contact} />
                                        : states[phoneState] || null
                                }
                            </div>
                        </>}
                    />
                </div>
            )}
            <div className="overflow-hidden relative truncate w-full">
                <FieldTextValue onClick={() => toggleEditableMode(true)} className="px-2 py-0.5">
                    {value || placeholder}
                </FieldTextValue>
            </div>
        </div>
    );
}


