import ct from 'countries-and-timezones';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

moment.locale('es');
dayjs.extend(utc)
dayjs.extend(timezone)

export const getCountryAndTimezoneByCode = (countryParse: string) => {
  let country = countryParse
  let timeszone: any = ct.getTimezonesForCountry((country).toUpperCase())
  if (timeszone?.length != 0) {
    timeszone = timeszone[0].utcOffset
  } else {
    timeszone = -180
    country = 'uy'
  }
  return [country, timeszone]
}

export const isValidTimeZone = (timeZone: string) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch (e) {
    return false;
  }
};

export const getDateTimeZone = (date: Date, timeZone?: string): Date => {
  if (!timeZone || (!isValidTimeZone(timeZone))) return date;
  return new Date(date.toLocaleString('en-US', { timeZone }));
}


export const formatChatDate = (date: Date): string => {
  const now = moment();
  const targetDate = moment(date);

  if (targetDate.isSame(now, 'day')) {
    return targetDate.format('HH:mm');
  }

  if (targetDate.isSame(now.subtract(1, 'days'), 'day')) {
    return 'Ayer';
  }

  if (targetDate.isSame(now, 'week')) {
    return targetDate.format('dddd');
  }

  return targetDate.format('DD/MM/YYYY');
};

export const getTimezone = () => dayjs.tz.guess();

export const convertToTimezone = (date: string | Date, toTimezone: string, fromTimezone?: string): Date => {
  const timezoneFrom = fromTimezone || dayjs.tz.guess();

  const parsedDate = dayjs(date).tz(timezoneFrom);
  const convertedDate = parsedDate.tz(toTimezone, true);

  return convertedDate.toDate();
}

export const getTimezoneByCountry = (country: string): string | undefined => {
  const countryZones = momentTimezone.tz.zonesForCountry(country);
  return countryZones?.[0];
}

export const adjustToTimezone = (date: Date | string, targetTimezone: string): Date => {
  return dayjs(date).tz(targetTimezone, true).toDate();
};