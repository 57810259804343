"use client"

import { isAuth } from "@/actions/auth"
import { getSellersList } from "@/actions/comercial/sale"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu"
import { isTheRolEnabled } from "@/helpers/featureFlagHelpers"
import { Share } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { Check, Plus, Trash } from "lucide-react"
import * as React from "react"
import { ContactAvatar } from "../Prospect"

export function ShareRequestSalePopover({ requestSale, setValues }: { requestSale: any, setValues: (string) => void }) {
    const [open, setOpen] = React.useState(false)
    const [sharedUsers, setSharedUsers] = React.useState<any>([])
    const currentUser = isAuth()

    const hasPermissionsToShare = React.useMemo(() => {
        if (requestSale?.creator._id === isAuth()._id) return true;
        if (isTheRolEnabled(['super', 'comercial'])) return true;
        const hasPermissions = requestSale?.shared.find((shared) => shared.userId._id === isAuth()._id && (shared.permissions === 'all' || shared.permissions === 'write'))
        if (hasPermissions) return true;
        return false;
    }, [requestSale, isAuth])

    const { data = { users: [] } } = useQuery({
        queryFn: getSellersList,
        queryKey: ['list-sales-users']
    })

    const listOfUsers = React.useMemo(() => {
        const users_exists = new Map();

        return data.users.filter((user) => {
            if (user._id === requestSale?.creator?._id) return false
            if (user._id === currentUser._id) return false
            if (!users_exists.has(user._id)) {
                users_exists.set(user._id, true)
                return true
            }
            return false
        })
    }, [data])

    const sharedWithList = React.useMemo(() => {
        if (!requestSale?.shared) return []
        const formattedList = requestSale.shared.map((shared) => {
            return {
                ...shared.userId,
                permissions: shared.permissions
            }
        })
        return formattedList
    }, [requestSale, listOfUsers])

    React.useEffect(() => {
        setSharedUsers(sharedWithList)
    }, [sharedWithList])

    const handleEditPermission = (userId: string, permission: "read" | "write" | "all" | "delete") => {
        const user = sharedUsers.find((u) => u._id === userId)
        if (permission === user?.permissions) return;

        if (permission === 'delete') {
            setSharedUsers(sharedUsers.filter((u) => u._id !== userId))
            return setValues((prev) => ([...prev.filter((u) => u.userId !== userId)]))
        }

        const newUserValue = { ...user, permissions: permission };

        const newSharedUsers = sharedUsers.map((u) => {
            if (u._id === userId) return newUserValue
            return u
        });

        setSharedUsers(newSharedUsers);

        setValues((prevValue) => {
            return prevValue.map((user) => {
                if (user.userId == userId) user.permissions = permission;
                return user;
            })
        });
    }

    return (
        <DropdownMenu open={open} onOpenChange={setOpen}>
            <DropdownMenuTrigger asChild>
                <IconButton sx={{ mr: 1 }} disabled={!hasPermissionsToShare} size="small" onClick={() => setOpen(true)}><Share /></IconButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[320px] z-[9999]">
                <DropdownMenuLabel className="text-xs text-gray-500">Compartir solicitud</DropdownMenuLabel>
                <DropdownMenuGroup >
                    <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                        <span className="flex items-center gap-2">
                            <ContactAvatar
                                className="w-[21px] shadow-sm text-[10px] h-[21px]"
                                bgColor={'#0080FF'}
                                textColor={'#fff'}
                                firstName={currentUser.name}
                                lastName={currentUser.lastname}
                                photo={currentUser.photo}
                            />
                            <p className="text-[13px]">{`${currentUser.name} ${currentUser.lastname} (Tú)`}</p>
                        </span>
                    </DropdownMenuItem>

                    {sharedUsers.length > 0 && sharedUsers.
                        filter((sharedUser) => sharedUser._id !== currentUser._id)
                        .map((sharedUser: {
                            _id: string,
                            name: string,
                            lastname: string,
                            photo: string
                            permissions: string
                        }) => (
                            <>
                                <DropdownMenuSeparator />
                                <DropdownMenuSub>
                                    <DropdownMenuSubTrigger className="relative">
                                        <span className="flex items-center gap-2">
                                            <ContactAvatar
                                                className="w-[21px] shadow-sm text-[10px] h-[21px]"
                                                bgColor={'#0080FF'}
                                                textColor={'#fff'}
                                                firstName={sharedUser.name}
                                                lastName={sharedUser.lastname}
                                                photo={sharedUser.photo}
                                            />
                                            <p className="text-[13px]">{`${sharedUser.name} ${sharedUser.lastname}`}</p>
                                        </span>
                                        <p className="absolute right-8 top-[5px] text-[13px] text-gray-500">{getPermissionTitle(sharedUser.permissions)}</p>
                                    </DropdownMenuSubTrigger>
                                    <DropdownMenuSubContent className="p-0">
                                        <Command>
                                            <CommandList>
                                                <CommandGroup>
                                                    {[{ value: 'Ver', permission: 'read' }, { value: 'Editar', permission: 'write' }, { value: 'Todo', permission: 'all' }].map((permission) => (
                                                        <CommandItem
                                                            key={permission.permission}
                                                            value={permission.permission}
                                                            onSelect={(value: "all" | "read" | "write" | "delete") => handleEditPermission(sharedUser._id, value)}
                                                            className="flex items-center justify-between"
                                                        >
                                                            <p className="text-[13px]">{permission.value}</p>
                                                            {sharedUser.permissions === permission.permission && <Check className="h-4 w-4 text-primary" />}
                                                        </CommandItem>
                                                    ))}
                                                    <DropdownMenuSeparator />
                                                    <CommandItem
                                                        key={'delete'}
                                                        value={'delete'}
                                                        onSelect={(value: "all" | "read" | "write" | "delete") => handleEditPermission(sharedUser._id, value)}
                                                        className="flex items-center justify-between"
                                                    >
                                                        <span className="text-[13px] text-red-600 hover:text-red-700 flex items-center justify-between w-full">
                                                            <p >Eliminar</p>
                                                            <Trash className="h-4 w-4" />
                                                        </span>
                                                    </CommandItem>
                                                </CommandGroup>
                                            </CommandList>
                                        </Command>
                                    </DropdownMenuSubContent>
                                </DropdownMenuSub>
                            </>
                        ))}
                    <DropdownMenuSeparator />
                    <DropdownMenuSub>
                        <DropdownMenuSubTrigger className="text-sm flex gap-2 items-center text-gray-500">
                            <Plus size={14} />
                            Invitar
                        </DropdownMenuSubTrigger>
                        <DropdownMenuSubContent className="p-0">
                            <Command>
                                <CommandInput
                                    placeholder="Buscar usuario..."
                                    autoFocus={true}
                                    className="text-sm"
                                />
                                <CommandList>
                                    <CommandEmpty>No hay resultados.</CommandEmpty>
                                    <CommandGroup>
                                        {listOfUsers
                                            .filter((user) => !sharedUsers.find((u) => u._id === user._id))
                                            .map((user) => (
                                                <CommandItem
                                                    key={user._id}
                                                    value={`${user.name} ${user.lastname}`}
                                                    onSelect={() => {
                                                        setSharedUsers([...sharedUsers, {
                                                            ...user,
                                                            permissions: 'read'
                                                        }])

                                                        setValues((prev) => ([
                                                            ...prev,
                                                            {
                                                                userId: user._id,
                                                                permissions: 'read',
                                                            }
                                                        ]));
                                                    }}
                                                    className="flex items-center justify-between"
                                                >
                                                    <span className="flex items-center gap-2">
                                                        <ContactAvatar
                                                            className="w-[21px] shadow-sm text-[10px] h-[21px]"
                                                            bgColor={'#0080FF'}
                                                            textColor={'#fff'}
                                                            firstName={user.name}
                                                            lastName={user.lastname}
                                                            photo={user.photo}
                                                        />
                                                        <p className="text-[13px]">{`${user.name} ${user.lastname}`}</p>
                                                    </span>
                                                </CommandItem>
                                            ))}
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </DropdownMenuSubContent>
                    </DropdownMenuSub>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu >
    )
}

export const getPermissionTitle = (permission: string) => {
    switch (permission) {
        case 'read':
            return 'Ver'
        case 'write':
            return 'Editar'
        case 'all':
            return 'Todo'
        default:
            return 'Desconocido'
    }
}