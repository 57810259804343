// import { useEffect, useState } from 'react';
// import { isAuth } from '../actions/auth'

// export default function ChatSoporte({focus}) {

import { useEffect } from 'react';
import { isAuth } from '../actions/auth';

export default function ChatSoporte({ focus }: { focus: boolean }) {
  useEffect(() => {
    const user = isAuth();
    if (!user) return;

    if (window && user.intercom_token) {
      window.intercomSettings = {
        app_id: 'clzsktrk',
        user_id: user._id,
        user_hash: user.intercom_token,
        name: `${user.name} ${user.lastname}`,
        email: user.email,
        company: {
          id: user.intercom_company_id,
          name: user.intercom_company,
        },
      };

      (function () {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
          if (focus) ic('show');
        } else {
          const d = document;
          const i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          const l = function () {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/clzsktrk';
            const x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (d.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    }
  }, [process.browser, process.browser && window && window.Intercom, focus]);

  return null;
}
