"use client"
import {
    Tabs,
    TabsList,
    TabsTrigger
} from "@/components/ui/tabs"
import dayjs from "dayjs"
import { Button } from "../ui/button"
import { Skeleton } from "../ui/skeleton"
import { FormatTime } from "./constants/types"

interface ListTimeProps {
    isLoadingGetAvailability?: boolean,
    isGettingAvailability?: boolean,
    dateSelected: Date,
    onChangeFormatDate: (format: FormatTime) => void,
    onSelectTime: (time: string) => void
    formatHour: FormatTime,
    times: { time: string }[],
}


export default function ListTime({
    isLoadingGetAvailability,
    isGettingAvailability,
    onChangeFormatDate,
    dateSelected,
    onSelectTime,
    formatHour,
    times
}: ListTimeProps) {
    return <div className="md:col-span-3 p-4 flex flex-col md:border-l">
        {
            dateSelected && <>
                {
                    isLoadingGetAvailability
                        ? <LoaderListTime />
                        : <>
                            <div className="mb-3 w-full flex items-center justify-between">
                                <h2 className="text-[18px] font-semibold capitalize">
                                    {dayjs(dateSelected).format('ddd').slice(0, 3)}
                                    <span className="text-gray-600 text-[16px]"> {dayjs(dateSelected).format('DD')}</span>
                                </h2>
                                <Tabs
                                    onValueChange={(value) => onChangeFormatDate(value as '12h' | '24h')}
                                    defaultValue={formatHour}
                                    value={formatHour}
                                    className="w-max"
                                >
                                    <TabsList className="grid w-max border h-8 grid-cols-2">
                                        <TabsTrigger value="12h" className="h-[23px] text-[13px]">12h</TabsTrigger>
                                        <TabsTrigger value="24h" className="h-[23px] text-[13px]">24h</TabsTrigger>
                                    </TabsList>
                                </Tabs>
                            </div>
                            <div className="relative w-full h-full flex flex-grow">
                                <ul className="flex flex-col flex-grow overflow-y-auto w-full h-full md:absolute top-0 left-0 max-h-max gap-[6px]">
                                    <TimeItem
                                        label="Iniciar reunion instantanea"
                                        onClick={() => onSelectTime('instant')}
                                        disable={isGettingAvailability}
                                    />
                                    {
                                        times.map((availabilityItem, index) => {
                                            const format = formatHour === '12h' ? 'h:mma' : 'HH:mm';
                                            return (
                                                <TimeItem
                                                    label={dayjs(availabilityItem.time).format(format)}
                                                    onClick={() => onSelectTime(availabilityItem.time)}
                                                    disable={isGettingAvailability}
                                                    key={index}
                                                />
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </>
                }
            </>
        }
    </div>
}

export function LoaderListTime() {
    return (
        <div className="grid gap-3 mt-9">
            {
                Array.from({ length: 5 }).map((__, inx) => {
                    return <Skeleton className="w-full bg-zinc-200 h-[26px] px-4 py-2" key={inx} />
                })
            }
        </div>
    )
}

export function TimeItem({
    onClick,
    label,
    disable = false
}: {
    onClick?: () => void,
    label: string,
    disable?: boolean
}) {
    return (
        <li>
            <Button
                disabled={disable}
                variant="outline"
                className="w-full truncate"
                onClick={onClick}
                type="button"
            >
                {label}
            </Button>
        </li>
    )
}