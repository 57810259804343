
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { useToggle } from "@/hooks/useToggle";
import { useEffect, useState } from "react";
import { UseContactFormReturn } from "./helpers/constants";

interface ContactNameFieldProps {
    contextForm: UseContactFormReturn,
    fullName?: string,
    onChangeValues: (value: { firstName: string, lastName: string }) => void
}

export default function ContactNameField({
    onChangeValues,
    contextForm,
    fullName
}: ContactNameFieldProps) {
    const [openPopover, togglePopover] = useToggle<boolean>()
    const [contactFullName, setFullName] = useState<{ firstName: string, lastName: string }>({ firstName: '', lastName: '' });

    useEffect(() => {
        const firstName = contextForm.getValues('firstName');
        const lastName = contextForm.getValues('lastName');

        if (openPopover) return setFullName({ firstName, lastName })

        if ((firstName == contactFullName.firstName && contactFullName.lastName == lastName) || !contactFullName.firstName.trim()) return;

        if (contactFullName.firstName) contextForm.setValue('firstName', contactFullName.firstName)
        contextForm.setValue('lastName', contactFullName.lastName);
        onChangeValues(contactFullName);
    }, [openPopover]);

    const onChangeField = (name: 'firstName' | 'lastName') => (value: string) => {
        setFullName(lastValue => ({ ...lastValue, [name]: value }));
    }

    return (
        <Popover open={openPopover} onOpenChange={togglePopover} >
            <PopoverTrigger asChild>
                <button
                    onClick={togglePopover}
                    style={{ wordBreak: 'break-word' }}
                    className="mt-2.5 font-medium text-[22px] line-clamp-3 text-gray-800 duration-100"
                    type="button"
                >
                    <span>
                        {fullName || 'Agrega un nombre...'}
                    </span>
                </button>
            </PopoverTrigger>
            <PopoverContent className="w-64 bottom-8 p-2 px-4 shadow-sm relative">
                <div className="grid gap-1.5">
                    <Field
                        label="Nombre"
                        autoFocus={true}
                        placeholder="Agrega un nombre..."
                        value={contactFullName.firstName}
                        onChangeValue={onChangeField('firstName')}
                    />
                    <Field
                        label="Apellido"
                        value={contactFullName.lastName}
                        placeholder="Agrega un apellido..."
                        onChangeValue={onChangeField('lastName')}
                    />
                </div>
            </PopoverContent>
        </Popover>
    )
}


interface FieldProps {
    label: string,
    placeholder: string,
    onChangeValue: (value: string) => void,
    value: string,
    autoFocus?: boolean
}

export function Field(props: FieldProps) {
    return (
        <div className="flex items-center gap-4 w-full">
            <span className="font-medium text-[14px] text-gray-700">
                {props.label}
            </span>
            <input
                type="text"
                autoComplete="off"
                autoCorrect="off"
                autoFocus={!!props.autoFocus}
                value={props.value || ''}
                placeholder={props.placeholder}
                onChange={(evt) => props.onChangeValue(evt.target.value)}
                className="text-[12.7px] font-medium text-gray-600 placeholder:text-gray-500 focus:outline-none w-full h-6"
            />
        </div>
    )
}