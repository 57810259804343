import { Files } from "lucide-react";

export default function EmptyTemplateAlert() {
    return (
        <div className="border rounded-md p-4 py-6 text-center flex items-center flex-col justify-center">
            <Files size={35} className="text-gray-400 mb-4" />
            <h5 className="text-[]17px] font-semibold text-gray-900 mb-0.5">
                Sin plantillas configuradas
            </h5>
            <p className="text-gray-600 text-sm">
                Aún no tienes plantillas configuradas. Crea una para optimizar y agilizar tu trabajo.
            </p>
        </div>
    )
}