import { Button } from "@/components/ui/button";
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { AtSign, Phone, Plus } from "lucide-react";
import { useMemo } from "react";

interface SelecteableFieldsProps {
    handleAddField: (field: any) => void,
    fields: any[],
}

export default function SelecteableFields({
    fields = [],
    handleAddField
}: SelecteableFieldsProps) {

    const allFields = useMemo(() => {
        return [
            {
                label: 'Teléfono',
                code: 'phones',
                icon: <Phone size={17} />,
                type: 'phone',
                emptyText: 'Agrega teléfonos...',
                fieldType: 'array'
            },
            {
                label: 'Email',
                code: 'emails',
                icon: <AtSign size={17} />,
                type: 'email',
                emptyText: 'Agrega emails...',
                fieldType: 'array'
            },
            ...fields
        ]
    }, [fields])
    
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={'ghost'}
                    type="button"
                    className="flex items-center w-max px-2 h-7 text-[12.9px] font-medium mt-2 text-gray-600 gap-2"
                >
                    <Plus size={17} />
                    Agregar propiedad
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-60 p-0">
                <Command className="p-0">
                    <CommandList className="max-h-[280px] overflow-y-auto">
                        <CommandGroup>
                            {
                                allFields.map((field) => {
                                    return (
                                        <CommandItem
                                            key={field.code}
                                            onSelect={() => handleAddField(field)}
                                        >
                                            <span className="mr-2">
                                                {field.icon}
                                            </span>
                                            <span>
                                                {field.label}
                                            </span>
                                        </CommandItem>
                                    )
                                })
                            }
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}