import { getCompany, getCookie, isAuth } from "@/actions/auth"
import { Button } from "@/components/ui/button"
import { CardDescription } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import useGetStatusTag from "@/hooks/useGetStatusTag"
import interationsToText from "@/lib/crm/interactionsToText"
import { Facebook, Instagram } from "@mui/icons-material"
import dayjs from "dayjs"
import _ from "lodash"
import { Copy, Magnet, Mail, PencilLine } from "lucide-react"
import { PropsWithChildren, ReactNode, useMemo, useState } from "react"
import { NewPhone } from "../Bubble/Icons/Crm"
import PuppoverStatusTagChange from "../StatusTag/puppover-change"
import StatusTagCircle from "../StatusTag/status-tag-circle"
import ViewOnMapButton from "../view-on-map-button"
import { ContactDetailLi } from "./contact-detail-li"
import DetailAccordionItem from "./detail-accordion-item"
import { DialogUnifyContact } from "./DialogUnifyContact"

const API_NEXT = process.env.API_NEXT;

interface IPropsListDetailOfContact {
    icon?: null | ReactNode
    title: string
    data: string | ReactNode
}

const channelDataByType = {
    'ig': {
        icon: <Instagram style={{ fontSize: '14px' }} />,
        name: 'Instagram'
    },
    'leads': {
        icon: <Facebook style={{ fontSize: '14px' }} />,
        name: 'Facebook leads'
    },
    'fb': {
        icon: <Facebook style={{ fontSize: '14px' }} />,
        name: 'Facebook'
    }
};

interface UserSocialNetwork {
    username: string
    pageId: string,
    PSID: string,
    type: 'ig' | 'leads' | 'fb'
};

export default function ProspectDetailItem({
    handleUpdateContact,
    prospect,
}) {
    const {
        firstName,
        lastName,
        statusTag,
        originText,
        detail,
        emails,
        phones,
        additionalData,
        contactLocation,
        channels
    } = prospect || {};

    const { find } = useGetStatusTag({ code: statusTag || '' })
    const allAdditionalData = useMemo(() => _.get(getCompany(), "additionalDataFields", []), [])
    const groupActiveExtraFields = allAdditionalData.filter(field => field.active)
    const matchingFields = groupActiveExtraFields.filter((field) => additionalData?.[field.code]);
    const groupCrmConfig = useMemo(() => _.get(getCompany(), "crmConfig", []), [])
    const directionFieldEnabled = useMemo(() => groupCrmConfig?.enableLocationField, [groupCrmConfig])
    const [unifyContact, setUnifyContact] = useState(false)

    const socialNetworkLink: UserSocialNetwork[] = useMemo(() => {
        return channels
            .filter((channel) => channel?.config?.username)
            .map(({ config, type }) => {
                return {
                    username: config?.username || [firstName, lastName].filter(Boolean).join(' '),
                    pageId: config.pageId,
                    PSID: config.PSID,
                    type
                }
            }) as UserSocialNetwork[]
    }, [channels]);

    const handlCopyConversation = () => {
        navigator.clipboard.writeText(interationsToText(prospect?.firstName, prospect?.interactions ?? []));
    }

    const onViewSocialNetwork = async (channel: UserSocialNetwork) => {
        const token = getCookie('token');

        const searchParams = new URLSearchParams();

        searchParams.append('socialNetworkId', channel.pageId)
        searchParams.append('socialNetworkContactId', channel.PSID)
        searchParams.append('channelType', channel.type)
        searchParams.append('accessToken', token)
        searchParams.append('username', channel.username)

        const url = `${API_NEXT}/crm/contact/${prospect._id}/socialNetwork?${searchParams.toString()}`;
        window.open(url, "_blank");
    }

    const hasUnifyContacts = useMemo(() => {
        const unifyConfig = groupCrmConfig.unifyContacts;

        if (!unifyConfig.active) return false;

        if (unifyConfig.roles.some(role => role === 'all')) return true;

        return unifyConfig.roles.some(role => isAuth().roles?.includes(role));
    }, [groupCrmConfig])

    return <>
        <DetailAccordionItem label="Detalles" value="value-2">
            <ul className="flex flex-col gap-3 mt-4">
                <ContactDetailName
                    title={"Origen:"}
                    data={originText}
                />

                {detail && detail.length > 0 && (
                    <ContactDetailName title={"Detalle:"} data={detail} />
                )}

                <ContactDetailName
                    title={"Etiqueta:"}
                    data={
                        <div className="font-medium overflow-hidden relative shrink-0 text-neutral-950 flex text-xs mt-0.5 gap-1 items-center">
                            <StatusTagCircle className="w-[7px] h-[7px]" code={statusTag || ''} />
                            <span className="w-full">
                                {find?.name || "Sin etiqueta"}
                            </span>
                            <PuppoverStatusTagChange
                                id={prospect._id || ''}
                                statusTag={statusTag || ''}
                            />
                        </div>
                    }
                />

                {
                    (phones.length > 0) && (
                        <ContactDetailName
                            icon={<NewPhone className="w-[13px] h-[13px]" />}
                            data={phones?.join(", ")}
                            title={"Teléfonos:"}
                        />
                    )
                }

                {emails && emails.length > 0 && (
                    <ContactDetailName
                        title={"E-mails:"}
                        icon={
                            <Mail className="w-[13px] h-[13px] text-black" />
                        }
                        data={emails?.join(", ")}
                    />
                )}

                {
                    socialNetworkLink.length > 0 && (
                        socialNetworkLink.map((channel, index: number) => {
                            const channelData = channelDataByType[channel.type] || {};
                            if (!channel?.username) return null;

                            return (
                                <ContactDetailName
                                    title={`${channelData?.name}:`}
                                    icon={channelData.icon}
                                    key={index}
                                    data={(
                                        <Button
                                            variant={'link'}
                                            className="p-0 font-normal"
                                            onClick={() => onViewSocialNetwork(channel)}
                                        >
                                            {channel?.username || ''}
                                        </Button>
                                    )}
                                />
                            )
                        })
                    )
                }

                {directionFieldEnabled && contactLocation && contactLocation.location && (
                    <>
                        <Separator />
                        <ContactDetailName
                            title={"Dirección:"}
                            data={contactLocation?.formatted_address}
                        />
                        {contactLocation && contactLocation.extraInfo && (
                            <ContactDetailName
                                title={"Comentario:"}
                                data={contactLocation?.extraInfo}
                            />
                        )}
                        <ViewOnMapButton
                            address={contactLocation?.formatted_address}
                            placeId={contactLocation?.place_id}
                        />
                    </>
                )}
                {matchingFields.length > 0 ? <Separator /> : null}

                {matchingFields.map((field) => {

                    switch (field.type) {
                        case 'select': return (
                            <ContactDetailName
                                key={field.code}
                                title={`${field.name}:`}
                                data={additionalData?.[field.code]}
                            />
                        )
                        case 'multiselect': return (
                            <ContactDetailLi
                                key={field.code}
                                title={`${field.name}:`}
                                data={additionalData?.[field.code]}
                                fieldColors={groupActiveExtraFields.find(f => f.code === field.code)?.options}
                            />
                        )
                        default: {
                            const value = additionalData?.[field.code];
                            const data = (field.type == 'date')
                                ? value ? dayjs(value).format('DD/MM/YYYY') : null
                                : value;

                            return (
                                <ContactDetailName
                                    key={field.code}
                                    title={`${field.name}:`}
                                    data={data}
                                />
                            )
                        }
                    }
                })}
            </ul>
            <Separator className="mb-2 mt-3" />
            <div className="flex flex-col gap-1.5">
                <ActionButton
                    onClick={() => setUnifyContact(true)}
                    hidden={!hasUnifyContacts}
                >
                    Unificar contacto
                    <Magnet size={15} />
                </ActionButton>

                <ActionButton onClick={handlCopyConversation}>
                    Copiar conversación
                    <Copy size={14} />
                </ActionButton>

                <ActionButton onClick={handleUpdateContact}>
                    Editar contacto
                    <PencilLine size={15} />
                </ActionButton>
            </div>
        </DetailAccordionItem>
        <DialogUnifyContact
            onOpenChange={() => setUnifyContact(false)}
            open={unifyContact}
            contact={prospect}
        />
    </>
}

export function ContactDetailName({
    data,
    title,
    icon = null,
}: IPropsListDetailOfContact) {
    if (!data) return null;

    return (
        <li>
            <CardDescription className="flex flex-wrap gap-2 line-clamp-4 text-[13px]">
                <span className="text-slate-800 text-[14px] flex items-center shrink-0 gap-1 font-medium">
                    {icon}
                    {title}
                </span>
                {data}
            </CardDescription>
        </li>
    )
}

interface ActionButtonProps extends PropsWithChildren {
    onClick: () => void,
    hidden?: boolean
}

export function ActionButton({ onClick, children, hidden = false }: ActionButtonProps) {
    if (hidden) return null;

    return (
        <Button
            onClick={onClick}
            className="h-8 flex text-slate-800 items-center gap-2 text-[13px] hover:bg-none"
            variant={"ghost"}
        >
            {children}
        </Button>
    )
}