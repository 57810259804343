import useGetGoals from "@/hooks/requests/useGetGoals";
import useGetSummaryByPeriod from "@/hooks/requests/useGetSummaryByPeriod";
import { TYPES_CHART } from "@/interfaces/config";
import { createContext, useContext, useEffect, useState } from "react";
import { getAnalyticPeriod } from "../helpers/getAnalyticPeriod";
import { validateReachedGoal } from "../helpers/validateReachedGoal";
const ContextPerformanceChart = createContext({})

const defaultAnalyticChart = {
    "sale": {},
    "contacts": {},
    "tracing": {}
}

export function PerformanceChartProvider({ children }) {
    const [isLoadingFake, setIsLoadingFake] = useState(false)
    const [periodDate, setPeriodDate] = useState({ start: '', end: '' })
    const [periodInformation, setInformationPeriod] = useState({})
    const [agentsId, setAgentId] = useState<string[] | null>(null)
    const [chartType, setChartType] = useState<string>(TYPES_CHART['DETAILED'])
    const [periodSelected, setPeriodSelected] = useState('now')
    const [lastPeriodSelected, setLastPeriodSelected] = useState(periodSelected)
    const [agentSelected, setAgentSelected] = useState<any>(undefined)
    const [goals, setGoals] = useState<any[]>([]);

    const { end, start } = periodDate

    const handleChangePeriodDate = (period: { start: string, end: string }) => setPeriodDate(period)
    const handleChangeInformationPeriod = (information) => setInformationPeriod(information)
    const handleChangeAgentsId = (ids: string[]) => setAgentId(ids)

    const handleChangeChartType = () => {
        setChartType(chartType === TYPES_CHART['DETAILED']
            ? TYPES_CHART['SIMPLIFIED']
            : TYPES_CHART['DETAILED'])
    }
    const handleChangePeriodSelected = (period: string) => {
        setLastPeriodSelected(periodSelected)
        setPeriodSelected(period)
    }
    const handleChangeGoals = (goals) => setGoals(goals)
    const handleChangeAgentsSelected = (agents) => setAgentSelected(agents)

    const { querySummaryByPeriod: analyticChart, isLoading: isLoadingGetSummary, refetchSummary } = useGetSummaryByPeriod({
        offset: goals?.[0]?.offset || -180,
        enabled: !!start && !!end && ((agentsId && agentsId.length > 0) || !!agentSelected),
        endDate: end,
        startDate: start,
        usersID: agentsId,
        queryKey: [`summary-by-period`]
    })

    const { isLoading, result, isFetching: isFetchingGoals } = useGetGoals({
        queryKey: [`goals-by-period`, agentSelected, periodSelected],
        period: periodSelected,
        byUser: agentSelected
    })

    useEffect(() => {
        if (result) {
            const { agentId, analyticPeriod, goals, periodDate } = getAnalyticPeriod({
                currentUserId: agentSelected,
                goals: result || [],
                period: periodSelected
            });

            setGoals(goals)
            setAgentId(agentId)
            setPeriodDate(periodDate)
            setInformationPeriod(analyticPeriod)
        }
    }, [result, JSON.stringify(result), agentSelected, periodSelected, isFetchingGoals])

    useEffect(() => {
        if ((!agentsId || agentsId && agentsId.length === 0) || (lastPeriodSelected == periodSelected)) return;
        refetchSummary()
    }, [JSON.stringify(agentsId), end])

    const createFakeLoader = () => {
        setIsLoadingFake(true)
        simulateRequest(300)
            .then(() => setIsLoadingFake(false))
    }

    useEffect(() => {
        if (chartType === TYPES_CHART['SIMPLIFIED']) createFakeLoader()
        else {
            setIsLoadingFake(false)
        }
    }, [periodSelected])

    useEffect(() => {
        createFakeLoader()
    }, [agentSelected])

    const isLoadingAnalyticChart = isLoading || isLoadingGetSummary || (chartType == TYPES_CHART['SIMPLIFIED'] && isLoadingFake)

    return <ContextPerformanceChart.Provider value={{
        analyticChart: analyticChart || defaultAnalyticChart,
        handleChangeInformationPeriod,
        handleChangePeriodSelected,
        handleChangeAgentsSelected,
        isLoadingAnalyticChart,
        handleChangeChartType,
        handleChangePeriodDate,
        handleChangeAgentsId,
        handleChangeGoals,
        periodInformation,
        periodSelected,
        agentSelected,
        periodDate,
        chartType,
        agentsId,
        goals
    }}>
        {children}
    </ContextPerformanceChart.Provider>
}

export const usePerformanceChartContext = (): Record<string, any> => {
    const context = useContext(ContextPerformanceChart);
    if (!context) throw new Error('useMyContext must be used within a MyProvider');
    return context;
};

function simulateRequest(delay: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, delay);
    });
}

