import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "@/components/ui/avatar";
import { getStatusTagColor } from "@/helpers/tagColorToPalette";
import { cn } from "@/lib/utils";

interface ContactProfilePictureProps {
    photo?: string,
    lastName?: string,
    firstName: string,
    statusTag?: string
    className?: string
}

export default function ContactProfilePicture({
    firstName,
    statusTag,
    lastName,
    photo,
    className
}: ContactProfilePictureProps) {
    const initials = [firstName, lastName].filter(Boolean).map((str: string) => str[0].toUpperCase()).join('');

    return (
        <Avatar
            className={cn('font-medium text-base', className)}

        >
            <AvatarImage src={photo} alt={`Image profile of ${firstName} ${lastName}`} />
            <AvatarFallback
                style={{
                    color: statusTag ? getStatusTagColor(statusTag, "secondary") : '',
                    backgroundColor: statusTag ? getStatusTagColor(statusTag, "primary") : ''
                }}
            >
                {initials}
            </AvatarFallback>
        </Avatar>
    )
}
