import { useToggle } from "@/hooks/useToggle";
import { useEffect, useRef, useState } from "react";
import FieldTextValue from "./FieldTextValue";

interface DefaultFieldProps {
    type: 'string' | 'number';
    value: string | number;
    onChangeValue: (value: string | number) => void;
    placeholder?: string;
}

export default function MixedTypeInput({
    onChangeValue,
    type,
    value,
    placeholder
}: DefaultFieldProps) {
    const [editableMode, toggleEditableMode] = useToggle<boolean>(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [textareaValue, setTextareaValue] = useState<string | number>(value);

    const isNumber = type === 'number';

    const handleChangeDefaultValue = () => {
        
        if (!textareaValue || `${textareaValue}`.trim() === '') {
            setTextareaValue('');
            onChangeValue('');
        } else {
            if (textareaValue == value) return;
            onChangeValue(isNumber ? Number(textareaValue) : textareaValue);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                handleChangeDefaultValue();
                toggleEditableMode(false);
            }
        };

        if (editableMode) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [editableMode, toggleEditableMode, handleChangeDefaultValue]);

    useEffect(() => {
        setTextareaValue(value);
    }, [value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;

        if (isNumber) {
            const numericValue = value.replace(/\D/g, '');
            setTextareaValue(numericValue);
        } else {
            setTextareaValue(value);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleChangeDefaultValue();
            toggleEditableMode(false);
        }
    };

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        if (textareaRef.current && editableMode) {
            adjustTextareaHeight();
            textareaRef.current.focus();
            textareaRef.current.setSelectionRange(
                textareaRef.current.value.length,
                textareaRef.current.value.length
            );
        }
    }, [textareaValue, editableMode]);

    return (
        <div ref={wrapperRef} className="relative flex items-center">
            {
                editableMode && (
                    <div
                        className={`absolute flex flex-col bg-white my-auto left-[-10px] z-10`}
                        style={{ width: 'calc(100% + 9px)' }}
                    >
                        <textarea
                            ref={textareaRef}
                            className="outline-none w-full resize-none overflow-hidden font-medium text-gray-700 placeholder:text-gray-700 bg-gray-50 max-h-[150px] text-[13px] py-2 px-3 rounded-lg border h-[34px]"
                            value={textareaValue}
                            onChange={handleInputChange}
                            style={{
                                boxShadow: '0px 4px 15px rgba(0,0,0,0.08)',
                                maxHeight: '150px',
                                overflowY: 'auto',
                            }}
                            onKeyDown={handleKeyDown}
                            placeholder={placeholder}
                            onInput={adjustTextareaHeight}
                            rows={1}
                        />
                    </div>
                )
            }
            <FieldTextValue onClick={() => toggleEditableMode(true)} className="px-2 py-0.5 line-clamp-5">
                {value || placeholder}
            </FieldTextValue>
        </div>
    );
}