'use client'
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import dayjs from 'dayjs';

import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as React from 'react';

import 'dayjs/locale/es';
import { Skeleton } from './skeleton';
dayjs.locale('es')

interface MonthlyViewCalendarProps {
    onChangeValue: (value: string[]) => void,
    selectedDates: string[],
    disabledDays: string[],
    currentDate?: Date,
    loading?: boolean,
    disableDeselectDate?: boolean
    onChangeDate?: (date: Date) => void
}
const days = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB']

export default function MonthlyViewCalendar({
    currentDate: externalCurrentDate,
    disabledDays = [],
    onChangeValue,
    selectedDates,
    onChangeDate,
    loading,
    disableDeselectDate = false
}: MonthlyViewCalendarProps) {
    const [currentDate, setCurrentDate] = React.useState(externalCurrentDate ? dayjs(externalCurrentDate) : dayjs().startOf('month'));

    const dateToUse = externalCurrentDate ? dayjs(externalCurrentDate) : currentDate;

    const daysInMonth = dateToUse.daysInMonth()
    const firstDayOfMonth = dateToUse.startOf('month').day()

    const todayDate = dayjs();
    const isCurrentMonth = dateToUse.isSame(todayDate, 'month')
    const disabledDaysToSet = new Set(disabledDays);

    const handlePrevMonth = () => {
        const value = dateToUse.subtract(1, 'month')
        setCurrentDate(value)
        onChangeDate && onChangeDate(value.toDate())
    }

    const handleNextMonth = () => {
        const value = dateToUse.add(1, 'month')
        setCurrentDate(value)
        onChangeDate && onChangeDate(value.toDate())
    }

    const handleDateClick = (date: string) => {
        const value = Array.from(new Set(selectedDates.includes(date) && !disableDeselectDate
            ? selectedDates.filter(d => d !== date)
            : [...selectedDates, date]).values());

        onChangeValue(value)
    }

    const isSelected = (date: string) => {
        return selectedDates.includes(date)
    }

    return (
        <div className="w-full  text-zinc-700 rounded-lg">
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-[18px] font-semibold capitalize">
                    {dateToUse.format('MMMM')}
                    <span className="text-gray-600 text-[16px]"> {dateToUse.format('YYYY')}</span>
                </h2>
                <div className="flex gap-1">
                    <Button
                        variant="ghost"
                        size="icon"
                        type="button"
                        onClick={handlePrevMonth}
                        disabled={isCurrentMonth}
                        className="h-8 w-8 text-zinc-400 disabled:cursor-not-allowed hover:text-white hover:bg-zinc-800 "
                    >
                        <ChevronLeft className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="ghost"
                        size="icon"
                        type="button"
                        onClick={handleNextMonth}
                        className="h-8 w-8 text-zinc-400 hover:text-white hover:bg-zinc-800"
                    >
                        <ChevronRight className="h-4 w-4" />
                    </Button>
                </div>
            </div>
            <div className="grid grid-cols-7 gap-1 mb-1">
                {days.map((day) => (
                    <div
                        key={day}
                        className="text-[11px] text-center py-1 font-medium text-zinc-400"
                    >
                        {day}
                    </div>
                ))}
            </div>
            <div className="grid grid-cols-7 gap-1">
                {Array.from({ length: firstDayOfMonth }).map((_, index) => (
                    <div key={`empty-${index}`} className="aspect-square" />
                ))}

                {Array.from({ length: daysInMonth }).map((_, index) => {

                    if (loading) return <div
                        className={cn(
                            "aspect-square flex items-center font-medium flex-col relative justify-center border-2 border-transparent rounded-md text-[14px] transition-colors"
                        )}
                    >
                        <Skeleton
                            key={index}
                            className={cn("w-10 h-10 bg-zinc-200 rounded-md")}
                        />
                    </div>

                    const date = dateToUse.date(index + 1).format('YYYY-MM-DD');
                    const isDateSelected = isSelected(date);
                    const isCurrent = dateToUse.date(index + 1).isSame(todayDate, 'day');
                    const isPast = dateToUse.date(index + 1).isBefore(todayDate, 'day');
                    const isDisabled = disabledDaysToSet.has(date);

                    return (
                        <button
                            key={date}
                            onClick={() => (!isPast && !isDisabled) && handleDateClick(date)}
                            disabled={isPast || isDisabled}
                            type="button"
                            className={cn(
                                "aspect-square flex items-center font-medium flex-col relative justify-center border-2 border-transparent rounded-md text-[14px] transition-colors",
                                isDateSelected
                                    ? "bg-zinc-900 text-white hover:bg-zinc-800"
                                    : (isPast || isDisabled)
                                        ? " text-zinc-800"
                                        : "bg-zinc-200 text-zinc-800 hover:bg-zinc-300"
                            )}
                        >
                            {index + 1}
                            {isCurrent && (
                                <span className={`w-[6px] h-[6px] rounded-full absolute bottom-[3px] ${isDisabled ? 'bg-zinc-800' : isDateSelected ? 'bg-white' : 'bg-zinc-800'}`} />
                            )}
                        </button>
                    );
                })}
            </div>
        </div>
    )
}
