"use client"
import { Colleague } from "@/interfaces/Interface"
import { GenericCombobox } from "../ui/generic-combobox"
import { UserAvatar } from "../ui/user-avatar"

interface AssignedUserSelectorProps {
    currentUser: Colleague,
    colleagues: Colleague[],
    value: string,
    onChange: (value: string) => void,
    usersMap: Record<string, Colleague>,
    disabled?: boolean
}

export default function AssignUserSelector({
    currentUser,
    colleagues,
    value,
    disabled,
    onChange,
    usersMap = {}
}: AssignedUserSelectorProps) {
    const options = generateOptions(currentUser, colleagues);

    return (
        <GenericCombobox
            buttonClassName="text-gray-800 w-full overflow-hidden text-[15px] px-2 h-8"
            options={options}
            placeholder="Seleccionar"
            modal={true}
            noResultsText="Sin resultados"
            searchPlaceholder="Buscar..."
            deselectValue={false}
            value={value}
            disabled={disabled}
            onChange={onChange}
            renderResult={(value) => {
                const user = usersMap[value];
                if (!user) return null;
                return renderUserLabel(user, 25, value == 'me' ? ' (Tú)' : '');
            }}
        />
    )
}

export const renderUserLabel = (user: Colleague, size = 20, extraLabel: string = '') => {
    const fullName = [user?.name, user?.lastname].filter(Boolean).join(' ');

    return (
        <div className="max-w-full w-full overflow-hidden">
            <span className="flex items-center truncate overflow-hidden max-w-full gap-2">
                <UserAvatar
                    user={user}
                    className={`w-[${size}px] border h-[${size}px] text-[${Math.floor(size / 2)}px]`}
                />
                <span className="max-w-full truncate">
                    {fullName}
                    {extraLabel}
                </span>
            </span>
        </div>
    );
};

const generateOptions = (currentUser: Colleague, colleagues: Colleague[]) => [
    {
        value: 'me',
        label: renderUserLabel(currentUser, 20, ' (Tú)'),
        searchableBy: [currentUser.name, currentUser.lastname]
    },
    ...colleagues.map((colleague) => ({
        value: colleague._id,
        label: renderUserLabel(colleague),
        searchableBy: [colleague.name, colleague.lastname]
    })),
];