import { getColleagues } from "@/actions/user";
import { Colleague } from "@/interfaces/Interface";
import { setColleagues } from "@/redux/slices/global";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


export default function useGetColleagues({ enabled = true }: { enabled?: boolean }) {
    const colleagues: Colleague[] = useSelector((state: any) => state.global.colleagues) as Colleague[];
    const dispatch = useDispatch();

    const { data, isLoading, isError } = useQuery({
        queryKey: ['my-colleagues'],
        queryFn: getColleagues,
        enabled: (enabled && colleagues.length == 0)
    });

    useEffect(() => {
        if (!data?.colleagues) return;
        dispatch(setColleagues(data.colleagues));
    }, [data])

    return {
        colleagues,
        isLoading,
        isError
    }
}
