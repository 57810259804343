export enum Routes {
  CHANNELS = "/company/channels",
  ADD_CHANNEL = "/company/channels",
  ADD_WHATSAPP_CHANNEL = "/company/channels/add/whatsapp",
  ADD_LEADS_CHANNEL = "/company/channels/add/leads",
  ADD_INSTAGRAM_CHANNEL = "/company/channels/add/instagram",
  ADD_FACEBOOK_CHANNEL = "/company/channels/add/facebook",
  GET_UNASSIGNED_CONTACT = "/company/contacts/unassigned",
  WATCH_CAMPAIGN = '/campaign',
  BUSSINES = '/companies',
  CREATE_CAMPAIGN = '/campaigns/create',
  ADD_UNOFFICIAL_WHATSAPP_CHANNEL = "/company/channels/add/unofficial-whatsapp",
  MEETINGS = '/settings/meetings',
  CONTACT = '/contact',
  COMISSION_REPORT = '/reportes/comissions',
  BACKOFFICE_COMISSION = '/comercial/sale/comissions',
  SALE_CONVERSATION_CONFIG = '/company/saleBubbleTemplates',
  SALE_REPORT = '/reportes/receipts',
  NOTIFICATION_SHIPMENT = '/grupos/notification-shipment',
  CALENDAR_AND_AVAILABILITY = '/settings/calendar-and-availability',
  UPLOAD_CONTACTS = '/contacts/upload',
  UPDATE_CONTACTS = '/contacts/update'
}
