import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import { ReactNode } from "react";
import { LoadingButton } from "../buttons/loading-button";

interface ModalActivateTagProps {
    open: any,
    onClose: () => void,
    description: string | ReactNode
    handleSubmit: () => void
    loading: boolean
    title: string
    labelBtn?: string,
    leftButton?: {
        loading: boolean,
        label: string
    }
}

export const AlertDialogActivate = ({
    open,
    onClose,
    description,
    handleSubmit,
    loading = false,
    title,
    labelBtn,
    leftButton = {
        label: 'Cancelar',
        loading: false
    }
}: ModalActivateTagProps) => {
    return (
        <AlertDialog open={open} onOpenChange={onClose}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription className="flex flex-col gap-3">
                        {description}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <LoadingButton
                        loading={leftButton.loading}
                        variant={'outline-primary'}
                        onClick={onClose}
                    >
                        {leftButton.label}
                    </LoadingButton>
                    <LoadingButton
                        variant={'california'}
                        onClick={handleSubmit}
                        loading={loading}
                        className="bg-primary hover:bg-blue-500">
                        {labelBtn || 'Activar'}
                    </LoadingButton>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
