"use client"

import { ChevronDown } from "lucide-react"
import * as React from "react"

import { Button } from "@/components/ui/button"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandList
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { searchRegex } from "@/helpers/regex"
import { cn } from "@/lib/utils"
import Checkbox from "./custom/checkbox"
import { GenericComboboxSearchInput } from "./generic-combobox"

type Option = {
    value: string;
    label: React.ReactNode | string,
    searchableBy: string
};

type GenericMultipleComboboxProps = {
    options: Option[];
    placeholder?: string;
    hiddenCheckbox?: boolean,
    searchPlaceholder?: string;
    noResultsText?: string;
    className?: string;
    buttonClassName?: string;
    disabled?: boolean;
    onChange?: (value: string[]) => void;
    modal?: boolean,
    value: string[],
    trigger?: React.ReactNode
};

export function GenericMultipleCombobox({
    options = [],
    placeholder = "Select an option...",
    searchPlaceholder = "Search...",
    noResultsText = "No options found.",
    className = "",
    disabled,
    buttonClassName = "",
    onChange,
    modal = false,
    value,
    hiddenCheckbox = false,
    trigger
}: GenericMultipleComboboxProps) {
    const [open, setOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState<string>('');
    const handleSelect = (selectedValue: string) => {
        const newValue = [...value];
        const valueIndex = newValue.indexOf(selectedValue);

        if (valueIndex == -1) {
            newValue.push(selectedValue);
        } else {
            newValue.splice(valueIndex, 1);
        }

        setOpen(false);
        if (onChange) onChange(newValue);
    };

    const filteredOptions = React.useMemo(() => {
        if (!searchValue) return options;
        const regex = searchRegex(searchValue);
        return options.filter((option) => regex.test(option.searchableBy));
    }, [options, searchValue]);

    const GetTrigger = () => {
        if (trigger) return trigger as JSX.Element;

        return (
            <Button
                variant="ghost"
                role="combobox"
                type="button"
                aria-expanded={open}
                className={cn(`w-max justify-between ${disabled ? "cursor-default hover:bg-transparent" : ""} ${open ? 'bg-accent' : ''}`, buttonClassName)}
            >
                {value.length > 0 ? `${value.length} selected` : placeholder}
                {!disabled && (<ChevronDown className={`ml-2 ${open ? "rotate-180" : ""} shrink-0 duration-100`} size={17} />)}
            </Button>
        )
    }

    if (disabled) return GetTrigger();

    return (
        <Popover open={open} onOpenChange={setOpen} modal={modal}>
            <PopoverTrigger asChild>
                {GetTrigger()}
            </PopoverTrigger>
            <PopoverContent className={`w-full max-w-[300px] p-0 ${className}`} align="start">
                <Command className="max-h-[260px]">
                    <GenericComboboxSearchInput
                        placeholder={searchPlaceholder}
                        onValueChange={setSearchValue}
                        value={searchValue}
                    />
                    <CommandList>
                        <CommandEmpty>{noResultsText}</CommandEmpty>
                        <CommandGroup>
                            {filteredOptions.map((option) => {
                                const isSelected = value.includes(option.value);

                                return (
                                    <CommandItem
                                        key={option.value}
                                        value={option.value}
                                        className="overflow-hidden group"
                                        onSelect={() => handleSelect(option.value)}
                                    >
                                        {
                                            !hiddenCheckbox && (
                                                <Checkbox
                                                    className={`${isSelected ? "opacity-100" : "opacity-0"} mr-2`}
                                                    selected={isSelected}
                                                />
                                            )
                                        }
                                        <span className="truncate max-w-full">
                                            {option.label}
                                        </span>
                                    </CommandItem>
                                )
                            })}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
