"use client"
import { isAuth } from "@/actions/auth"
import { CreateCalendarEventParams, createMeetingLink } from "@/actions/crm/calendar"
import { createMeeting } from "@/actions/crm/contact"
import {
    Dialog,
    DialogContent
} from "@/components/ui/dialog"
import useGetAvailabilityMeetingIntegrations from "@/hooks/user/useGetAvailabilityMeetingIntegrations"
import useGetCalendarAvailability from "@/hooks/user/useGetCalendarAvailability"
import useGetColleagues from "@/hooks/user/useGetColleagues"
import { ModalPropsBase } from "@/interfaces/Interface"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import dayjs from "dayjs"
import _ from "lodash"
import { Clock2 } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { toastActionError } from "../comercial/comercialManagment/comissions/CommisionModal"
import { CustomFormField } from "../ui/custom-form-field"
import { Form } from "../ui/form"
import { GenericCombobox } from "../ui/generic-combobox"
import MonthlyViewCalendar from "../ui/monthly-view-calendar"
import { Skeleton } from "../ui/skeleton"
import { toast } from "../ui/use-toast"
import AssignUserSelector from "./assign-user-selector"
import ConfirmStep from "./confirm-step"
import { defaultMeetingValue, DURATIONS_OPTIONS, MEETING_MEDIUMS, meetingFormSchema, MeetingFormType } from "./constants"
import { MeetingStep } from "./constants/types"
import ListTime from "./list-time"


export interface MeetingClient {
    firstName: string;
    lastName: string;
    photo: string;
    id: string;
    email: string
};

interface MeetingModalProps extends ModalPropsBase {
    client: MeetingClient
};

// TODO: Refactorizar
export default function MeetingModal({ open, onOpenChange, client }: MeetingModalProps) {
    const [step, setStep] = useState<MeetingStep>('select-date');
    const [formatHour, setFormatHour] = useState<'12h' | '24h'>('12h');
    const [calendarDate, setCalendarDate] = useState<Date>(dayjs().startOf('month').toDate());
    const [isInstant, setIsInstant] = useState<boolean>(false)

    const {
        availability,
        isLoading: isLoadingGetAvailability,
        onChangeParams: onChangeAvailabilityParams
    } = useGetCalendarAvailability({ enabled: open });

    const { colleagues } = useGetColleagues({ enabled: open });

    const contextForm = useForm<MeetingFormType>({
        resolver: zodResolver(meetingFormSchema),
        defaultValues: defaultMeetingValue
    });

    const currentUser = isAuth();
    const summary = contextForm.watch('summary');
    const date = contextForm.watch('date');
    const formatDate = dayjs(date).format('YYYY-MM-DD');
    const times: any = (availability?.[formatDate] || []) as any
    const duration = contextForm.watch('duration');
    const medium = contextForm.watch('medium');
    const assignUser = contextForm.watch('assignUser')
    const isFirstStep = step === 'select-date';

    const {
        integrations: availabilityIntegrations,
        isLoading: isGettingAvailability
    } = useGetAvailabilityMeetingIntegrations({ agent: assignUser || '', enabled: open });

    useEffect(() => {
        let mediumToUse = 'person';

        if (client.email) {
            const integration = Object.entries(availabilityIntegrations).find(([__, value]) => value);
            mediumToUse = integration?.[0] || 'person';
        };

        contextForm.setValue('medium', mediumToUse as MeetingFormType['medium']);
    }, [availabilityIntegrations]);

    useEffect(() => {
        if (open) {
            let notifications: any = _.get(isAuth(), 'meetingSettings.reminders', []);

            if (notifications.length == 0) {
                notifications = [{
                    unitAndNumber: { number: 30, unit: 'minutes' },
                    messageContent: { message: '' }
                }]
            };

            const clientFullName = [client.firstName, client.lastName].filter(Boolean).join(' ');

            contextForm.reset({
                ...defaultMeetingValue,
                summary: `Reunión con ${clientFullName}`,
                medium: 'person',
                assignUser: 'me',
                notifications
            });
        }
    }, [open])

    useEffect(() => {
        const nextDateAvailable = Object.keys(availability)[0];
        contextForm.setValue('date', (nextDateAvailable ? dayjs(nextDateAvailable).toDate() : null) as any);
    }, [availability])

    const allUsersToMap = useMemo(() => {
        return _.keyBy([{
            _id: 'me',
            name: currentUser?.name,
            lastname: currentUser?.lastname,
            photo: currentUser?.photo,
            email: currentUser?.email
        }, ...colleagues], '_id');
    }, [colleagues])

    const disabledDays = useMemo(() => {
        const startMonth = dayjs(calendarDate).startOf('month');
        const endMonth = dayjs(calendarDate).endOf('month');
        const unavailableDays: string[] = [];

        for (let i = startMonth; i.isBefore(endMonth); i = i.add(1, 'day')) {
            const formatedDate = i.format('YYYY-MM-DD');
            if (!availability[formatedDate]) {
                unavailableDays.push(formatedDate);
            }
        }

        return unavailableDays
    }, [availability, calendarDate]);

    const { mutate: mutateMeetingLink, isPending: isGeneretingLink } = useMutation({
        mutationFn: (values: CreateCalendarEventParams) => createMeetingLink(values),
        onSuccess: ({ link }) => {
            contextForm.setValue('meetingLink', link);
        },
        onError: () => {
            toast({
                variant: 'destructive',
                title: 'Error al crear el enlace de la reunión',
                description: 'Ocurrió un problema al intentar generar el enlace de la reunión. Intenta nuevamente. Si el problema persiste, asegúrate de que el organizador tenga su cuenta de Google Calendar integrada con la plataforma.'
            })
        }
    });

    const { mutate, isPending: isCreatingMeeting } = useMutation({
        mutationFn: (values: MeetingFormType) => createMeeting(client.id, values),
        onError: toastActionError,
        onSuccess: () => {
            toast({
                title: 'Reunión creada',
                description: 'La reunión se ha creado correctamente.',
                variant: 'success'
            });

            onOpenChange(false);
        }
    })

    const handleBackStep = () => {
        setStep('select-date');
        contextForm.setValue('meetingLink', '');
        contextForm.setValue('attendees', []);
    }

    const handleSelectTime = (time: string) => {
        let meetingDate = new Date(date);
        const isInstant = time === 'instant';
        setIsInstant(isInstant);

        if (isInstant) {
            meetingDate = new Date();
        } else {
            const timeToDate = new Date(time);
            meetingDate.setHours(timeToDate.getHours());
            meetingDate.setMinutes(timeToDate.getMinutes());
        };

        contextForm.setValue('date', meetingDate);
        setStep('confirm');

        if (medium !== 'person') {
            mutateMeetingLink({
                summary: summary,
                medium,
                duration: {
                    value: duration == '30min' ? 30 : 60,
                    unit: 'minutes'
                },
                startDate: meetingDate.toISOString(),
                agent: (assignUser == 'me' || !assignUser)
                    ? currentUser._id
                    : assignUser
            });
        }
    }

    const onSubmit = (values: MeetingFormType) => {
        if (isInstant) {
            values.reminders = [];
            values.notifications = [];
        }

        mutate(values);
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                className={`${isFirstStep ? 'sm:max-w-[1000px]' : 'sm:max-w-[800px]'} duration-100 h-[95vh] overflow-y-auto md:h-[500px] p-0`}
                showCloseButton={false}
            >
                <Form {...contextForm}>
                    <form
                        onSubmit={contextForm.handleSubmit(onSubmit)}
                        className={`flex flex-col md:grid w-full ${isFirstStep ? 'md:grid-cols-11' : 'md:grid-cols-8'}`}
                        id="meeting-form"
                    >
                        <div className="md:col-span-3 md:border-r border-b md:border-b-0 p-4">
                            <CustomFormField
                                name="assignUser"
                                control={contextForm.control}
                                fnElement={({ field }) => (
                                    <AssignUserSelector
                                        colleagues={colleagues}
                                        currentUser={currentUser}
                                        usersMap={allUsersToMap}
                                        disabled={!isFirstStep}
                                        value={field.value}
                                        onChange={(value) => {
                                            onChangeAvailabilityParams({ agent: value });
                                            field.onChange(value)
                                            contextForm.setValue('date', null as any);
                                        }}
                                    />
                                )}
                            />

                            <h2 className="text-[19px] px-2 mt-3 font-semibold text-gray-800">
                                {summary}
                            </h2>

                            <div className="mt-2 grid gap-2">
                                {
                                    isGettingAvailability
                                        ? <Skeleton className="w-[122px] rounded-[2px] h-3.5" />
                                        : (
                                            <CustomFormField
                                                name="medium"
                                                control={contextForm.control}
                                                fnElement={({ field }) => (
                                                    <GenericCombobox
                                                        buttonClassName="text-gray-800 text-[14px] px-2 h-7"
                                                        options={MEETING_MEDIUMS.map((medium) => {

                                                            if (medium.value !== 'person') {
                                                                medium.disabled = !availabilityIntegrations[medium.value] || !client.email
                                                            };

                                                            return medium;
                                                        })}
                                                        placeholder="Seleccionar"
                                                        disabled={!isFirstStep}
                                                        noResultsText="Sin resultados"
                                                        searchPlaceholder="Buscar..."
                                                        onChange={field.onChange}
                                                        value={field.value}
                                                    />
                                                )}
                                            />
                                        )
                                }
                                {
                                    isFirstStep
                                        ? <CustomFormField
                                            name="duration"
                                            control={contextForm.control}
                                            fnElement={({ field }) => (
                                                <GenericCombobox
                                                    buttonClassName="text-gray-800 text-[14px] px-2 h-7"
                                                    options={DURATIONS_OPTIONS}
                                                    placeholder="Seleccionar"
                                                    noResultsText="Sin resultados"
                                                    disabled={!isFirstStep}
                                                    searchPlaceholder="Buscar..."
                                                    value={field.value}
                                                    onChange={(value) => {
                                                        onChangeAvailabilityParams({ eventTypeSlug: value as '30min' });
                                                        field.onChange(value);
                                                    }}
                                                />
                                            )}
                                        />
                                        : <div className="flex items-start px-2 gap-2">
                                            <Clock2 size={17} className="mt-1" />
                                            <div className="grid">
                                                <span className="text-gray-800 text-[14px] font-medium">
                                                    {`${dayjs(date).format('HH:mm')} - ${dayjs(date).add(duration === '30min' ? 30 : 60, 'minutes').format('HH:mm')}`}
                                                </span>
                                                <span className="text-[13px] text-gray-600">
                                                    <span className="capitalize">
                                                        {dayjs(date).format('dddd')}
                                                    </span>
                                                    {` ${dayjs(date).format('D [de]')} `}
                                                    <span className="capitalize">
                                                        {dayjs(date).format('MMMM')}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="md:col-span-5 md:border-b-0 border-b">
                            {
                                (isFirstStep) ? (
                                    <div className="p-4">
                                        <CustomFormField
                                            name="date"
                                            control={contextForm.control}
                                            fnElement={({ field }) => (
                                                <MonthlyViewCalendar
                                                    disableDeselectDate
                                                    currentDate={calendarDate}
                                                    disabledDays={disabledDays}
                                                    loading={isLoadingGetAvailability}
                                                    selectedDates={field.value ? [dayjs(field.value).format('YYYY-MM-DD')] : []}
                                                    onChangeValue={(value) => {
                                                        field.onChange(dayjs(value[value.length - 1], 'YYYY-MM-DD').toDate());
                                                    }}
                                                    onChangeDate={(value) => {
                                                        setCalendarDate(value);
                                                        onChangeAvailabilityParams({
                                                            startTime: dayjs(value).startOf('month').toISOString(),
                                                            endTime: dayjs(value).endOf('month').toISOString()
                                                        });
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                ) : <ConfirmStep
                                    isGeneretingLink={isGeneretingLink}
                                    onBackStep={handleBackStep}
                                    contextForm={contextForm}
                                    currentUser={currentUser}
                                    isCreatingMeeting={isCreatingMeeting}
                                    colleagues={colleagues}
                                    usersMap={allUsersToMap}
                                    isInstant={isInstant}
                                    client={client}
                                />
                            }
                        </div>
                        {
                            (isFirstStep) && (
                                <ListTime
                                    isLoadingGetAvailability={isLoadingGetAvailability}
                                    onChangeFormatDate={(value) => setFormatHour(value)}
                                    isGettingAvailability={isGettingAvailability}
                                    onSelectTime={handleSelectTime}
                                    formatHour={formatHour}
                                    dateSelected={date}
                                    times={times}
                                />
                            )
                        }
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}