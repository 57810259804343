
import { LifeBuoy } from "lucide-react"
import "moment/locale/es"
import { useEffect, useState } from "react"
import ChatSoporte from "../ChatSoporte"
import NavLink from "./NavLink"

interface SupportItemProps {
    isCollapsedMenu: boolean;
}

export default function SupportItem({ isCollapsedMenu }: SupportItemProps) {
    const [focusChat, setFocusChat] = useState(false);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

    const handleToggleSupport = () => {
        setFocusChat((prev) => !prev);

        // @ts-ignore
        if (window && window.Intercom) {
            if (focusChat) {
                // @ts-ignore
                window.Intercom('hide');
            } else {
                // @ts-ignore
                window.Intercom('show');
            }
        }
    };

    useEffect(() => {
        // @ts-ignore
        if (window && window.Intercom) {
            // @ts-ignore
            window.Intercom('onUnreadCountChange', (count: number) => {
                console.log('INTERCOME MESSAGES COUNT:', count);
                setUnreadMessagesCount(count);
            });

            return () => {
                // @ts-ignore
                window.Intercom('offUnreadCountChange');
            };
        }
    }, []);

    return (
        <>
            <ChatSoporte focus={focusChat} />
            <NavLink
                classNameNotificationItem="bg-primary text-white"
                notification={unreadMessagesCount}
                isCollapsedMenu={isCollapsedMenu}
                onAction={handleToggleSupport}
                icon={<LifeBuoy size={17} />}
                href="javascript:void(0)"
                label="Soporte"
            />
        </>
    );
}

