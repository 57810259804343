import { TReminderData } from "@/components/crm/BottomActions/Reminder/constant";
import { MoveToInboxFormSchemaType } from "@/components/crm/MoveToInboxContactDialog";
import { MeetingFormType } from "@/components/MeetingModal/constants";
import { fetcher } from "@/helpers/fetcher";
import { formatSearchParams } from "@/helpers/formatSearchParams";
import { EntityResult, ScheduleMessageContent } from "@/interfaces/Interface";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import fetch from "isomorphic-fetch";
import { getMedium } from "../../components/channels/helpers/hooks/useChannels";
import { Contact, ScheduleMessageStatus } from "../../interfaces/crm/contact";
import { getCompany, getCookie } from "../auth";

const API = process.env.API;
// const API_WPP = "https://api.getventia.com";
const API_NEXT = process.env.API_NEXT
const API_WPP = process.env.API_WPP ?? "https://api.getventia.com";

export const create = (contact: Contact) => {
  return fetch(`${API}/crm/contact`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(contact),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getContactsByUser = ({
  type,
  search = "",
  limit = 0,
  page = 0,
  lastDate,
  actAs,
  archiveFilter,
  extraFilters,
}) => {
  let useArchiveFilter = ""

  if (type == "closed" && archiveFilter != "all")
    useArchiveFilter = `&archivingReason=${archiveFilter}`

  Object.keys(extraFilters).forEach((key) => {
    if (extraFilters[key] === "") {
      useArchiveFilter += `&no_${key}=true`
    } else if (extraFilters[key] === undefined) {
    } else {
      useArchiveFilter += `&${key}=${extraFilters[key]}`
    }
  })

  return fetch(
    `${API_NEXT}/crm/contacts?user=${actAs}${type ? `&listType=${type}` : ''}${useArchiveFilter}&limit=${limit}&page=${page}${lastDate ? (type == "reminder"
      ? `&gtReminderDate=${lastDate}`
      : `&ltLastInteractionDate=${lastDate}`
    ) : ''
    }${search && search != "" ? `&search=${search}` : ``
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + getCookie("token"),
      },
    }
  )
    .then((res) => res.json())
    .catch((err) => console.log(err))
}

export const createNewMeeting = ({ body, contactId, query = {} }) => {
  const queryParams = formatSearchParams(query)
  return fetcher<{ events: Array<any> }>({
    url: `/crm/contact/meeting?id=${contactId}&${queryParams}`,
    defaultContentType: true,
    method: 'POST',
    body: {
      via: 'web',
      ...body,
    }
  })
}

export const createVisitInteraction = ({ id, body, query }) => {
  const queryParams = formatSearchParams(query)

  return fetcher<{ events: Array<any> }>({
    url: `/crm/contact/${id}/visit?${queryParams}`,
    defaultContentType: true,
    method: 'POST',
    body: {
      ...body
    },
  })
}

export const upadteMeetingStatus = (body, meetingId, contactId) => {
  const url = `${API_NEXT}/crm/contact/meeting/${contactId}/${meetingId}`

  return fetch(url, {
    method: 'PATCH',
    headers: {
      "Authorization": "JWT " + getCookie("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body)
  })
    .then(res => res.json())
    .catch(e => console.log(e))
}

export const whatsappIsOnline = () => {
  return fetch(`${API_WPP}/crm/whatsapp/online`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const WMPIsOnline = () => {
  return fetch(`${API_NEXT}/whatsappmanager/personal/start`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const desasociarWhatsapp = () => {
  return fetch(`${API_WPP}/crm/whatsapp/remove`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const WMPdesasociar = () => {

  return fetch(`${API_NEXT}/whatsappmanager/personal/reset`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
}

export const getFirstUnassigned = (origin: string | string[]) => {
  return fetcher<any>({
    url: `/contacts/unassigned?origin=${[origin].flat().join(',')}`,
    defaultContentType: true
  })
};

export const getUnassignedCount = () => {
  return fetcher<{ count: number, origins: { _id: string, count: number }[] }>({
    url: '/contacts/unassigned/count',
    defaultContentType: true
  })
};

export const fetchContactsMarkers = (body, configQuery) => {
  let querystring: any = [];
  Object.keys(configQuery).map((k) => {
    if ((configQuery[k] || configQuery[k] == 0) && configQuery[k][0] != "all")
      return querystring.push(`${k}=${encodeURI(configQuery[k])} `);
  });

  return fetcher({
    url: `${API}/crm/contacts/map?${querystring.join("&")}`,
    defaultContentType: true,
    isExternalURL: true,
    method: 'POST',
    body
  })
};


// export const fetchContactsMarkers = (body, configQuery) => {
//   
//   let querystring: any = [];

//   Object.keys(configQuery).map((k) => {
//     if ((configQuery[k] || configQuery[k] == 0) && configQuery[k][0] != "all")
//       return querystring.push(`${k}=${encodeURI(configQuery[k])} `);
//   });


// };

export const sendChannelMessage = async (
  id,
  action: string,
  message,
  template,
  channelid,
  replyMessage,
  query = {}
) => {
  const queryParams = formatSearchParams(query)

  const medium = getMedium(action)
  const objectForm: { [key: string]: any } = Object.fromEntries(Object.entries({
    via: "web",
    message: message,
    tag: template?.tag ?? undefined,
    medium,
    channelid,
    replyMessage
  }).filter(([_, value]) => !!value));

  if (template?._id) objectForm.template = template._id;

  return fetch(`${API_WPP}/crm/contact/${id}/channelMessage?${queryParams}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(objectForm),
  })
    .then((response) => {
      return response.json();
    })
};

export const sendChannelMultimediaMessage = async (
  formdata: any,
  id: string,
  query = {}
) => {
  const queryParams = formatSearchParams(query)

  return fetch(`${API_WPP}/crm/contact/${id}/channelMessage/multimedia?${queryParams}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      ...formdata,
      "via": "web"
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendUnofficialWhatsappMessage = async (
  id: string,
  text: string,
  replyMessage: string,
  query: Record<string, any> = {}
) => {
  const queryParams = formatSearchParams(query)

  return fetch(`${API_WPP}/crm/whatsapp/${id}/send?${queryParams}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      text,
      replyMessage,
    }),
  })
    .then((response) => {
      return response.json();
    })
};

export const sendPersonalWhatsappOnBehalf = (
  agent: string,
  contact: string,
  message: { text: string, replyMessage?: string },
  query: Record<string, any> = {}
) => {
  const queryParams = formatSearchParams(query);

  return fetcher({
    url: `${API_WPP}/crm/whatsapp/${contact}/onbehalf/${agent}?${queryParams}`,
    defaultContentType: true,
    isExternalURL: true,
    method: 'POST',
    body: message
  })
}

export const sendPersonalWhatsappOnBehalfMultimedia = (
  agent: string,
  contact: string,
  body: Record<string, any>,
  query: Record<string, any> = {}
) => {
  const queryParams = formatSearchParams(query);

  return fetcher({
    url: `${API_WPP}/crm/whatsapp/${contact}/onbehalf/${agent}/multimedia?${queryParams}`,
    defaultContentType: true,
    isExternalURL: true,
    method: 'POST',
    body
  })
}

export const sendUnofficialServerlessWhatsappMessage = async (id, text) => {
  return fetch(`${API_NEXT}/crm/contact/${id}/personalwhatsapp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      text,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendUnofficialWhatsappMultimediaMessage = async (
  formdata: any,
  id: string,
  query: Record<string, any> = {}
) => {
  const queryParams = formatSearchParams(query)
  return fetch(`${API_WPP}/crm/whatsapp/${id}/send/multimedia?${queryParams}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(formdata),
  })
    .then((response) => response.json())
};

export const sendServerlessWhatsappMultimediaMessage = async (
  formdata: any,
  id: string
) => {
  return fetch(`${API_NEXT}/crm/contact/${id}/personalwhatsapp/multimedia`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(formdata),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendQRUnofficialWhatsappMessage = async (id, channelid, text) => {
  return fetch(`${API_WPP}/crm/channel/qrwhatsapp/${channelid}/send/${id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      text,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

interface mutateVisit {
  id: string;
  visit: { state: string; comment: string; tag: string };
}
export const useCreateVisit = () => {
  return useMutation({
    mutationFn: ({ id, visit }: mutateVisit) => {
      return fetch(`${API_NEXT}/crm/contact/${id}/visit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "JWT " + getCookie("token"),
        },
        body: JSON.stringify({
          via: "web",
          ...visit,
        }),
      })
        .then(async (response) => {
          return response.json();
        })
        .catch((err) => err);
    }
  })
};

export const archive = (id: string, reason: string, query = {}) => {
  const queryParams = formatSearchParams(query)

  return fetcher<{ archive: Record<string, any>, events: Array<any>, error?: string }>({
    url: `/crm/contact/${id}/archiving?${queryParams}`,
    method: 'POST',
    defaultContentType: true,
    body: {
      via: "web",
      reason
    }
  })
};

export const uploadContacts = (body) => {
  return axios.post(`${API}/crm/uploadContacts`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  });
};

export const uploadContactPreview = (form: FormData) => {
  return axios.post(`${API}/crm/uploadContactsPreview`, form, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  });
};

export const remind = (id, reminderDate) => {
  return fetch(`${API}/crm/contact/${id}/reminder`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
      reminderDate,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const cancelreminder = (id) => {
  return fetcher<{ success: boolean, events: any[] }>({
    url: `/crm/contact/${id}/cancelreminder`,
    defaultContentType: true,
    method: 'POST',
    body: {
      via: "web",
    }
  })
};

export const transfer = (
  id: string,
  to: string,
  description: string = '',
  keepReminderAndArchivingReason: boolean = false
) => {
  return fetcher<any>({
    method: 'POST',
    defaultContentType: true,
    url: `/crm/contact/${id}/transfer`,
    body: {
      keepReminderAndArchivingReason,
      description,
      via: "web",
      to
    }
  })
};

export const transferMasive = (from, to, keepContactStatus) => {
  return fetcher<any>({
    url: '/crm/interaction/transfermasive',
    defaultContentType: true,
    body: {
      from,
      to,
      changeStatusToActive: keepContactStatus
    },
    method: 'POST'
  })
};

export const note = (id, body, isWhatsapp, localId, socketIgnore, files, timezone) => {
  return fetch(`${API}/crm/contact/${id}/note${localId ? `?localId=${localId}&socketIgnore=${socketIgnore}` : ''}&timezone=${timezone}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
      body,
      channel: isWhatsapp ? "whatsapp" : undefined,
      files: files || []
    }),
  })
    .then((response) => response.json())
};

export const callPhone = (id: string, phone: string, query: Record<string, any>) => {
  const queryParams = formatSearchParams(query)

  return fetcher<{ events: Array<any> }>({
    url: `/crm/contact/${id}/phonecall?${queryParams}`,
    defaultContentType: true,
    method: 'POST',
    body: {
      via: "web",
      phone
    }
  })
};

export const closeCallPhone = (
  id: string,
  interactionId: string,
  state: "calling" | "done" | "cancel" | "voicemail",
  query = {}
) => {
  const queryParams = formatSearchParams(query)

  return fetcher<{ events: Array<any> }>({
    method: 'PUT',
    url: `/crm/contact/${id}/closephonecall/${interactionId}?${queryParams}`,
    defaultContentType: true,
    body: {
      via: "web",
      state,
    },
  })
};

export const callByExternalApi = (id: string, provider: string, providerId?: string) => {
  return fetcher<{ events: Array<any> }>({
    url: `/crm/contact/${id}/phone_exchange/call?providerId=${providerId}`,
    defaultContentType: true,
    method: 'POST',
    body: {
      via: "web",
      provider: provider
    }
  })
};

export const update = (id, contact: Partial<Contact>, fastUpdate: '0' | '1' = '0') => {
  return fetch(`${API_NEXT}/crm/contact/${id}?fastUpdate=${fastUpdate}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(contact),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const get = (id, query = {}) => {
  const queryParmas = formatSearchParams(query);
  
  return fetch(`${API_NEXT}/crm/contact/${id}?${queryParmas}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => response.json())
};

export const getInteractionsContact = (id, date) => {
  return fetch(`${API}/crm/contact/${id}/chatsfrom/${date}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


export const deleteContacts = (list) => {
  return fetch(`${API}/crm/deleteContacts`, {
    method: "POST",
    body: JSON.stringify(list),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const transferToInbox = (body: MoveToInboxFormSchemaType) => {
  return fetcher({
    url: '/crm/transferInbox',
    body,
    method: 'POST',
    defaultContentType: true
  })
};

export const getContactList = (
  type,
  search = "",
  limit = 10,
  page = 0,
  lastDate: Date | false = false,
  archiveFilter: string,
  extraFilters: { [filter: string]: string }
) => {
  let useArchiveFilter = "";
  if (type == "closed" && archiveFilter != "all")
    useArchiveFilter = `&archivingReason=${archiveFilter}`;
  Object.keys(extraFilters).forEach((key) => {
    if (extraFilters[key] === "") {
      useArchiveFilter += `&no_${key}=true`;
    } else if (extraFilters[key] === undefined) {
    } else {
      useArchiveFilter += `&${key}=${extraFilters[key]}`;
    }
  });

  return fetch(
    `${API}/crm/contact/withLastInteractions?listType=${type}${useArchiveFilter}&limit=${limit}&page=${page}${lastDate && type == "reminder"
      ? "&gtReminderDate="
      : "&ltLastInteractionDate="
    }${lastDate ? lastDate : ""}${search && search != "" ? `&search=${search}` : ``
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + getCookie("token"),
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getContactAvanced = (
  search = "",
  limit = 10,
  page = 0,
  filters
) => {
  let filterString = "";
  if (
    filters.origin &&
    !filters.origin.includes("all") &&
    filters.origin.length > 0
  )
    filterString += "&origin=" + encodeURIComponent(filters.origin.join(","));
  if (
    filters.archivingReason &&
    !filters.archivingReason.includes("all") &&
    filters.archivingReason.length > 0
  )
    filterString +=
      "&archivingReason=" +
      encodeURIComponent(filters.archivingReason.join(","));
  if (
    filters.status &&
    !filters.status.includes("all") &&
    filters.status.length > 0
  )
    filterString += "&estado=" + encodeURIComponent(filters.status.join(","));
  if (filters.user && !filters.user.includes("all") && filters.user.length > 0)
    filterString += "&agents=" + encodeURIComponent(filters.user.join(","));
  if (filters.sortModel && filters.sortModel.length > 0) {
    if (filters.sortModel[0].sort == "desc") {
      filterString +=
        "&sort=" + encodeURIComponent("-" + filters.sortModel[0].field);
    } else {
      filterString +=
        "&sort=" + encodeURIComponent("+" + filters.sortModel[0].field);
    }
  }
  if (filters.createdAt && filters.createdAt.gt)
    filterString += "&gtCreatedAt=" + filters.createdAt.gt;
  if (filters.createdAt && filters.createdAt.lt)
    filterString += "&ltCreatedAt=" + filters.createdAt.lt;

  if (filters.lastInteraction && filters.lastInteraction.gt)
    filterString += "&gtLastInteraction=" + filters.lastInteraction.gt;
  if (filters.lastInteraction && filters.lastInteraction.lt)
    filterString += "&ltLastInteraction=" + filters.lastInteraction.lt;

  return fetch(
    `${API}/crm/contact/advanced?limit=${limit}&page=${page}${filterString}${search && search != "" ? `&search=${encodeURIComponent(search)}` : ``
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + getCookie("token"),
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const downloadContact = (search = "", limit = 10, page = 0, filters) => {
  let filterString = "";
  if (
    filters.origin &&
    !filters.origin.includes("all") &&
    filters.origin.length > 0
  )
    filterString += "&origin=" + encodeURIComponent(filters.origin.join(","));
  if (
    filters.archivingReason &&
    !filters.archivingReason.includes("all") &&
    filters.archivingReason.length > 0
  )
    filterString +=
      "&archivingReason=" +
      encodeURIComponent(filters.archivingReason.join(","));
  if (
    filters.status &&
    !filters.status.includes("all") &&
    filters.status.length > 0
  )
    filterString += "&estado=" + encodeURIComponent(filters.status.join(","));
  if (filters.user && !filters.user.includes("all") && filters.user.length > 0)
    filterString += "&agents=" + encodeURIComponent(filters.user.join(","));
  if (filters.sortModel && filters.sortModel.length > 0) {
    if (filters.sortModel[0].sort == "desc") {
      filterString +=
        "&sort=" + encodeURIComponent("-" + filters.sortModel[0].field);
    } else {
      filterString +=
        "&sort=" + encodeURIComponent("+" + filters.sortModel[0].field);
    }
  }
  if (filters.createdAt && filters.createdAt.gt)
    filterString += "&gtCreatedAt=" + filters.createdAt.gt;
  if (filters.createdAt && filters.createdAt.lt)
    filterString += "&ltCreatedAt=" + filters.createdAt.lt;

  return axios({
    url: `${API}/crm/contact/advanced?limit=${limit}&downloadExcel=true&page=${page}${filterString}${search && search != "" ? `&search=${encodeURIComponent(search)}` : ``
      }`,
    method: "GET",
    responseType: "blob", // important
    headers: {
      Authorization: "JWT " + getCookie("token"),
    },
  });
};

export const generateDownloadContact = (
  search = "",
  limit = 10,
  page = 0,
  filters
) => {
  let filterString = "";
  if (
    filters.origin &&
    !filters.origin.includes("all") &&
    filters.origin.length > 0
  )
    filterString += "&origin=" + encodeURIComponent(filters.origin.join(","));
  if (
    filters.archivingReason &&
    !filters.archivingReason.includes("all") &&
    filters.archivingReason.length > 0
  )
    filterString +=
      "&archivingReason=" +
      encodeURIComponent(filters.archivingReason.join(","));
  if (
    filters.status &&
    !filters.status.includes("all") &&
    filters.status.length > 0
  )
    filterString += "&estado=" + encodeURIComponent(filters.status.join(","));
  if (filters.user && !filters.user.includes("all") && filters.user.length > 0)
    filterString += "&agents=" + encodeURIComponent(filters.user.join(","));
  if (filters.sortModel && filters.sortModel.length > 0) {
    if (filters.sortModel[0].sort == "desc") {
      filterString +=
        "&sort=" + encodeURIComponent("-" + filters.sortModel[0].field);
    } else {
      filterString +=
        "&sort=" + encodeURIComponent("+" + filters.sortModel[0].field);
    }
  }
  if (filters.createdAt && filters.createdAt.gt)
    filterString += "&gtCreatedAt=" + filters.createdAt.gt;
  if (filters.createdAt && filters.createdAt.lt)
    filterString += "&ltCreatedAt=" + filters.createdAt.lt;

  return {
    url: `${API}/crm/contact/advanced?limit=${limit}&downloadExcel=true&page=${page}${filterString}${search && search != "" ? `&search=${encodeURIComponent(search)}` : ``
      }`,
    method: "GET",
    responseType: "blob", // important
    authorization: "JWT " + getCookie("token"),
  };
};


export const sendEmailToProspects = ({ body, contactId, idProvider, scheduleDate }) => {
  const searchParams = formatSearchParams({ idProvider, scheduleDate });
  const url = `/crm/contact/${contactId}/mailV3?${searchParams}`;

  return fetcher({
    defaultContentType: true,
    method: 'POST',
    body,
    url
  })
};

export const postFileToServer = ({ body, idProvider }) => {
  const url = `${API_NEXT}/nylas/file?idProvider=${idProvider}`

  return fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": "JWT " + getCookie("token"),
    },
    body
  })
    .then(res => res.json())
    .catch(e => console.log(e))
}

export const getThreadEmails = (threadId) => {
  const url = `${API_NEXT}/crm/contact/mail?threadId=${threadId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": "JWT " + getCookie("token"),
    },
  })
    .then(res => res.json())
    .catch(e => console.log(e))
}

export const getFiles = (providerId, filesId) => {
  const url = `${API_NEXT}/nylas/file?providerId=${providerId}&filesId=${filesId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": "JWT " + getCookie("token"),
    },
  })
    .then(res => res.json())
    .catch(e => console.log(e))
}

export const downloadFiles = (providerId, fileId) => {
  const url = `${API_NEXT}/nylas/fileDownload?idProvider=${providerId}&fileId=${fileId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": "JWT " + getCookie("token"),
    },
  })
    .then(res => res.blob())
    .catch(e => console.log(e))
}

export const takeContactFromOther = (id, contactId) => {
  return fetch(`${API_NEXT}/crm/contact/${id}/takeContactFromOther/${contactId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token")
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
}

export const createCloneContact = ({ body, id }: { body: { agent: string, description: string, }, id: string }) => {
  return fetcher<any>({
    url: `/crm/contact/${id}/clone`,
    defaultContentType: true,
    method: 'POST',
    body: {
      via: 'web',
      ...body
    }
  })
}

export const createScheduleMessage = (
  id: string,
  body: ScheduleMessageContent,
  onbehalf?: string,
  params: Record<string, any> = {}
) => {
  const queryParams = formatSearchParams({ onbehalf, ...params });

  return fetcher<{ success: boolean, events: Array<any> }>({
    url: `/crm/contact/${id}/scheduledmessage?${queryParams}`,
    defaultContentType: true,
    method: 'POST',
    body: {
      ...body,
      via: 'web'
    }
  })
}

export const changeScheduleMessage = (id: string, status: ScheduleMessageStatus) => {

  return fetcher<{ success: boolean, events: Array<any> }>({
    url: `/crm/interaction/scheduledmessage/${id}/changestatus?status=${status}`,
    method: 'PUT',
    defaultContentType: true
  })
}

export const createMassiveReminder = (body: { contacts: string[], data: TReminderData }) => {
  return fetcher({
    url: `/crm/massiveaction/reminder`,
    method: "POST",
    defaultContentType: true,
    body: {
      ...body,
      via: 'web'
    }
  })
}

export const getDisregardedCount = (user?: string) => {
  return fetcher<{ countDocuments: number }>({
    url: `/contacts/disregarded/count?user=${user}`,
    defaultContentType: true,
    method: 'GET'
  })
}

export const changeOrigin = (contactId: string, { origin, originText }: { originText: string, origin: string }) => {
  const searchParams = new URLSearchParams({ contactId, origin, originText })
  return fetcher({
    url: `/roles/projectmanager/changecontactorigin?${searchParams.toString()}`,
    defaultContentType: true,
    method: "PUT"
  })
}

export const changeAffiliatedCompanies = (
  companies: Record<string, any>,
  contactId: string
) => {
  return fetcher<any>({
    method: 'PATCH',
    defaultContentType: true,
    url: `/crm/contact/${contactId}/companyGroup`,
    body: {
      companyGroups: companies
    }
  })
};

export const generateUContactCallTracing = (call) => {
  return fetcher({
    url: '/crm/generateCallTracing',
    method: 'POST',
    body: {
      call,
      company: getCompany()?._id,
    },
    defaultContentType: true
  })
};

export const deleteWhatsAppMessage = (contactId: string, id: string) => {
  return fetcher({
    url: `${API_WPP}/crm/contact/${contactId}/whatsAppMessage/${id}`,
    defaultContentType: true,
    method: 'DELETE',
    isExternalURL: true,
    withToken: true,
    isApi: true
  })
};

export const editWhatsAppMessage = (contactId: string, id: string, message: string) => {
  return fetcher({
    url: `${API_WPP}/crm/contact/${contactId}/whatsAppMessage/${id}`,
    defaultContentType: true,
    isExternalURL: true,
    withToken: true,
    method: 'PATCH',
    body: {
      message: {
        type: 'text',
        text: message
      }
    }
  })
};

export const deepSearchEntities = (filters: { [key: string]: any } = {}) => {
  const searchParams = formatSearchParams(filters);

  return fetcher<EntityResult>({
    url: `/crm/deepSearchEntities?${searchParams}`,
    method: 'GET'
  })
};

export const updateContactBotStatus = (contactId: string, status: boolean) => {
  return fetcher({
    url: `/crm/contact/${contactId}/bot/disable`,
    method: 'PATCH',
    defaultContentType: true,
    body: {
      isActive: status
    }
  })
}

export const unifyContact = (contactId: string, contactToUnify: string) => {
  return fetcher<{ success: boolean, contactBaseId: string }>({
    url: `/crm/contact/${contactId}/unify/${contactToUnify}`,
    method: 'POST',
    defaultContentType: true
  })
}

export const doneSuggestion = (contactId: string, interactionId: string, index: number) => {
  return fetcher({
    url: `/crm/contact/${contactId}/suggestions/done?interaction=${interactionId}&index=${index}`,
    defaultContentType: true,
    method: 'PUT'
  })
}

export const createMeeting = (id: string, body: MeetingFormType) => {
  return fetcher<{ success: boolean }>({
    url: `/crm/contact/${id}/meeting`,
    defaultContentType: true,
    method: 'POST',
    body: {
      ...body,
      via: 'web'
    }
  })
}