import { isAuth } from "@/actions/auth"
import { CustomFormField } from "@/components/ui/custom-form-field"
import {
  FormControl
} from "@/components/ui/form"
import MultipleSelector from "@/components/ui/multiselector"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import dayjs from "dayjs"
import { UseFormReturn } from "react-hook-form"
import InputDate from "../Modal/InputDate"
import AdditionalDataAutoComplete from "./AdditionalDataAutoComplete"
import { CreateContactFormType } from "./CreateContactDrawer"
import ValidateUniqueValueInput from "./ValidateUniqueValueInput"

interface AditionalDataContactProps {
  handleAdditioanlData?: (c: any, value: string) => void
  id: string
  control: any
  additionalDataFields: Array<any>
  requiredOnSale?: boolean
  getAdditionalData?: (name: string) => string;
  contextForm: UseFormReturn<CreateContactFormType>;
  onClose?: () => void;
  markAsUpdatedFields?: () => void
}

export function AditionalDataContact({
  id,
  control,
  requiredOnSale,
  additionalDataFields = [],
  getAdditionalData,
  markAsUpdatedFields,
  handleAdditioanlData,
  contextForm,
  onClose
}: AditionalDataContactProps) {

  return <>
    {
      additionalDataFields
        .filter(
          (f) =>
            f.active &&
            (!f.disableForUsers ||
              isAuth().roles.includes("super") ||
              isAuth().roles.includes("comercial")) &&
            (!f.onlyRead || (id !== "")) &&
            (requiredOnSale == true ? f.requiredOnSale : true)
        )
        .map((f) => {
          const placeholder = f.placeholder || f.name
          switch (f.type) {
            case "string":
            case "number":
              return (
                <>
                  <CustomFormField
                    key={f.name}
                    name={`additionalData.${f.code}`}
                    label={f.name}
                    control={control}
                    isRequired={f.required}
                    fnElement={({ field }) => (
                      <ValidateUniqueValueInput
                        type={f.type == 'number' ? 'number' : 'text'}
                        onChangeValue={field.onChange}
                        markAsUpdatedFields={markAsUpdatedFields}
                        contextForm={contextForm}
                        placeholder={placeholder}
                        validate={f.uniqueField}
                        onCloseModal={onClose}
                        required={f.required}
                        value={field.value}
                        fieldCode={f.code}
                      />
                    )}
                  />
                </>
              )
            case "select":
              if (f.autocomplete) {
                return <CustomFormField
                  key={f.name}
                  name={`additionalData.${f.code}`}
                  label={f.name}
                  control={control}
                  isRequired={f.required}
                  fnElement={({ field }) => (
                    <AdditionalDataAutoComplete
                      options={f.options}
                      handleChange={(value: string) => {
                        markAsUpdatedFields && markAsUpdatedFields();
                        if (handleAdditioanlData) return handleAdditioanlData(f, value);
                        field.onChange(value);
                      }}
                      label={f.name}
                      defaultValue={field.value}
                    />
                  )}
                />
              }
              return (
                <>
                  <CustomFormField
                    key={f.name}
                    name={`additionalData.${f.code}`}
                    label={f.name}
                    control={control}
                    isRequired={f.required}
                    fnElement={({ field }) => (
                      <Select
                        key={f.name}
                        value={field.value}
                        onValueChange={(value) => {
                          markAsUpdatedFields && markAsUpdatedFields();

                          if (handleAdditioanlData) return handleAdditioanlData(f, value);
                          field.onChange(value);
                        }}
                        defaultValue={getAdditionalData ? getAdditionalData(f.code) : field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={placeholder} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="max-h-[200px]">
                          <SelectGroup>
                            <SelectLabel>{f.name}</SelectLabel>
                            {f.options.map((o) => (
                              <SelectItem key={o} value={o}>
                                {o}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </>
              )
            case 'multiselect':
              return <CustomFormField
                key={f.name}
                name={`additionalData.${f.code}`}
                label={f.name}
                control={control}
                isRequired={f.required}
                fnElement={({ field }) => (
                  <MultipleSelector
                    value={(field.value ?? []).map((v) => ({ label: v, value: v, color: f.options.find((o) => o.name === v)?.color ?? "#fafafa" }))}
                    onChange={(value) => {
                      markAsUpdatedFields && markAsUpdatedFields();
                      if (handleAdditioanlData) return handleAdditioanlData(f, value as any);
                      field.onChange(value.map((v) => v.value))
                    }}
                    defaultOptions={f.options.map(option => ({ label: option.name, value: option.name, color: option.color }))}
                    placeholder={placeholder || 'Selecciona opciones...'}
                    emptyIndicator={
                      <p className="text-center text-md leading-10 text-gray-600 dark:text-gray-400">
                        No se encontraron resultados.
                      </p>
                    }
                  />
                )}
              />
            case 'date': {
              const fieldCode = `additionalData.${f.code}` as `additionalData.${string}`
              return <CustomFormField
                key={f.name}
                name={fieldCode}
                label={f.name}
                control={control}
                isRequired={f.required}
                fnElement={({ field }) => (
                  <InputDate
                    date={field.value ? dayjs(field.value, 'YYYY-MM-DD').toDate() : undefined}
                    errorHandler={false}
                    required={false}
                    handleInvalidDate={(hasError) => {
                      if (hasError) return contextForm.setError(fieldCode, {
                        message: 'La fecha tiene que ser válida con el formato DD/MM/YYYY'
                      })

                      markAsUpdatedFields && markAsUpdatedFields();
                      contextForm.clearErrors(fieldCode);
                    }}
                    setDate={(value) => {
                      field.onChange(value ? dayjs(value).format('YYYY-MM-DD') : undefined);
                    }}
                  />
                )}
              />
            }
            default:
              return <p>No se encontro el campo {f.type}</p>
          }
        })
    }
  </>
}