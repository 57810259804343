"use client"
import { GoogleMeet, Zoom } from "@/components/crm/Bubble/Icons"
import { PersonMeetingIcon } from "@/icons"
import { Clock2, Clock3, Clock9 } from "lucide-react"
import { z } from "zod"

export const meetingFormSchema = z.object({
    summary: z.string({ required_error: 'El asunto de la reunión es requerido' }),
    medium: z.enum(['meet', 'zoom', 'person']),
    date: z.date({ required_error: "Fecha requerida" }),
    comment: z.string().max(2500).optional(),
    assignUser: z.string().optional(),
    timezone: z.string().optional(),
    duration: z.enum(['30min', '1hour']),
    reminders: z.array(z.object({
        unitAndNumber: z.object({
            number: z.number(),
            unit: z.string()
        }).optional()
    })).optional(),
    notifications: z.array(z.object({
        unitAndNumber: z.object({
            number: z.number(),
            unit: z.string()
        }).optional(),
        messageContent: z.object({
            message: z.string()
        }).optional(),
        medium: z.string().optional(),
    })).optional(),
    attendees: z.array(z.object({
        role: z.enum(["organizer", "client", "guest"]).optional(),
        user: z.string().optional(),
        email: z.string().email(),
        name: z.string(),
        lastname: z.string().optional(),
        photo: z.string().optional()
    })),
    meetingLink: z.string().optional(),
});

export type MeetingFormType = z.infer<typeof meetingFormSchema>;

export const defaultMeetingValue: MeetingFormType = {
    date: null,
    summary: "",
    medium: "meet",
    comment: "",
    assignUser: "me",
    duration: '30min',
    timezone: "",
    reminders: [],
    attendees: [],
    notifications: []
} as any

export const MEETING_MEDIUMS = [
    {
        label: <span className="flex items-center gap-2">
            <GoogleMeet />
            Google Meet
        </span>,
        value: 'meet',
        searchableBy: ['Google Meet', 'meet', 'calendar', 'calendario', 'google'],
        disabled: false
    },
    {
        label: <span className="flex items-center gap-2">
            <Zoom />
            Zoom
        </span>,
        value: 'zoom',
        searchableBy: ['Zoom'],
        disabled: false
    },
    {
        label: <span className="flex items-center gap-2">
            <PersonMeetingIcon className="w-[15px] h-[15px]" />
            Presencial
        </span>,
        value: 'person',
        searchableBy: ['Presencial', 'person'],
        disabled: false
    }
]

export const DURATIONS_OPTIONS = [
    {
        label: <span className="flex items-center gap-2">
            <Clock2 size={17} />
            15 min
        </span>,
        value: '15min',
        searchableBy: ['15 minutos']
    },
    {
        label: <span className="flex items-center gap-2">
            <Clock3 size={17} />
            30 min
        </span>,
        value: '30min',
        searchableBy: ['30 minutos']
    },
    {
        label: <span className="flex items-center gap-2">
            <Clock9 size={17} />
            1 hora
        </span>,
        value: '1hour',
        searchableBy: ['1 hora']
    }
]

export const ATTENDES_ROLES = {
    'client': {
        label: 'Cliente',
        color: 'blue'
    },
    'guest': {
        label: 'Invitado',
        color: 'amber'
    }
}
