import { getCompany, isAuth } from "@/actions/auth";
import { Separator } from "@/components/ui/separator";
import { toast } from "@/components/ui/use-toast";
import { Feature, getPersonalFeature, getWordDefinition, GroupWordsDefinitions, isFeatureEnabled, PersonalFeature } from "@/helpers/featureFlagHelpers";
import { useToggle } from "@/hooks/useToggle";
import { MeetingIcon, NoteIcon, VisitIcon } from "@/icons/AppsToreIcons";
import _ from "lodash";
import { Briefcase, Receipt } from "lucide-react";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import IconButtonTitle from "../../core/IconButtonTitle";
import { DialogSeeShortcut } from "../ContactInformationMenu/DialogSeeShortcut";
import RequestSale from "../requestsale/RequestSale";
import { SelectorBottomActions } from "./SelectorBottomActions";
import React from "react";
import AddModal from "@/components/sale/AddSale/addModal";

moment.locale("es");

interface SelectActionInterface {
  phone?: string;
  id?: string;
  contactId: string,
  isFromSharedInbox?: boolean
  interactionid?: string;
  onAction: (action: actions_crm, payload?: any) => void;
  isLead?: boolean;
  action?: actions_crm;
  setOpenUpdateContact: any;
}

export interface IShortcut {
  title: string,
  avatar: {
    type: "icon" | "link",
    url: string,
  },
  url: string,
  redirectionType: "internal" | "external",
}

export type actions_crm =
  | "channel-sms"
  | "note"
  | "whatsapp"
  | "visit"
  | "more"
  | "transfer"
  | "form"
  | "select"
  | "sales"
  | "unofficial-whatsapp"
  | "select-more"
  | "facebook"
  | "instagram"
  | "channel-chat"
  | "channel-select"
  | "whatsapp-note"
  | "email"
  | "unofficial-personal-whatsapp"
  | "meeting";

let badActions = ['whatsapp-note', 'unofficial-whatsapp', "whatsapp"]

const SelectAction = ({
  setOpenUpdateContact,
  onAction,
  action,
  contactId,
  isFromSharedInbox,
}: SelectActionInterface) => {
  const [openRequestSale, toggleOpenRequestSale] = useToggle<boolean>()
  const [isActiveIcon, setIsActiveIcon] = useState<string | null>('note');
  const [openDialogShortcut, setOpenDialogShortcut] = useState<string | null>(null);
  const [openManualSales, setOpenManualSales] = useState<any>(false)
  // const [openAddSaleModal, toggleAddSaleModal] = useToggle<boolean>()

  useEffect(() => {
    if (action) setIsActiveIcon(action)
  }, [action])

  let handleAction = (code: actions_crm, channel?: any) => () => {
    if (prospect.phones.length === 0 || prospect.phones[0] === "") {
      if (badActions.includes(code)) return
    }

    setIsActiveIcon(code)
    return onAction(code, channel);
  };

  const prospect = useSelector((state: any) => state.crm.contact);
  const user = useMemo(() => isAuth(), [isAuth])

  const groupConfig = useMemo(() => {
    return getCompany()
  }, [getCompany])

  const hasManualSalesPermissions = useMemo(() => {
    const manualSaleConfig = groupConfig?.crmConfig?.manualSales

    if (manualSaleConfig.active) {
      const manualSalesRoles = manualSaleConfig.roles
      if (manualSalesRoles.length > 0) {
        return user.roles.some((role: string) => manualSalesRoles.includes(role))
      }
    }

    return false
  }, [contactId])

  const replaceVariables = (template, data) => {
    try {
      _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
      const newUrl = _.template(template);
      return newUrl(data);
    } catch (e) {
      return false
    }
  }

  const handleOnClickShortcut = (shortcut: IShortcut) => {
    if (shortcut.redirectionType === "internal") {
      const newUrl = replaceVariables(shortcut.url, { contact: prospect, user: user, group: user.group })
      if (newUrl) {
        setOpenDialogShortcut(newUrl)
      } else {
        toast({
          title: 'Error',
          description: 'La url no es valida, por favor revisa la configuración del acceso directo.',
          duration: 2000
        })
      }
    } else if (shortcut.redirectionType === "external") {
      const newUrl = replaceVariables(shortcut.url, { contact: prospect, user: user, group: user.group })
      if (newUrl) {
        window.open(newUrl, "_blank");
      } else {
        toast({
          title: 'Error',
          description: 'La url no es valida, por favor revisa la configuración del acceso directo.',
          duration: 2000
        })
      }
    } else {
      window.alert("Url no especificada");
    }
  }

  return (
    <>
      <RequestSale
        close={() => toggleOpenRequestSale()}
        open={!!openRequestSale}
        contactid={contactId}
      />
      <div className="items-center absolute top-0 left-0 overflow-x-auto modifyScroll h-full w-full flex" >
        <DialogSeeShortcut open={openDialogShortcut} onClose={() => setOpenDialogShortcut(null)} />
        <SelectorBottomActions
          setOpenUpdateContact={setOpenUpdateContact}
          isFromSharedInbox={isFromSharedInbox}
          isActiveIcon={isActiveIcon}
          handleAction={handleAction}
          prospect={prospect}
          user={user}
        />
        <Separator orientation="vertical" className="h-[50%] bg-[#C4C4C4]" />
        <IconButtonTitle
          isActive={action == 'note' || isActiveIcon == 'visit'}
          onClick={handleAction("note")}
          inverse
          iconColor="#f0ab03"
          title="Nota"
          icon={<NoteIcon height={'20px'} width={'20px'} />}
        />
        <Separator orientation="vertical" className="h-[50%] bg-[#C4C4C4]" />
        {
          ((user && user.config.enableMeetings || groupConfig && groupConfig.crmConfig?.enableMeetings)) && (
            <>
              <IconButtonTitle
                isActive={isActiveIcon == 'meeting'}
                onClick={handleAction("meeting")}
                inverse
                iconColor="#6B7280"
                title="Reunión"
                icon={<MeetingIcon height={'20px'} width={'22px'} />}
              />
              <Separator orientation="vertical" className="h-[50%] bg-[#C4C4C4]" />
            </>
          )
        }
        {groupConfig?.visitResults && groupConfig.visitResults.length > 0 && (
          <>
            <IconButtonTitle
              onClick={handleAction("visit")}
              inverse
              iconColor="#3D5A80"
              title="Visita"
              icon={<VisitIcon height={'20px'} width={'22px'} />}
            />
            <Separator orientation="vertical" className="h-[50%] bg-[#C4C4C4]" />
          </>
        )}
        {
          (isFeatureEnabled(Feature.ENABLE_REQUEST_SALE) &&
            !getPersonalFeature<boolean>(PersonalFeature.DISABLE_REQUEST_SALE, false)) && (
            <>
              <IconButtonTitle
                isActive={isActiveIcon == 'form'}
                onClick={toggleOpenRequestSale}
                icon={<Receipt size={20} />}
                title={getWordDefinition<string>(GroupWordsDefinitions.REQUESTSALE) || "Solicitudes"}
                iconColor="#000"
                inverse
              />
            </>
          )
        }
        {isFeatureEnabled(Feature.ENABLE_MANUAL_SALES) && hasManualSalesPermissions && (
          <>
            <Separator orientation="vertical" className="h-[50%] bg-[#C4C4C4]" />
            <IconButtonTitle
              isActive={isActiveIcon == 'manualSale'}
              onClick={() => setOpenManualSales({
                manualSale: true,
                contact: {
                  phone: prospect?.phones?.[0],
                  name: prospect?.firstName,
                  id: contactId,
                },
                userId: prospect?.agent || user?.id,
              })}
              icon={<Briefcase size={20} />}
              title="Venta Manual"
              iconColor="#000"
              inverse
            />
          </>
        )

        }
        {isFeatureEnabled(Feature.ENABLE_MANUAL_SALES) && (
          <IconButtonTitle
            isActive={isActiveIcon == 'manualSale'}
            onClick={() => setOpenManualSales({
              manualSale: true,
              contact: {
                phone: prospect?.phones?.[0],
                name: `${prospect?.firstName + prospect?.lastName}`
              },
              userId: user?._id,
            })}
            icon={<Briefcase size={20} />}
            title="Venta Manual"
            iconColor="#000"
            inverse
          />
        )

        }
        {groupConfig && groupConfig.shortcuts && groupConfig.shortcuts.length > 0 && groupConfig.shortcuts.map((shortcut: any, index: number) => {
          return (
            <div key={index} className="flex items-center h-full justify-center">
              <Separator orientation="vertical" className="h-[50%] bg-[#C4C4C4]" />
              <IconButtonTitle
                onClick={() => handleOnClickShortcut(shortcut)}
                inverse
                iconColor="#000"
                title={shortcut.title}
                avatar={shortcut.avatar}
                rounded
              />
            </div>
          )
        }
        )}
      </div >
      <AddModal
        open={openManualSales}
        close={() => setOpenManualSales(false)}
      />
    </>
  );
};

SelectAction.defaultProps = {
  onAction: () => { },
};

export default SelectAction;
