import { isAuth } from "@/actions/auth"
import { transfer } from "@/actions/crm/contact"
import AlertError from "@/components/AlertError"
import { BtnNextStep } from "@/components/buttons/BtnNextStep"
import FeatureCheckbox from "@/components/grupos/crm-config/feature-checkbox"
import { Button } from "@/components/ui/button"
import { CustomFormField } from "@/components/ui/custom-form-field"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog"
import { Form } from "@/components/ui/form"
import { Textarea } from "@/components/ui/textarea"
import { InternalServerErrorDescription, TransferErrorsDescription } from "@/constants/errores/descriptions"
import { Feature, isFeatureEnabled } from "@/helpers/featureFlagHelpers"
import { zodResolver } from "@hookform/resolvers/zod"
import { useQueryClient } from "@tanstack/react-query"
import _ from "lodash"
import { AlarmClock, Archive } from "lucide-react"
import mixpanel from "mixpanel-browser"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import InputAgentTransfer from "../input-agent-transfer"
import BadgePill from "../Pills/BadgePill"
import CreateSubConversationAlert from "./CreateSubConversationAlert"
moment.locale('es')

const cloneContactSchema = z.object({
    agent: z.object({ _id: z.string() }),
    subConversation: z.boolean().default(true),
    description: z.string(),
    keepReminderAndArchivingReason: z.boolean().optional(),
})

type TCloneContactSchema = z.infer<typeof cloneContactSchema>

export enum FetchStates {
    LOADING = 'loading',
    ERROR = 'error',
    IDLE = 'idle'
}

const defaultValues = {
    description: '',
    subConversation: false,
    keepReminderAndArchivingReason: false,
}

interface TransferContactDialogProps {
    type: 'transfer' | 'clone'
    currentUser?: boolean,
    open: boolean | string[] | string | null | undefined,
    onClose: () => void,
    agentProspect?: string,
    contact?: any
}

export default function TransferContactDialog({
    open,
    onClose,
    currentUser,
    type = 'clone',
    agentProspect,
    contact
}: TransferContactDialogProps) {
    const [error, setError] = useState<null | string>(null)

    const [openConfirmClone, setOpenConfiernClone] = useState(false)
    const [fetchState, setFetchState] = useState(FetchStates.IDLE)
    const enabledSubConversations = isFeatureEnabled(Feature.ENABLE_SUB_CONVERSATIONS)
    const isTransferMode = type === 'transfer' || !enabledSubConversations

    const userId = useMemo(() => _.get(isAuth(), '_id', ''), [])
    const contactList: any[] = [open].flat()
    const isMasiveIDs = contactList.length > 1;

    const contextForm = useForm<TCloneContactSchema>({
        resolver: zodResolver(cloneContactSchema),
        defaultValues
    })

    const keepReminderAndArchivingReason = contextForm.watch('keepReminderAndArchivingReason');

    const agentSelected = contextForm.watch('agent')
    const queryCliente = useQueryClient()

    useEffect(() => {
        contextForm.reset({
            ...defaultValues,
            keepReminderAndArchivingReason: false
        })

        if (currentUser && type === 'clone') {
            contextForm.setValue('agent', isAuth())
        }
        setError(null)
        setFetchState(FetchStates.IDLE)
    }, [open])

    const handleSubmit = (values: TCloneContactSchema) => {
        if ((type === 'clone' && !currentUser && !values.agent) || (type === 'transfer') && !values.agent) {
            return contextForm.setError('agent', { message: 'El agente a transferir es necesario' })
        }

        if ((type === 'clone' || values.subConversation)) return setOpenConfiernClone(true)
        handleTransfer(values)
    }

    const handleTransfer = (values: TCloneContactSchema) => {
        const { agent, description, keepReminderAndArchivingReason } = values;

        if (contactList.length === 0 || !open || typeof open === "boolean") return
        setFetchState(FetchStates.LOADING)
        setError(null)

        const allPromises = contactList.map(
            (id) =>
                new Promise((resolve, reject) => {
                    transfer(id, agent._id, description ?? '', keepReminderAndArchivingReason)
                        .then((response) => {
                            mixpanel.track("Transfer created", {
                                contactId: id,
                                receiverId: agent._id,
                            })
                            resolve(response)
                        })
                        .catch((e) => {
                            const requestBody = e?.body
                            let errorText = ''

                            const { errorCode } = requestBody || {}
                            errorText = (TransferErrorsDescription?.[errorCode] || InternalServerErrorDescription)
                            setError(errorText);

                            mixpanel.track("Transfer created Error", {
                                contactId: id,
                                receiverId: agent,
                                error: errorText
                            })

                            setFetchState(FetchStates.ERROR)
                            reject(e)
                        })
                })
        )

        Promise.all(allPromises)
            .then(() => {
                setFetchState(FetchStates.IDLE)

                queryCliente.invalidateQueries({
                    queryKey: ["table-contacts"]
                })

                onClose()
            })
            .catch(() => {
                setFetchState(FetchStates.ERROR)
            })
    }

    const isLoadingTransfer = fetchState === FetchStates.LOADING;
    const isErrorTransfer = fetchState === FetchStates.ERROR;

    const keepReminderAndArchivingReasonLabel = useMemo(() => {
        if (type !== 'transfer') return '';

        if (keepReminderAndArchivingReason) return 'Mantener cierres y recordatorios.';

        if (contact) {
            const { reminder, archivingReason } = contact;
            if (reminder && archivingReason) return 'Mantener recordatorio y cierre.';
            if (reminder) return 'Mantener recordatorio.';
            if (archivingReason) return 'Mantener cierre.';
        }

        return 'Mantener cierres y recordatorios.';
    }, [type, keepReminderAndArchivingReason, contact]);

    const hasTagOrReminder = useMemo(() => {
        const { archivingReason, reminder } = contact || {};
        return !!archivingReason || !!reminder;
    }, [contact]);

    return (
        <>
            {
                openConfirmClone && <CreateSubConversationAlert
                    contactId={contactList}
                    onClose={() => setOpenConfiernClone(false)}
                    open={openConfirmClone}
                    onSuccess={onClose}
                    currentUser={currentUser}
                    body={
                        {
                            agent: contextForm.getValues('agent')._id,
                            description: contextForm.getValues('description')
                        }
                    }
                />
            }
            <Dialog open={!!open} onOpenChange={() => onClose && onClose()}>
                <DialogContent className="sm:max-w-[530px]">
                    <DialogHeader>
                        <DialogTitle>
                            {
                                isTransferMode
                                    ? `Transferir ${isMasiveIDs ? "contactos" : "contacto"}`
                                    : 'Crear Sub-Conversación'
                            }
                        </DialogTitle>
                    </DialogHeader>
                    <AlertError
                        description={error}
                        title="Ocurrio un error en la transferencia"
                    />
                    <div className="min-h-[200px] mt-4 mb-2">
                        <Form {...contextForm}>
                            <form
                                id="createSubConversation"
                                onSubmit={contextForm.handleSubmit(handleSubmit)}
                                className="flex flex-col gap-5"
                            >
                                {
                                    ((type === 'clone' && !currentUser) || (type === 'transfer')) && <CustomFormField
                                        control={contextForm.control}
                                        name="agent"
                                        label="A quien se le asigna"
                                        key={`${!!open}`}
                                        isRequired
                                        fnElement={({ field }) => (
                                            <InputAgentTransfer
                                                onChangeValue={field.onChange}
                                                value={field.value}
                                                agentProspect={agentProspect}
                                            />
                                        )}
                                    />
                                }
                                <CustomFormField
                                    control={contextForm.control}
                                    name="description"
                                    label="Nota"
                                    fnElement={({ field }) => (
                                        <Textarea
                                            {...field}
                                            placeholder="Aquí podrás dejar una nota para que la persona que reciba el contacto tenga más contexto acerca del mismo."
                                            className="min-h-[130px]"
                                        />
                                    )}
                                />
                                {
                                    keepReminderAndArchivingReasonLabel && (
                                        <CustomFormField
                                            control={contextForm.control}
                                            name="keepReminderAndArchivingReason"
                                            fnElement={({ field }) => (
                                                <div className="flex flex-col gap-2">
                                                    <FeatureCheckbox
                                                        checked={keepReminderAndArchivingReason}
                                                        onCheckedChange={() => field.onChange(!field.value)}
                                                        label={keepReminderAndArchivingReasonLabel}
                                                        code={'keepReminderAndArchivingReason'}
                                                    />
                                                    {hasTagOrReminder && (
                                                        <div className="flex flex-row gap-2">
                                                            {contact?.reminder && (
                                                                <BadgePill className="rounded-lg">
                                                                    <AlarmClock size={16} />
                                                                    <span>{moment(contact?.reminder).format('LLL')}</span>
                                                                </BadgePill>
                                                            )}
                                                            {contact?.archivingReason && (
                                                                <BadgePill className="rounded-lg">
                                                                    <Archive size={16} />
                                                                    <span>{contact?.archivingReasonText}</span>
                                                                </BadgePill>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    )
                                }
                            </form>
                        </Form>
                    </div>
                    <DialogFooter>
                        <Button type="button" variant={'outline-primary'} onClick={onClose}>
                            Cancelar
                        </Button>
                        <BtnNextStep
                            hiddenArrow={true}
                            type="button"
                            disabled={!agentSelected || agentSelected === userId}
                            loading={isLoadingTransfer}
                            onClick={() => handleSubmit(contextForm.getValues())}
                            variant={'california'}
                        >
                            {
                                isErrorTransfer
                                    ? 'Reintentar'
                                    : isTransferMode
                                        ? 'Transferir'
                                        : 'Crear'
                            }
                        </BtnNextStep>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}
