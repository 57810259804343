import { Facebook, Instagram, WhatsApp } from "@mui/icons-material";

export const CHANNELS_TYPE_DATA = {
    'whatsapp_360_dialog': {
        label: 'WhatsApp Oficial',
        icon: <WhatsApp className="w-[19px] h-[19px]" />
    },
    'fb': {
        label: 'Facebook',
        icon: <Facebook className="w-[19px] h-[19px]" />
    },
    'leads': {
        label: 'Facebook',
        icon: <Facebook className="w-[19px] h-[19px]" />
    },
    'ig': {
        label: 'Instagram',
        icon: <Instagram className="w-[19px] h-[19px]" />
    },
    'wpp': {
        label: 'WhatsApp Oficial',
        icon: <WhatsApp className="w-[19px] h-[19px]" />
    }
};