import { isAuth } from "@/actions/auth"
import useChangeAccount from "@/hooks/useChangeAccount"
import _ from "lodash"
import "moment/locale/es"
import { CommandList, CommandSeparator } from "../../ui/command"
import { LoadingCommandItem } from "../../ui/pupover-with-select-item"
import AccountItem from "./account-item"

export default function ListOfLinkedAccount({
    accounts,
    handleChangeAccount,
    isLoadingFetch
}) {
    if (isLoadingFetch) return <div className="px-2"> <LoadingCommandItem countItem={4} /></div>
    const currentUserId = _.get(isAuth(), '_id', '')
    const actualCompanyId = _.get(isAuth(), 'group._id', '')

    console.log('Accounts', accounts);
        
    return (
        <CommandList
            className={"h-max px-2 overflow-y-auto max-h-[200px] relative modifyScroll  gap-1 flex flex-col"}>
            {
                accounts.filter((account) => account._id !== currentUserId).map((account, index) => (
                    <div key={account._id} className="w-full">
                        <AccountItem
                            account={account}
                            onSelect={() => handleChangeAccount(account._id)}
                        />
                        {
                            index !== (accounts.length - 2) && <CommandSeparator className="mx-[2px] my-[2px]" />
                        }
                    </div>
                ))
            }
        </CommandList>
    )
}
