import { SmsProvider } from "@/actions/channels/sms"
import { getEmails } from "@/actions/config/email"
import { sendSms } from "@/actions/crm/interactions"
import { getStatusTagColor } from "@/helpers/tagColorToPalette"
import { uploadFileToS3ByObject } from "@/helpers/uploadFileToS3"
import useGetRequestSaleTemplates from "@/hooks/crm/useGetRequestSaleTemplates"
import { useToggle } from "@/hooks/useToggle"
import { ProspectProps } from "@/interfaces/Interface"
import { cn } from "@/lib/utils"
import { useMediaQuery } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import mixpanel from "mixpanel-browser"
import moment from "moment"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  get,
  sendUnofficialServerlessWhatsappMessage
} from "../../actions/crm/contact"
import {
  closeLoadingContact,
  setAction,
  setActiveChannel,
  setContact,
  setEditInteraction,
  setEmptyInteractionsId,
  setError,
  setFetchingId,
  setFilterInteraction,
  setLoadingContact,
  setMoreChats,
  setOpenMenuInformation,
  setOpenReminderCall,
  setOpenReminderMenu,
  setRedirectToInteraction,
  setReminderData,
  setReply,
  setRequestSaleId,
  setScheduleMessageDate,
  setSearchValue,
  setViewCompanyGroup
} from "../../redux/slices/crm"
import { Avatar } from "../ui/avatar"
import Loading from "../ui/loading"
import { BottomActions } from "./BottomActions/BottomActions"
import ModalReminder from "./BottomActions/Reminder/ModalReminder"
import { retryScheduleValues } from './BottomActions/Reminder/constant/index'
import { actions_crm } from "./BottomActions/SelectAction"
import CompanyGroupDrawer from "./CompanyGroupDrawer"
import CreateContact from "./ContactDrawer/CreateContactDrawer"
import ContactInformationMenu from "./ContactInformationMenu"
import ContactProfileInformation from "./ContactProfileInformation"
import Conversation from "./Conversation"
import EmailInput from "./EmailInput"
import SelectChannel from "./Modal/SelectChannel"
import TransferContactDialog from "./Modal/TransferContactDialog"
import VisitModal from "./Modal/VisitModal"
import WhatsappMultiSelect from "./Modal/WhatsappMultiSelect"
import { ProspectHeader } from "./Prospect/ProspectHeader"
import { createInteraction } from "./Prospect/libs/helpers/interaction"
import FetchContactError, { FETCHING_CONTACT_ERRORS, FetchingContactError } from "./contact/FetchContactError"
import ArchiveDrawer from "./drawes/ArchiveDrawer"
import ProspectCollapseInformation from "./prospect-collapse-information"
import UploadMultimedia from "./uploadMultimedia"

moment.locale("es")

const
  Prospect = ({
    id,
    forceBack = false,
    fixedInformationMenu = true,
    onClose,
    query,
    agentSet,
    fromBackOffice = false,
    handleAfkError,
    countUnassigned,
    className,
    classFoceBack,
    customStylesBubbles,
    nextProspect,
    isFromSharedInbox,
    mode,
  }: ProspectProps) => {

    const prospect = useSelector((state: any) => state.crm.contact)
    const [toggleArchivar, setToggleArchivar] = useState<boolean | string[] | null>(false)
    const [toggleTransfer, setToggleTransfer] = useState<boolean | string[] | null>(false)
    const [toggleVisit, setToggleVisit] = useState<boolean | string>(false)
    const [openUpdateContact, toggleUpdateContact, setOpenUpdateContact] = useToggle(false)
    const [openCloneContact, setCloneContact] = useState<boolean | string>(false)
    const [openMultiWhatsappMenu, setOpenMultiWhatsappMenu] = useState<any>(false)
    const [channelSelectModal, setChannelSelectModal] = useState(false);
    const [fetchingError, setFetchingError] = useState<FetchingContactError | undefined>();

    const scheduleMessageDate = useSelector((state: any) => state.crm.scheduleMessageDate)
    const openMenuInformation = useSelector((state: any) => state.crm.openMenuInformation);
    const viewCompanyGroup = useSelector((state: any) => state.crm.viewCompanyGroup);
    const scrolleableDivRef = useRef<HTMLDivElement | null>(null);
    const redirectToInteraction = useSelector((state: any) => state.crm.redirectToInteraction);
    const interactionsBase = useSelector((state: any) => state?.crm?.contact?.interactions || [])

    const handleOpenMenuInformation = (open: boolean) => dispatch(setOpenMenuInformation(open))
    const toggleMenuInformation = () => handleOpenMenuInformation(!openMenuInformation)

    const isContactError = useSelector((state: any) => state?.crm?.error)

    const [note, setNote] = useState("")
    const action = useSelector((state: any) => state.crm.action)
    const activeChannel = useSelector((state: any) => state.crm.activeChannel)

    const showContactProfile = useFeatureFlagEnabled('perfil-de-contacto');

    useGetRequestSaleTemplates()

    const dispatch = useDispatch()
    const isOpenReminderMenu = useSelector((state: any) => state.crm.isOpenReminderMenu)
    const openCallReminderModal = useSelector((state: any) => state.crm.openCallReminderModal)

    const multimediaToSend = useSelector((state: any) => state.crm.multimediaToSend)
    const replyMessage = useSelector((state: any) => state.crm.reply)
    const editInteraction = useSelector((state: any) => state.crm.editInteraction);
    const sharedInboxUser = useSelector((state: any) => state.crm.sharedInboxUser);

    const refScroll = useRef<HTMLDivElement | null>(null)
    const isMobile = useMediaQuery("(max-width: 1280px)");

    const onUnViewCompanyGroup = () => dispatch(setViewCompanyGroup(false));

    const { data: dataProvider } = useQuery({
      queryKey: ["get-nylas-email"],
      queryFn: getEmails
    })

    const fetchContact = async (id: string) => {
      setFetchingError(undefined);
      dispatch(setError(undefined));
      dispatch(setFilterInteraction({}));
      dispatch(setSearchValue(""));
      dispatch(setEditInteraction(undefined));
      setOpenUpdateContact(false);
      dispatch(setLoadingContact(true));
      dispatch(setFetchingId(id));
      dispatch(setAction("select"));

      try {
        const result = await get(id, query);

        if (!result || result.error) {
          const error = result?.error || '';

          if (FETCHING_CONTACT_ERRORS[error]) {
            dispatch(setError(error));
            setFetchingError(error);
            return;
          }

          throw new Error('Error al obtener info del contacto')
        }

        dispatch(setContact(result));
        dispatch(closeLoadingContact(id));
        dispatch(setEmptyInteractionsId(null));

        const interactions = result.interactions;

        if (interactions.length < 50) {
          dispatch(setMoreChats(false));
        } else {
          dispatch(setMoreChats(true));
        }

        if (redirectToInteraction?.interaction && redirectToInteraction?.contact === id) {
          if (interactions.findIndex((interaction) => interaction._id === redirectToInteraction.interaction) === -1) {
            const scrolleableConversation = document.getElementById("scorllableChat");

            if (scrolleableConversation) {
              scrolleableConversation.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }
        }

        mixpanel.track("Contact view on web", { contactId: id });
        return;
      } catch (error) {
        console.log('ERROR AL OBTENER CONTACTO2:', error);
        const errorMessage = error instanceof Error ? error.message : error;
        mixpanel.track("Get contact error", { contactId: id, error, reason: errorMessage });

        dispatch(setLoadingContact(false));
        dispatch(setError("Error al obtener info del contacto"));
        throw error;
      }
    };

    const { isPending, refetch: refetchContact } = useQuery({
      queryKey: ["contact-by-id", id],
      queryFn: () => fetchContact(id),
      enabled: !!id,
      retry: 3,
      retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 10000),
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true
    });

    useEffect(() => { setOpenMenuInformation(false) }, [isMobile])

    const downChatFc = ({ behavior }: { behavior: ScrollBehavior } = { behavior: "instant" }
    ) => {
      const elementToScroll = refScroll.current
      elementToScroll?.scrollIntoView({ behavior, block: "end" })
    }

    const onChangeScheduleDate = (date: string | null) => dispatch(setScheduleMessageDate(date))

    const sendMultimedia = async (file: string, type?: string, text?: string, name?: string, externalMimetype?: string) => {
      if (!activeChannel) return
      let mimetype = externalMimetype || 'application/octet-stream';
      let path = file;

      if (!file.startsWith('https')) {
        const result = await uploadFileToS3ByObject({
          name: (text || name || "") as string,
          path: file as string,
          type: mimetype
        });

        mimetype = file.split(';')[0].split(':')[1]
        path = result[0].path;
      };

      await createInteraction({
        dispatch,
        type: 'channel-message',
        data: {
          message: text,
          channel: activeChannel,
          contactId: id,
          file: path,
          replyMessage,
          type,
          mimetype,
          channelType: activeChannel.type,
          onSuccess: (response) => {
            if (response.data.error) return alert(response.data.error)
            if (agentSet) agentSet()
            onSuccesRequest()
          }
        }
      })

      onChangeScheduleDate(null)
    }

    const sendMultimediaPersonalWPP = async (file: string, type?: string, text?: string, name: string = '') => {
      const mimetype = `audio/mp4`

      const [{ path }] = await uploadFileToS3ByObject({
        name: name as string,
        path: file as string,
        type: mimetype
      })

      await createInteraction({
        dispatch,
        type: 'unofficial-whatsapp',
        data: {
          onSuccess: () => agentSet && agentSet(),
          isFromSharedInbox: isFromSharedInbox && (action == 'unofficial-whatsapp-from-shared-inbox'),
          agent: sharedInboxUser,
          message: text ?? "",
          contactId: id,
          type,
          mimetype,
          file: path,
          extras: {
            text: text ?? "",
          }
        }
      })

      onChangeScheduleDate(null)
    }

    const onSuccesRequest = () => {
      setNote("")
      downChatFc()
    }

    const sendMessage = async (__, noteValue = undefined, template = undefined) => {
      const textMsm = noteValue ?? note;

      const isWhatsApp = (action === 'unofficial-whatsapp' ||
        action === 'unofficial-whatsapp-from-shared-inbox' ||
        (action === 'channel-chat' && activeChannel.type === 'unofficial_wpp')
      );

      if (action == 'update-whatsapp-message' && editInteraction) {
        return createInteraction({
          type: 'update-whatsapp-message',
          dispatch,
          data: {
            interaction: editInteraction,
            contactId: prospect._id,
            editedMessage: textMsm
          }
        })
      };

      if (scheduleMessageDate && isWhatsApp) {
        return createInteraction({
          dispatch,
          type: 'scheduled-message',
          data: {
            contactId: prospect._id,
            scheduleMessageDate,
            isFromSharedInbox: isFromSharedInbox && (action == 'unofficial-whatsapp-from-shared-inbox'),
            agent: sharedInboxUser,
            interactionType: 'unofficial-whatsapp',
            channel: (action !== 'unofficial-whatsapp') ? activeChannel?._id : undefined,
            messageContent: {
              message: textMsm
            }
          }
        })
      }

      switch (action) {
        case "channel-chat":
          createInteraction({
            dispatch,
            type: 'channel-message',
            data: {
              template,
              fromBackOffice,
              message: noteValue ?? note,
              channel: activeChannel,
              contactId: id,
              replyMessage,
              channelType: activeChannel.type,
              onSuccess: () => {
                if (agentSet) agentSet()
                onSuccesRequest()
              }
            }
          })
          break
        case "unofficial-whatsapp-from-shared-inbox":
        case "unofficial-whatsapp":
          createInteraction({
            dispatch,
            type: 'unofficial-whatsapp',
            data: {
              isFromSharedInbox: isFromSharedInbox && (action == 'unofficial-whatsapp-from-shared-inbox'),
              agent: sharedInboxUser,
              message: textMsm,
              fromBackOffice,
              contactId: id,
              replyMessage,
              onSuccess: () => {
                if (agentSet) agentSet()
                onSuccesRequest()
              }
            }
          })
          break
        case "unofficial-personal-whatsapp":
          sendUnofficialServerlessWhatsappMessage(id, noteValue ?? note).then(
            (response) => {
              if (response.error) return alert(response.error)
              if (agentSet) {
                agentSet()
              }
              onSuccesRequest()
            }
          )
          break;
        case "channel-sms": {
          const { accessToken, pin } = activeChannel?.config?.providerConfig || {}

          await sendSms({
            id: prospect._id,
            content: {
              message: noteValue || ''
            },
            provider: SmsProvider.SmsMensajeros,
            config: {
              accessToken,
              pin
            }
          })

          onSuccesRequest()
          break;
        }
        default: {
          createInteraction({
            dispatch,
            type: 'note',
            data: {
              action: action,
              id,
              note: noteValue ?? note,
              onSuccess: onSuccesRequest,
            }
          })
          onSuccesRequest()
        }
      }

      onChangeScheduleDate(null)
    }

    const handelActionPress = (name: actions_crm, payload?: any) => {
      dispatch(setScheduleMessageDate(null))

      switch (name) {
        case "whatsapp-note":
          if (prospect.phones.length > 1) {
            setOpenMultiWhatsappMenu(true)
            dispatch(setAction("whatsapp-note"))
          } else {
            window.open("https://wa.me/" + prospect.phones[0], "_blank")
            dispatch(setAction("whatsapp-note"))
          }
          break
        case "channel-select":
          setChannelSelectModal(true)
          break
        case "channel-chat":
        case "channel-sms":
          dispatch(setActiveChannel({ action: name, config: payload }))
          break
        case "unofficial-whatsapp":
          dispatch(setAction("unofficial-whatsapp"))
          break
        case "unofficial-personal-whatsapp":
          dispatch(setAction("unofficial-personal-whatsapp"))
          break
        case "visit":
          setToggleVisit(true)
          break
        case "more":
          dispatch(setAction("select-more"))
          break
        case "transfer":
          setToggleTransfer(true)
          break
        case "form":
          dispatch(setRequestSaleId(false))
          dispatch(setAction("form"))
          break

        default:
          dispatch(setAction(name))
          break
      }
    }

    const scrollToBottom = useCallback(() => {
      if (scrolleableDivRef.current) {
        const scrollableElement = scrolleableDivRef.current;

        scrollableElement.scrollTo({
          top: (scrollableElement.scrollHeight - scrollableElement.clientHeight) * -1,
          behavior: "smooth",
        });
      }
    }, [scrolleableDivRef])

    useEffect(() => {
      if (
        redirectToInteraction?.interaction &&
        redirectToInteraction?.contact === prospect._id &&
        interactionsBase.length > 0
      ) {
        const documentInteraction = document.getElementById(redirectToInteraction.interaction);

        if (!scrolleableDivRef.current) return;

        if (!documentInteraction) {
          if (scrolleableDivRef.current) scrollToBottom();
        } else {
          if (scrolleableDivRef.current) {
            const { top: elementTop, height: elementHeight } = documentInteraction.getBoundingClientRect();
            const { top: containerTop, height: containerHeight } = scrolleableDivRef.current.getBoundingClientRect();

            const offset = elementTop - containerTop + (elementHeight / 2) - (containerHeight / 2);

            scrolleableDivRef.current.scrollTo({
              top: offset + scrolleableDivRef.current.scrollTop,
              behavior: "smooth",
            });

            const checkScroll = setInterval(() => {
              if (!scrolleableDivRef.current) return;

              const currentScrollTop = scrolleableDivRef.current.scrollTop;
              const containerScrollHeight = scrolleableDivRef.current.scrollHeight;
              const containerClientHeight = scrolleableDivRef.current.clientHeight;

              const isElementInView = () => {
                if (!scrolleableDivRef.current) return false;

                const { top } = documentInteraction.getBoundingClientRect();
                const { top: containerTop } = scrolleableDivRef.current.getBoundingClientRect();
                return top >= containerTop && top <= (containerTop + containerClientHeight);
              };

              if (isElementInView() || currentScrollTop >= containerScrollHeight - containerClientHeight) {
                clearInterval(checkScroll);
                documentInteraction.classList.add('highlight');

                setTimeout(() => {
                  documentInteraction.classList.remove('highlight');
                }, 1000);

                dispatch(setRedirectToInteraction({ contact: null, interaction: null }));
              }
            }, 200);
          }
        }
      }
    }, [redirectToInteraction?.interaction, interactionsBase]);

    useEffect(() => {
      dispatch(setReply(null))
    }, [id]);

    return <div className={cn('flex flex-grow h-full', className)}>
      {
        (isPending)
          ? <Loading className={cn("[&>svg]:w-6 [&>svg]:h-6")} />
          : isContactError
            ? <FetchContactError
              onRetryFetchContact={refetchContact}
              fetchingError={fetchingError}
            />
            : prospect && (
              <div className={cn("relative h-full flex overflow-hidden flex-grow")}>
                <div className="relative w-full gap-0 bg-crm  rounded-none flex flex-col flex-1">
                  <ProspectHeader
                    toggleMenuInformation={() => {

                      if (showContactProfile && !!openUpdateContact) {
                        setOpenUpdateContact(undefined);
                        setTimeout(() => toggleMenuInformation(), 200)
                        return;
                      }

                      toggleMenuInformation();
                    }}
                    downChatFc={downChatFc}
                    forceBack={forceBack}
                    classFoceBack={classFoceBack}
                    id={id}
                    onClose={onClose}
                    prospect={prospect}
                    handleUpdateContact={() => {

                      if (showContactProfile && !!openMenuInformation && !openUpdateContact) {
                        toggleMenuInformation();
                        setTimeout(() => setOpenUpdateContact(!openUpdateContact && prospect), 200);
                        return;
                      }

                      setOpenUpdateContact(!openUpdateContact && prospect);
                    }}
                    functions={{
                      handleTransfer: () => setToggleTransfer(prospect._id),
                      handleArchive: () => setToggleArchivar(prospect),
                      handleReminder: () => dispatch(setOpenReminderMenu(prospect._id)),
                      handleSubConversation: () => setCloneContact(prospect._id)
                    }}
                  />
                  {
                    action !== 'email' && (
                      <ProspectCollapseInformation
                        onUpdateContact={() => setOpenUpdateContact(prospect)}
                        countUnassigned={countUnassigned}
                        handleAfkError={handleAfkError}
                        contact={prospect}
                        mode={mode}
                      />
                    )
                  }
                  <div className="flex-grow overflow-hidden w-full flex relative">
                    {
                      multimediaToSend.multimedia.length > 0
                        ? <UploadMultimedia prospect={prospect} />
                        : (action == 'email' && prospect.emails.length > 0) ?
                          <EmailInput
                            dataProvider={dataProvider ?? {}}
                            prospect={prospect ?? {}}
                            goBack={() => dispatch(setAction("select"))}
                          />
                          : action != "form" && (
                            <Conversation
                              downChatFc={downChatFc}
                              refScrollConversation={refScroll}
                              scrolleableDivRef={scrolleableDivRef}
                              customStyles={
                                customStylesBubbles
                                  ? customStylesBubbles
                                  : openMenuInformation
                                    ? {
                                      note: "lg:w-[65%]",
                                      phoneCall: "xl:w-[50%]",
                                      meeting: 'xl:w-[70%]',
                                      sms: "xl:w-[70%] xl:max-w-[75%]",
                                      reminder: "xl:w-[50%] xl:max-w-[55%]",
                                      endMessage: "xl:max-w-[100%]",
                                      "unofficial-whatsapp": "xl:max-w-[65%]",
                                    }
                                    : null
                              }
                              nextProspect={() => nextProspect && nextProspect()}
                              enableNextProspect={mode == "unassigned" && !prospect.lockedAt}
                            />
                          )
                    }
                  </div>
                  {
                    (multimediaToSend.multimedia.length == 0 && action !== 'email') && (
                      <BottomActions
                        action={action}
                        activeChannel={activeChannel}
                        dispatch={dispatch}
                        downChatFc={downChatFc}
                        handelActionPress={handelActionPress}
                        fromBackOffice={fromBackOffice}
                        id={id}
                        prospect={prospect}
                        sendMessage={sendMessage}
                        sendMultimedia={sendMultimedia}
                        isFromSharedInbox={isFromSharedInbox}
                        sendMultimediaPersonalWPP={sendMultimediaPersonalWPP}
                        setAction={setAction}
                        setOpenUpdateContact={() => setOpenUpdateContact(prospect)}
                      />
                    )
                  }
                  <ModalReminder
                    open={(typeof isOpenReminderMenu !== "boolean" && isOpenReminderMenu) || openCallReminderModal}
                    customReminder={openCallReminderModal && { ...retryScheduleValues() }}
                    handleSuccess={() => onSuccesRequest()}
                    onCLose={() => {
                      dispatch(setOpenReminderMenu(false))
                      dispatch(setOpenReminderCall(false))
                      dispatch(setReminderData(null))
                    }}
                  />

                  {
                    !showContactProfile && (
                      <CreateContact
                        open={openUpdateContact}
                        setOpen={setOpenUpdateContact}
                        onSave={() => setOpenUpdateContact(false)}
                      />
                    )
                  }

                  <ArchiveDrawer
                    open={toggleArchivar}
                    onClose={() => setToggleArchivar(null)}
                    currentArchivingReason={prospect?.archivingReason}
                  />

                  <VisitModal
                    modal={toggleVisit && id}
                    setModal={() => setToggleVisit(false)}
                  />

                  <WhatsappMultiSelect
                    open={openMultiWhatsappMenu}
                    setOpen={setOpenMultiWhatsappMenu}
                    phones={prospect.phones}
                  />

                  <SelectChannel
                    open={channelSelectModal}
                    setOpen={setChannelSelectModal}
                  />

                  <TransferContactDialog
                    onClose={() => !!toggleTransfer ? setToggleTransfer(null) : setCloneContact(false)}
                    type={openCloneContact ? 'clone' : 'transfer'}
                    open={openCloneContact || toggleTransfer}
                    agentProspect={prospect?.agent || ''}
                    contact={prospect}
                  />
                </div>
                {
                  showContactProfile && (
                    <ContactProfileInformation
                      downChatFn={() => downChatFc({ behavior: 'instant' })}
                      open={showContactProfile && !!openUpdateContact}
                      onClose={() => setOpenUpdateContact(false)}
                      fixed={fixedInformationMenu}
                      contact={prospect}
                    />
                  )
                }
                <ContactInformationMenu
                  prospect={prospect}
                  fixedMenu={fixedInformationMenu}
                  openMenuInformation={showContactProfile ? (!openUpdateContact && !!openMenuInformation) : !!openMenuInformation}
                  toggleMenuInformation={toggleMenuInformation}
                  downChatFc={() => downChatFc({ behavior: "instant" })}
                  handleUpdateContact={() => setOpenUpdateContact(prospect)}
                />
              </div>
            )
      }
      <CompanyGroupDrawer
        id={viewCompanyGroup}
        open={!!viewCompanyGroup}
        onOpenChange={onUnViewCompanyGroup}
      />
    </div>
  }

export function ContactAvatar({
  statusTag,
  photo,
  firstName,
  lastName,
  defaultColors = {},
  bgColor,
  textColor,
  className = "",
}: any) {
  const { text, background } = defaultColors || {}
  const initials = `${firstName?.[0] || ""}${lastName?.[0] || ""}`

  return (
    <Avatar
      className={cn(
        "w-[42px] uppercase font-medium flex items-center justify-center text-[13px] h-[42px]",
        className
      )}
      style={{
        background: bgColor
          ? bgColor
          : statusTag
            ? getStatusTagColor(statusTag, "primary")
            : background || "#f7f9fb",
        color: textColor ? textColor : statusTag ? getStatusTagColor(statusTag, "secondary") : text || "#555",
      }}
    >
      {photo ? (
        <img
          src={photo}
          className="w-full h-full object-cover"
          alt={`Image porfile of the ${firstName} ${lastName}`}
        />
      ) : (
        <span>{initials}</span>
      )}
    </Avatar>
  )
}

export default Prospect
