import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import Chip from '../ui/chip';
import { ATTENDES_ROLES } from './constants';

interface ParticipantItemProps {
    email: string,
    name: string,
    lastname: string,
    photo: string,
    role: string
};

export default function ParticipantItem({ email, name, lastname, photo, role }: ParticipantItemProps) {
    const initials = [name.charAt(0), lastname.charAt(0)].join('').toLowerCase();
    const fullName = [name, lastname].filter(Boolean).join(' ');
    const attendeType = ATTENDES_ROLES[role];

    return (
        <li>
            <div className="flex items-center">
                <div className="flex items-center gap-2">
                    <Avatar className="w-[33px] h-[33px] bg-primary text-white">
                        <AvatarImage src={photo} alt={email} />
                        <AvatarFallback className="text-[12px] bg-primary uppercase font-medium text-white">
                            {initials}
                        </AvatarFallback>
                    </Avatar>
                    <div className="grid">
                        <span className="font-medium max-w-[180px] truncate text-gray-800 text-[14px]">
                            {fullName}
                        </span>
                        <span className="text-gray-600 max-w-[180px] truncate text-[12px]">
                            {email}
                        </span>
                    </div>
                </div>
                {
                    attendeType && (
                        <Chip
                            withCircle={false}
                            color={attendeType.color}
                            className="ml-2 text-[11px]"
                        >
                            {attendeType.label}
                        </Chip>
                    )
                }
            </div>
        </li>
    )
}