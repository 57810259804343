import { getCompany } from "@/actions/auth";
import { Button } from "@/components/ui/button";
import { Contact } from "@/interfaces/crm/contact";
import dayjs from "dayjs";
import _ from "lodash";
import { ArrowLeftRight, ArrowRight } from 'lucide-react';
import { useMemo } from 'react';

interface UnifyFeedbackTableProps {
    contactA: Contact,
    contactB?: Contact,
    base: 'A' | 'B',
    onChangeBase: (base: 'A' | 'B') => void
}

export default function UnifyFeedbackTable({
    contactA,
    contactB,
    base,
    onChangeBase
}: UnifyFeedbackTableProps) {

    const useContactA = base == 'B' ? contactB : contactA;
    const useContactB = base == 'B' ? contactA : contactB;
    const additionalDataFields = _.get(getCompany(), 'additionalDataFields', []);

    const fields = useMemo(() => {
        const list = [
            {
                label: 'Fecha de creación:',
                key: 'createdAt',
                extractorValue: (value: string) => value && dayjs(value).format('DD/MM/YYYY HH:mm')
            },
            {
                label: 'Nombre:',
                extractorValue: (value: string) => value,
                key: 'firstName'
            },
            {
                label: 'Apellido:',
                extractorValue: (value: string) => value,
                key: 'lastName'
            },
            {
                label: 'Teléfonos:',
                extractorValue: (value: string[]) => (value || []).join(', '),
                key: 'phones'
            },
            {
                label: 'Emails:',
                extractorValue: (value: string[]) => (value || []).join(', '),
                key: 'emails'
            },
            {
                label: 'Origen:',
                extractorValue: (value: string) => value,
                key: 'originText'
            },
            {
                label: 'Cierre:',
                extractorValue: (value: string) => value,
                key: 'archivingReasonText'
            },
            {
                label: 'Recordatorio:',
                extractorValue: (value: string) => value && dayjs(value).format('DD/MM/YYYY'),
                key: 'reminder'
            },
            {
                label: 'Última interacción:',
                extractorValue: (value: string) => value && dayjs(value).format('DD/MM/YYYY'),
                key: 'lastInteraction'
            }
        ];

        additionalDataFields.forEach(({ name, type, code }) => {
            list.push({
                label: name,
                key: 'additionalData',
                extractorValue: (value: any) => {
                    if (type == 'multiselect') return (value?.[code] || []).join(', ');
                    return value?.[code] || undefined;
                }
            })
        });

        return list;
    }, [additionalDataFields]);

    const handleChangeBase = () => onChangeBase(base == 'A' ? 'B' : 'A');

    const joinResult = useMemo(() => {
        let result: Partial<Contact> = {};
        if (!useContactB || !useContactA) return result;

        const removeDuplicates = (values: string[]) => Array.from(new Set(values).values());

        result = {
            ...useContactB,
            ...Object.fromEntries(Object.entries(useContactA).filter(([__, value]) => !!value)),
            phones: removeDuplicates([...(useContactA?.phones || []), ...(useContactB?.phones || [])]),
            emails: removeDuplicates([...(useContactA?.emails || []), ...(useContactB?.emails || [])]),
        };

        if (!result.additionalData) result.additionalData = {};

        additionalDataFields.forEach(({ code, type }) => {
            if (type == 'multiselect') {
                (result as any).additionalData = {
                    ...result.additionalData,
                    [code]: removeDuplicates([
                        ...((useContactA as any)?.additionalData?.[code] || []),
                        ...((useContactB as any)?.additionalData?.[code] || [])]
                    ) as any
                };

            } else {
                if (!useContactA?.additionalData?.[code] && (useContactB as any)?.additionalData?.[code]) {
                    (result as any).additionalData = {
                        ...result.additionalData,
                        [code]: (useContactB as any).additionalData[code]
                    };
                }
            }
        });

        if (useContactB?.lastInteraction && (!useContactA?.lastInteraction || new Date(useContactB.lastInteraction) > new Date(useContactA.lastInteraction))) {
            result.lastInteraction = useContactB.lastInteraction;
        };

        result.createdAt = useContactA.createdAt;

        if (new Date(useContactB.createdAt) < new Date(useContactA.createdAt)) {
            result.createdAt = useContactB.createdAt;
        }

        return result;
    }, [contactA, contactB, base]);

    if (!contactA) return null;

    return (
        <div>
            <table className="table-auto border-collapse border rounded-md w-full">
                <thead>
                    <tr>
                        <th className="border text-[13.5px] w-[140px] font-medium text-gray-800 px-2.5 py-2">Campos</th>
                        <th className="border text-[13.5px] w-[220px] font-medium text-gray-800 px-2.5 py-2">
                            Contacto A (Base)
                        </th>
                        <th className="border relative text-[13.5px] w-[200px] font-medium text-gray-800 px-2.5 py-2">
                            <Button
                                size="icon"
                                className="w-6 h-6 rounded-full shrink-0 flex items-center justify-center absolute top-0 bottom-0 my-auto -left-3"
                                onClick={handleChangeBase}
                                disabled={!contactB}
                                type="button"
                            >
                                <ArrowLeftRight size={16} />
                            </Button>
                            Contacto B
                        </th>
                        <th className="border text-[13.5px] font-medium text-gray-800 px-2.5 py-2">Resultado</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        fields.map(({ extractorValue, key, label }, inx) => {
                            return <tr key={inx}>
                                <td className="border px-2.5 py-1.5 text-[13px] font-medium text-gray-800">
                                    {label}
                                </td>
                                <td className="border px-2.5 py-1.5 text-[13px] text-gray-600">
                                    {extractorValue((useContactA || contactA)[key])}
                                </td>
                                <td className="border px-2.5 py-1.5 pr-4 text-[13px] text-gray-600">
                                    {useContactB && extractorValue(useContactB[key])}
                                </td>
                                <td className="border px-2.5 py-1.5 pl-4 text-[13px] relative text-gray-600">
                                    <ArrowRight size={17} className="absolute my-auto bottom-0 top-0 -left-2 text-gray-800" />
                                    {extractorValue(joinResult[key] || null)}
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}